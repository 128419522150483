import { addGchatNotifier, deleteGchatNotifier, updateGchatNotifier } from "./gchatService";
import { addWatcherNotifier, updateWatcherNotifier } from "./watcherService";
import { deleteNotifier } from "./notifierService";
import http from "./httpService";

const apiEndPoint = "/stores/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

function getLocationUrl(location, search = false) {
  let extraArgs = "";
  if (search) {
    extraArgs = "find/";
  }
  return `${apiEndPoint}location/${extraArgs}${location}`;
}

function cleanIndex(data, key) {
  const value = parseInt(data[key]);
  if (value === 0) return null;
  return value;
}

export function getStores(page = 1, query = "") {
  const url = `${apiEndPoint}?page=${page}&search=${query}`;
  return http.get(url);
}

export function getStore(storeId) {
  return http.get(getUrl(storeId));
}

export function getStoreByLocation(location) {
  return http.get(getLocationUrl(location, true));
}

export function saveStore(store) {
  const body = { ...store };
  Object.keys(body).forEach(function (key) {
    if (body[key] === "") body[key] = null;
    if (key.includes("ToggleGroup")) delete body[key];
  });
  body.alert_channel = cleanIndex(body, "alert_channel");
  body.deep_model = cleanIndex(body, "deep_model");
  body.slack_channel = cleanIndex(body, "slack_channel");
  body.blurring = cleanIndex(body, "blurring");
  body.upload_target = cleanIndex(body, "upload_target");
  body.upload_bucket = cleanIndex(body, "upload_bucket");
  body.store_favor_factor = parseFloat(body.store_favor_factor) === 0 ? null : body.store_favor_factor;

  if (body.id) {
    delete body.id;
    return http.patch(getUrl(store.id), body);
  }
  return http.post(apiEndPoint, body);
}

export function patchStoreField(storeDd, fieldName, fieldValue) {
  const data = {
    id: storeDd,
  };
  data[fieldName] = fieldValue;
  return http.patch(getUrl(storeDd), data);
}

/**
 * Partial update of store.
 *
 * @param store the store to be updated.
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateStoreLanguageAndRegion(store) {
  const body = { ...store };
  const data = {
    id: body.id,
    language: body.language,
    currency: body.currency,
    country: body.country,
    timezone: body.timezone,
  };

  Object.keys(data).forEach(function (key) {
    if (data[key] === "") data[key] = null;
  });

  if (data.id) {
    delete data.id;
    return http.patch(getUrl(store.id), data);
  }
}

export function deleteStore(storeId) {
  return http.delete(getUrl(storeId));
}

export function addOrUpdateVideoTune(store) {
  // eslint-disable-next-line camelcase
  const { video_tune } = store;
  // eslint-disable-next-line camelcase
  const data = { ...video_tune, store: store.id };

  if (Object.prototype.hasOwnProperty.call(data, "permissions")) {
    delete data.permissions;
  }

  if (Object.prototype.hasOwnProperty.call(data, "id")) {
    return http.patch(`/videotunes/${data.id}/`, data);
  }
  return http.post("/videotunes/", data);
}

export function addOrUpdateSpecificNotifier(data, notifierName, specificNotifier) {
  patchStoreField(data.id, "notifiers", data.notifiers);
  if (notifierName === "gchat") {
    const gchat = specificNotifier;
    if (Object.prototype.hasOwnProperty.call(gchat, "id")) {
      return updateGchatNotifier(gchat);
    }
    return addGchatNotifier(gchat);
  }
  if (notifierName === "watcher") {
    const watcher = specificNotifier;
    if (Object.prototype.hasOwnProperty.call(watcher, "id")) {
      return updateWatcherNotifier(watcher);
    }
    return addWatcherNotifier(watcher);
  }
}

export function deleteSpecificNotifier(data, notifierName, specificNotifierId, notifierId) {
  patchStoreField(data.id, "notifiers", data.notifiers);
  if (notifierName === "gchat") {
    return deleteGchatNotifier(specificNotifierId);
  }
  if (notifierName === "watcher") {
    return deleteNotifier(notifierId);
  }
}

import React, { Component } from "react";
import { toast } from "react-toastify";

import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import ChannelsTableScreenshot from "../channels/ChannelsTableScreenshot";
import Screenshot from "../gallery/Screenshot";
import ImageModal from "../gallery/ImageModal";
import { getServerIp } from "../../services/locationService";
import "./VideoRecorder.scss";

class VideoRecorderScreenshot extends Component {
  state = {
    screenshotData: {},
    screenshotId: 0,
    screenshotChannel: null,
    isScreenshotShown: false,
    isGalleryShown: false,
    serverIp: null,
    showModal: false,
    uriModal: null,
    channels: [],
  };

  async componentDidMount() {
    const { channels } = this.props;
    const updatedChannels = channels.sort(function (a, b) {
      return a.channel_number - b.channel_number;
    });
    const server = updatedChannels[0] ? updatedChannels[0].inference_machine_data.hostname : null;
    const serverIp = server ? await this.fetchServerIp(server) : null;
    this.setState({ serverIp, channels: updatedChannels });
  }

  showGallery = (isShown) => {
    this.setState({ isGalleryShown: isShown });
  };

  showScreenshot = (isShown) => {
    this.setState({ isScreenshotShown: isShown });
  };

  handleGenerateScreenshot = async (channel, data, server) => {
    const { screenshotId } = this.state;
    await this.fetchServerIp(server);
    this.setState({ screenshotData: data, screenshotId: screenshotId + 1, screenshotChannel: channel });
    this.showScreenshot(true);
    this.showGallery(false);
  };

  handleShowModal = (dataUri) => {
    this.setState({ uriModal: dataUri });
    this.toggle();
  };

  toggle = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  async fetchServerIp(server) {
    try {
      const { data: serverIp } = await getServerIp(server);
      return serverIp.server_ip;
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Unable to fetch server IP");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      } else {
        toast.error("Unhandled error while fetching server IP");
      }
      return null;
    }
  }

  render() {
    const { videoRecorder, index, storeLocation } = this.props;
    const {
      serverIp,
      channels,
      isGalleryShown,
      isScreenshotShown,
      screenshotChannel,
      screenshotData,
      screenshotId,
      showModal,
      uriModal,
    } = this.state;

    return (
      <Card className="video-recorder">
        <CardHeader>
          <CardTitle tag="h4">
            DVR {index} : {videoRecorder.video_recorder_type_name}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {videoRecorder && serverIp && (
            <ChannelsTableScreenshot
              channels={channels}
              videoRecorder={videoRecorder}
              videoRecorderIndex={index}
              serverIp={serverIp}
              showScreenshot={this.showScreenshot}
              showGallery={this.showGallery}
              isGalleryShown={isGalleryShown}
              onClick={this.handleGenerateScreenshot}
            />
          )}
          {isScreenshotShown && serverIp && videoRecorder && (
            <Screenshot
              videoRecorderId={videoRecorder.id}
              channel={screenshotChannel}
              formData={screenshotData}
              formId={screenshotId}
              serverIp={serverIp}
              storeLocation={storeLocation}
              onClick={this.handleShowModal}
            />
          )}
          {showModal && <ImageModal showModal={showModal} dataUri={uriModal} toggle={this.toggle} />}
        </CardBody>
      </Card>
    );
  }
}

export default VideoRecorderScreenshot;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { updateIncident } from "../../services/incidentService";
import Select from "../common/controls/Select";
import "./Incident.scss";
import incidentTypesGroups from "./IncidentType";

export default function Incident({ incident, store }) {
  const [status, setStatus] = useState(incident.status);
  const [name, setName] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const options = [
    { id: "open", name: "OPEN" },
    { id: "solved", name: "SOLVED" },
  ];

  useEffect(() => {
    buildDisplayName();
    buildCreatedBy();
  }, []);

  const { machine } = incident;
  const createdAt = incident.created_at;

  function buildDisplayName() {
    const incidentName = incident.incident_type && incident.incident_type.name;
    Object.keys(incidentTypesGroups).forEach((groupKey) => {
      const group = incidentTypesGroups[groupKey];
      Object.keys(group.incidentTypes).forEach((typeKey) => {
        const type = group.incidentTypes[typeKey];
        if (type.name === incidentName) {
          setName(`${group.displayName} : ${type.displayName}`);
        }
      });
    });
  }

  function buildCreatedBy() {
    let result = "";
    if (incident.created_by.first_name) {
      result += `${incident.created_by.first_name} `;
    }
    if (incident.created_by.last_name) {
      result += `${incident.created_by.last_name} `;
    }
    if (incident.created_by.email) {
      result += `${incident.created_by.email}`;
    }
    setCreatedBy(result.trim());
  }

  function updateStatus(event) {
    const newStatus = event.target.value;
    setStatus(newStatus);
    incident.status = newStatus;
    updateIncident(store.location, incident);
    toast.info("Incident updated");
  }

  return (
    <div>
      <Row>
        <Col md={2}>{name}</Col>
        <Col md={1} className={status === "solved" ? "green" : "red"}>
          <Select options={options} value={status} onChange={updateStatus} />
        </Col>
        <Col md={2}>{machine}</Col>
        <Col md={2}>{createdAt}</Col>
        <Col md={3}>{createdBy}</Col>
      </Row>
    </div>
  );
}

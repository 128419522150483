import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";

import getGchatBots from "../../services/gchatBotService";
import GchatForm from "../Forms/GchatForm";
import "./Gchat.scss";

class Gchat extends Component {
  state = { editMode: false, gchatBots: [] };

  async componentDidMount() {
    const { editMode } = this.props;
    const gchatBots = await this.fetchGchatBots();
    this.setState({ editMode, gchatBots });
  }

  getBotNameFromId = (botId) => {
    const { gchatBots } = this.state;
    gchatBots.forEach((gchatBot) => {
      if (gchatBot.id === botId) {
        return gchatBot.name;
      }
    });
    return "";
  };

  toggleEditMode = () => {
    const { editMode } = this.state;
    this.setState({ editMode: !editMode });
  };

  async fetchGchatBots() {
    const { data: gchatBots } = await getGchatBots();
    gchatBots.unshift({ id: "0", name: "Not allocated" });
    return gchatBots;
  }

  render() {
    const { gchat, notifier, onSaveNotifier, store, updateGchatList, removeFromGchatList, showNewGchat } = this.props;
    const { editMode, gchatBots } = this.state;
    const className = "gchat";

    return (
      <div className={className}>
        <Row>
          {!editMode && gchat && (
            <Col className="px-md-3" md="2">
              <label>Space Id</label>
              <p>{gchat.space_id}</p>
            </Col>
          )}
          {!editMode && gchat && (
            <Col className="px-md-3" md="2">
              <label>Space Name</label>
              <p>{gchat.space_name}</p>
            </Col>
          )}
          {!editMode && gchat && (
            <Col className="px-md-3" md="2">
              <label>Bot</label>
              <p>{this.getBotNameFromId(gchat.bot)}</p>
            </Col>
          )}
          {editMode && (
            <GchatForm
              gchat={gchat}
              gchatBots={gchatBots}
              notifier={notifier}
              onSaveNotifier={onSaveNotifier}
              store={store}
              updateGchatList={updateGchatList}
              toggleEditMode={this.toggleEditMode}
              removeFromGchatList={removeFromGchatList}
              showNewGchat={showNewGchat}
            />
          )}
          {!showNewGchat && (
            <Col className="px-md-3" md="2">
              <Button className="float-left edit-button align-input" onClick={this.toggleEditMode}>
                <i className="fas fa-edit" />
                {editMode ? "Cancel" : "Edit"}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default Gchat;

import http from "./httpService";

const apiEndPoint = "/openinghours/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function saveOpeningHour(openingHour) {
  const body = { ...openingHour };
  if (body.is_open === false) {
    body.time_open = null;
    body.time_close = null;
  }
  Object.keys(body).forEach(function (key) {
    if (body[key] === "") body[key] = null;
  });
  if (body.id) {
    delete body.id;
    return http.patch(getUrl(openingHour.id), body);
  }
  return http.post(apiEndPoint, body);
}

export function deleteOpeningHour(openingHourId) {
  return http.delete(getUrl(openingHourId));
}

import React from "react";
import PageContainer from "../PageContainer";

function Forbidden() {
  return (
    <PageContainer>
      <p>Forbidden. Please contact your administrator</p>
    </PageContainer>
  );
}

export default React.memo(Forbidden);

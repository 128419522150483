import React, { Component } from "react";
import { Button } from "reactstrap";

import VtIpForm from "../Forms/VtIpForm";
import "./VtIp.scss";

class VtIp extends Component {
  // eslint-disable-next-line react/destructuring-assignment
  state = { editMode: this.props.editMode };

  toggleEditMode = () => {
    const { editMode } = this.state;
    this.setState({ editMode: !editMode });
  };

  render() {
    const { vtIp, storeId, updateVtIpList, removeFromVtIpList, showNewVtIp } = this.props;
    const { editMode } = this.state;
    const className = "vt-ip";

    return (
      <div className={className}>
        {!editMode && <span>{vtIp && vtIp.ip}</span>}
        {editMode && (
          <VtIpForm
            vtIp={vtIp}
            storeId={storeId}
            updateVtIpList={updateVtIpList}
            toggleEditMode={this.toggleEditMode}
            removeFromVtIpList={removeFromVtIpList}
            showNewVtIp={showNewVtIp}
          />
        )}
        {!showNewVtIp && (
          <Button className="float-left edit-button" onClick={this.toggleEditMode}>
            <i className="fas fa-edit" />
            {editMode ? "Cancel" : "Edit"}
          </Button>
        )}
      </div>
    );
  }
}

export default VtIp;

export const editorRole = "front_editor";
export const viewerRole = "front_viewer";
export const qualityRole = "quality";
export const resellerRole = "reseller";
export const csmRole = "csm";
export const csmMaintenanceRole = "csm_maintenance";
export const adminViewerRole = "admin_viewer";
export const adminEditorRole = "admin_editor";
export const techopsRole = "techops";
export const technicianRole = "technician";

export const MINIMUM_RESOLUTION = 144;
export const MAXIMUM_RESOLUTION = 7680;

export const MAX_ANCHOR = 3000;

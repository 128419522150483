/* eslint-disable react/jsx-no-useless-fragment */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col } from "reactstrap";
import ChannelForm from "../Forms/ChannelForm";
import SubChannelsTable from "./SubChannelsTable";
import ToggleSwitch from "../common/controls/ToggleSwitch/ToggleSwitch";
import ResponsiveRow from "../Forms/base/ResponsiveRow";
import FieldBuilder from "../shared/utils/FieldBuilder";
import FormDataContext from "../shared/contexts/FormDataContext";

class Channel extends Component {
  state = {
    editMode: false,
    deleteMode: false,
  };

  toggleEditMode = () => {
    const { channel, channelIndex, updateChannelList } = this.props;
    const { editMode } = this.state;
    if (!channel) updateChannelList(null, channelIndex);
    const updatedEditMode = !editMode;
    this.setState({ editMode: updatedEditMode });
  };

  toggleDeleteMode = () => {
    const { deleteMode } = this.state;
    const updatedDeleteMode = !deleteMode;
    this.setState({ deleteMode: updatedDeleteMode });
  };

  flattenViewArea(viewArea) {
    if (viewArea) {
      return `${viewArea.length} poly${viewArea.length !== 1 ? "s" : ""}`;
    }
    return "";
  }

  channelNumberCheckboxComponent(channel) {
    const { showBulkActions, checked, selectChannel } = this.props;
    return (
      <>
        <input
          style={{ display: showBulkActions ? "inline-block" : "none" }}
          type="checkbox"
          checked={checked}
          onChange={(event) => selectChannel(event.target.checked, channel.channel_number)}
        />
        <p className="channel-number">{channel.channel_number}</p>
      </>
    );
  }

  missedTheftsComponent(missedThefts) {
    return (
      missedThefts > 0 && (
        <p>
          {missedThefts} MT <i className="fas fa-eye-slash mr-2" />
        </p>
      )
    );
  }

  inferenceMachineComponent(channel) {
    return channel.inference_machine ? (
      <Link to={`/inferencemachines/${channel.inference_machine_data.id}`}>
        <span className="machine">
          <i className="fas fa-microchip mr-2" /> {channel.inference_machine_data.hostname}
        </span>
      </Link>
    ) : (
      "Not assigned"
    );
  }

  render() {
    const { channel, alertChannels, relevances, depthOfFields } = this.props;
    const { editMode, deleteMode } = this.state;
    let className = "channel";
    if (deleteMode) className = `${className} danger`;
    let recheckText = "";
    if (channel && channel.recheckings) {
      recheckText = `${channel.recheckings} recheck`;
      if (channel.recheckings > 1) {
        recheckText += "s";
      }
    }
    let prioText = "";
    if (channel && channel.alert_channel) {
      const foundAlertChannel = alertChannels.find((alertChannel) => alertChannel.id === channel.alert_channel);
      if (foundAlertChannel && foundAlertChannel.name.includes("Prio")) {
        prioText = "Label Prio";
      }
    }
    let relevanceText = "";
    let depthOfFieldText = "";
    let missedThefts = 0;
    if (channel && channel.relevance) {
      const foundRelevance = relevances.find((relevance) => relevance.id === channel.relevance);
      if (foundRelevance && foundRelevance.name) {
        relevanceText = foundRelevance.name;
      }
    }
    if (channel && channel.depth_of_field) {
      const foundDepthOfField = depthOfFields.find((depthOfField) => depthOfField.id === channel.depth_of_field);
      if (foundDepthOfField && foundDepthOfField.name) {
        depthOfFieldText = foundDepthOfField.name;
      }
    }

    if (channel && channel.missed_thefts && channel.missed_thefts.length > 0) {
      missedThefts = channel.missed_thefts.length;
    }
    const { storeId, videoRecorderId, updateChannelList, removeChannel, channelIndex, canHaveSubChannels } = this.props;
    return (
      <FormDataContext.Provider value={channel}>
        {channel && !editMode && (
          <ResponsiveRow
            md="1"
            className={className}
            fields={[
              new FieldBuilder()
                .setId("channel_number")
                .setName("channel_number")
                .setComponent(this.channelNumberCheckboxComponent(channel))
                .build(),
              new FieldBuilder()
                .setId("relevance")
                .setName("relevance")
                .setComponent(<>{relevanceText}</>)
                .build(),
              new FieldBuilder()
                .setId("depth_of_field")
                .setName("depth_of_field")
                .setComponent(<>{depthOfFieldText}</>)
                .build(),
              new FieldBuilder()
                .setId("missed_thefts")
                .setName("missed_thefts")
                .setComponent(<>{this.missedTheftsComponent(missedThefts)}</>)
                .build(),
              new FieldBuilder()
                .setId("alert_channel")
                .setName("alert_channel")
                .setComponent(<>{prioText}</>)
                .build(),
              new FieldBuilder()
                .setId("favor_factor")
                .setName("favor_factor")
                .setComponent(<>{channel.favor_factor || 1} FF</>)
                .build(),
              new FieldBuilder()
                .setId("recheckings")
                .setName("recheckings")
                .setComponent(<>{recheckText}</>)
                .build(),
              new FieldBuilder()
                .setId("view_area")
                .setName("view_area")
                .setComponent(<>{this.flattenViewArea(channel.view_area)}</>)
                .build(),
              new FieldBuilder()
                .setId("is_active")
                .setName("is_active")
                .setComponent(<ToggleSwitch checked={channel.is_active} readOnly />)
                .build(),
              new FieldBuilder()
                .setId("inference_machine")
                .setName("inference_machine") // md="2"
                .setComponent(this.inferenceMachineComponent(channel))
                .build(),
            ]}
          >
            <Col className="pr-md-1" md="1">
              <Button onClick={this.toggleEditMode}>
                <i className="fas fa-edit mr-2" />
                Edit
              </Button>
            </Col>
          </ResponsiveRow>
        )}

        {channel && editMode && (
          <ChannelForm
            channel={channel}
            alertChannels={alertChannels}
            relevances={relevances}
            depthOfFields={depthOfFields}
            storeId={storeId}
            videoRecorderId={videoRecorderId}
            toggleEditMode={this.toggleEditMode}
            updateChannelList={updateChannelList}
            removeChannel={removeChannel}
            channelIndex={channelIndex}
            canHaveSubChannels={canHaveSubChannels}
            toggleDeleteMode={this.toggleDeleteMode}
            deleteMode={deleteMode}
            className={className}
          />
        )}
        {!channel && (
          <ChannelForm
            channel={channel}
            alertChannels={alertChannels}
            relevances={relevances}
            depthOfFields={depthOfFields}
            storeId={storeId}
            videoRecorderId={videoRecorderId}
            toggleEditMode={this.toggleEditMode}
            updateChannelList={updateChannelList}
            channelIndex={channelIndex}
            canHaveSubChannels={canHaveSubChannels}
          />
        )}
        {channel && channel.sub_channels && channel.sub_channels.length > 0 && (
          <tr className={className}>
            <td colSpan={12}>
              <SubChannelsTable subChannels={channel.sub_channels} storeId={storeId} channelId={channel.id} />
            </td>
          </tr>
        )}
        <hr style={{ borderColor: "grey", height: 0.5 }} />
      </FormDataContext.Provider>
    );
  }
}

export default Channel;

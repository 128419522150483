import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  InputGroup,
  CardHeader,
  CardTitle,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import getCommands from "../../services/commandsService";

class SelectCommand extends Component {
  state = {
    commands: [],
    enabled: false,
    dropDownOpen: false,
  };

  async componentDidMount() {
    await this.fetchCommands().then((adaptedCommands) => {
      this.setState({ commands: adaptedCommands }, () => {
        const { selectedCommand } = this.props;
        if (selectedCommand) {
          this.onSelectedCommandChanged(selectedCommand.id);
        }
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      enabled: nextProps.enabled,
    };
  }

  onSelectedCommandChanged = (id) => {
    const { commands } = this.state;
    const { handleSelectCommand } = this.props;
    const selectedCommand = commands.find((item) => parseInt(id) === item.id);
    handleSelectCommand(null);
    if (selectedCommand !== "" && selectedCommand !== undefined) {
      handleSelectCommand(selectedCommand);
    } else {
      handleSelectCommand(null);
    }
  };

  toggle = () => {
    const { dropDownOpen } = this.state;
    this.setState({ dropDownOpen: !dropDownOpen });
  };

  async fetchCommands() {
    const commands = await getCommands();

    const size = commands.length;
    const HardwareSpecificationCommand = {
      id: size,
      value: size,
      label: "hardware-specifications",
    };
    commands.push(HardwareSpecificationCommand);

    return commands;
  }

  render() {
    const { commands, enabled, dropDownOpen } = this.state;
    return (
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Select a command</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <InputGroup>
                    <Dropdown isOpen={dropDownOpen} toggle={this.toggle}>
                      <DropdownToggle caret>Commands</DropdownToggle>
                      <DropdownMenu>
                        {commands.map((command, index) => (
                          <DropdownItem
                            value={command.value}
                            key={index}
                            disabled={!enabled}
                            onClick={(event) => this.onSelectedCommandChanged(event.target.value)}
                          >
                            {command.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </InputGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default SelectCommand;

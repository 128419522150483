import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import triggerRemoteCommand from "../services/remoteControlService";
import DisplayWhen from "../components/common/base/DisplayWhen";
import SelectCommand from "../components/remotecontrol/SelectCommand";
import Command from "../components/remotecontrol/Command";
import SelectServer from "../components/remotecontrol/SelectServer";
import { getServerById, getVideoRecordersByMachine } from "../services/serversService";
import getCommands from "../services/commandsService";

class RemoteControlView extends Component {
  state = {
    selectedServer: null,
    enableDropdown: false,
    selectedCommand: null,
    videoRecorders: [],
    activeTabIndex: 0,
    isDisabledButton: true,
    running: false,
    listServersOutput: null,
  };

  async componentDidMount() {
    const urlServerId = this.getUrlServerId();
    if (urlServerId) {
      const [{ data: selectedServer }, commands, { data: videoRecorders }] = await Promise.all([
        this.fetchServerById(urlServerId),
        this.fetchCommands(),
        this.fetchVideoRecorders(urlServerId),
      ]);
      const selectedCommand = commands.find((item) => item.label === this.getUrlServerCommand());
      this.setState({
        selectedServer,
        enableDropdown: false,
        selectedCommand,
        videoRecorders,
      });
    }
  }

  fetchServerById = async (urlServerId) => {
    const server = await getServerById(urlServerId);
    return server;
  };

  fetchCommands = async () => {
    const commands = await getCommands();
    return commands;
  };

  fetchVideoRecorders = async (urlServerId) => {
    const videoRecorders = await getVideoRecordersByMachine(urlServerId);
    return videoRecorders;
  };

  getUrlServerId = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get("server");
  };

  getUrlServerCommand = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get("command");
  };

  cleanUrlServerId = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("server");
    const newUrl = window.location.pathname;
    window.history.replaceState({}, "", newUrl);
  };

  toggleTab = (tabIndex) => {
    const { activeTabIndex } = this.state;
    if (activeTabIndex !== tabIndex) {
      this.setState({
        activeTabIndex: tabIndex,
      });
    }
  };

  handleReachServer = (server, reached) => {
    this.setState({
      selectedServer: server,
      enableDropdown: reached,
      ...(this.getUrlServerId() ? {} : { selectedCommand: null }),
      ...(this.getUrlServerId() ? {} : { videoRecorders: [] }),
      isDisabledButton: false,
    });
    this.cleanUrlServerId();
  };

  handleSelectCommand = (command) => {
    this.setState({ selectedCommand: command });
  };

  handleRunCommand = (running) => {
    this.setState({ enableDropdown: !running });
  };

  handleListServersReverseTunnel = async () => {
    const { running } = this.state;

    if (running) {
      toast.warn("Command list servers reverse tunnel is already running");
      return;
    }

    const data = {
      hostname: "taxi",
      cmd: "list-servers-reverse-tunnel",
      cmdArgs: [],
    };

    this.setState({
      selectedCommand: { label: "list-servers-reverse-tunnel" },
    });

    this.handleRunCommand(true);
    this.setState({
      running: true,
      listServersOutput: null,
    });
    toast.info("Running list-servers-reverse-tunnel command, please wait...");
    await triggerRemoteCommand(data)
      .then((response) => {
        if (response.status === "OK") {
          toast.success("Command succeded !");
        } else if (response.status === "FAILED") {
          toast.error("Command failed...");
        }
        this.handleRunCommand(false);
        this.setState({
          listServersOutput: response.stdout,
          running: false,
        });
      })
      .catch(() => {
        this.handleRunCommand(false);
        this.setState({
          running: false,
        });
        toast.error("Unhandled error");
      });
  };

  render() {
    const {
      selectedServer,
      enableDropdown,
      selectedCommand,
      videoRecorders,
      activeTabIndex,
      isDisabledButton,
      listServersOutput,
      running,
    } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Select a server</CardTitle>
                <CardSubtitle className="card-category" style={{ textTransform: "none" }}>
                  You can only select existing servers
                </CardSubtitle>
              </CardHeader>
              <CardBody>
                <DisplayWhen condition={!this.getUrlServerId() || selectedServer}>
                  <SelectServer
                    handleListServersReverseTunnel={this.handleListServersReverseTunnel}
                    handleReachServer={this.handleReachServer}
                    server={selectedServer}
                  />
                </DisplayWhen>
                {selectedServer && (
                  <SelectCommand
                    enabled={enableDropdown}
                    handleSelectCommand={this.handleSelectCommand}
                    selectedCommandLabel={selectedCommand}
                  />
                )}
                {selectedCommand &&
                  ((selectedCommand.label !== "screenshot" &&
                    selectedCommand.label !== "connect-dvr-interface" &&
                    selectedCommand.label !== "update-dvr") ||
                    videoRecorders.length === 0) &&
                  selectedCommand.label !== "list-servers-reverse-tunnel" && (
                    <Command
                      server={selectedServer}
                      command={selectedCommand}
                      handleRunCommand={this.handleRunCommand}
                      videoRecorder={null}
                      isDisabledButton={isDisabledButton}
                    />
                  )}
                {selectedCommand &&
                  (selectedCommand.label === "screenshot" ||
                    selectedCommand.label === "connect-dvr-interface" ||
                    selectedCommand.label === "update-dvr") &&
                  videoRecorders.length > 0 && (
                    <Nav tabs>
                      {videoRecorders.map((videoRecorder, index) => (
                        <NavItem role="button">
                          <NavLink
                            className={activeTabIndex === index ? "active" : ""}
                            onClick={() => {
                              this.toggleTab(index);
                            }}
                          >
                            <p>
                              DVR {index + 1} : {videoRecorder.video_recorder_type_name}
                            </p>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  )}
                {selectedCommand &&
                  (selectedCommand.label === "screenshot" ||
                    selectedCommand.label === "connect-dvr-interface" ||
                    selectedCommand.label === "update-dvr") &&
                  videoRecorders.length > 0 && (
                    <TabContent activeTab={activeTabIndex}>
                      {videoRecorders.map((videoRecorder, index) => (
                        <TabPane tabId={index}>
                          <Command
                            server={selectedServer}
                            command={selectedCommand}
                            handleRunCommand={this.handleRunCommand}
                            videoRecorder={videoRecorder}
                            isDisabledButton={isDisabledButton}
                          />
                        </TabPane>
                      ))}
                    </TabContent>
                  )}
                {running && <Spinner color="primary" />}
                {selectedCommand && selectedCommand.label === "list-servers-reverse-tunnel" && listServersOutput && (
                  <div>
                    <h5>List servers connected to the reverse tunnel:</h5>
                    <pre>{listServersOutput}</pre>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RemoteControlView;

import React from "react";
import { Row, Col, Button } from "reactstrap";
import { saveOpeningHour, deleteOpeningHour } from "../../services/openingHourService";
import StatusIndicator from "../common/StatusIndicator/StatusIndicator";
import BaseForm from "../common/BaseForm";
import OpeningHour from "../models/OpeningHourModel";
import "../channels/Channel.scss";
import ResponsiveRow from "./base/ResponsiveRow";
import FormDataContext from "../shared/contexts/FormDataContext";
import FieldBuilder from "../shared/utils/FieldBuilder";

class OpeningHourForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    status: "started",
    deleteMode: false,
    days: [],
  };
  opening_hour = new OpeningHour();
  schema = this.opening_hour.getSchema();

  setDaysOptions() {
    const days = [
      { id: "default", name: "default" },
      { id: "monday", name: "monday" },
      { id: "tuesday", name: "tuesday" },
      { id: "wednesday", name: "wednesday" },
      { id: "thursday", name: "thursday" },
      { id: "friday", name: "friday" },
      { id: "saturday", name: "saturday" },
      { id: "sunday", name: "sunday" },
    ];
    this.setState({ days });
  }

  componentDidMount() {
    this.setDaysOptions();
    const { openingHour } = this.props;
    let data;
    if (openingHour) {
      data = openingHour;
    } else {
      data = this.opening_hour.getDefaultData();
      data.store = this.props.storeId;
    }
    this.setState({ data });
  }

  save = async () => {
    if (this.state.data.is_open && (this.state.data.time_open === "" || this.state.data.time_close === "")) {
      const status = "Invalid data provided! ";
      this.setState({ status });
      return;
    }
    let status = "saving";
    this.setState({ status });
    try {
      const { data: openingHour, status: resCode } = await saveOpeningHour(this.state.data);
      status = resCode < 300 ? "success" : "Could not save opening hour";
      this.setState({ status });
      if (status === "success") {
        this.props.updateOpeningHoursList(openingHour);
      }
    } catch (exception) {
      if (exception.response) {
        status = "Invalid data provided! ";
        if (exception.response.data.non_field_errors) {
          status += exception.response.data.non_field_errors;
        }
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      console.log(exception.response);
      this.setState({ status });
    }
  };

  delete = async () => {
    let status = "deleting";
    this.setState({ status });
    try {
      const res = await deleteOpeningHour(this.state.data.id);
      if (res.status === 204) {
        this.props.removeFromOpeningHoursList(this.state.data.id);
      }
    } catch (exception) {
      if (exception.response) {
        status = "Failed to delete!";
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  render() {
    const { data, status } = this.state;
    const { className, deleteMode } = this.props;
    let statusType = null;
    let message = "";
    switch (status) {
      case "edited":
        statusType = null;
        message = null;
        break;
      case "started":
        statusType = null;
        message = null;
        break;
      case "saving":
        statusType = "progress";
        message = "saving...";
        break;
      case "deleting":
        statusType = "progress";
        message = "deleting...";
        break;
      case "success":
        statusType = "success";
        message = "saved!";
        break;
      default:
        statusType = "failed";
        message = status;
    }
    const statusIndicator = (
      <div className="float-right m-2">
        <StatusIndicator status={statusType} message={message} />
      </div>
    );

    let isOpenComponent = undefined;
    if (data.day !== "default") {
      isOpenComponent = this.renderToggleSwitch("is_open", "Open");
    }

    let openingHourComponent;
    let closingHourComponent = undefined;
    if (data.is_open) {
      openingHourComponent = this.renderInput("time_open", "Opening Hour", "time");
      closingHourComponent = this.renderInput("time_close", "Closing Hour", "time");
    } else {
      openingHourComponent = <>The store is closed on {this.state.data.day}</>;
    }

    return (
      <React.Fragment>
        <div className={className}>
          <FormDataContext.Provider value={data}>
            <ResponsiveRow
              fields={[
                new FieldBuilder()
                  .setId("day")
                  .setName("day")
                  .setVisible(data.day === "default")
                  .setComponent(
                    this.renderInput("day", "Day", null, {
                      disabled: true,
                    })
                  )
                  .build(),
                new FieldBuilder()
                  .setId("day")
                  .setName("day")
                  .setVisible(data.day !== "default")
                  .setComponent(this.renderSelect("day", "Day", this.state.days))
                  .build(),
                new FieldBuilder()
                  .setId("time_open")
                  .setName("time_open")
                  .setVisible(data.is_open)
                  .setComponent(openingHourComponent)
                  .build(),
                new FieldBuilder()
                  .setId("time_close")
                  .setName("time_close")
                  .setVisible(data.is_open)
                  .setComponent(closingHourComponent)
                  .build(),
                new FieldBuilder()
                  .setId("is_open")
                  .setName("is_open")
                  .setVisible(data.day !== "default")
                  .setComponent(isOpenComponent)
                  .build(),
              ]}
            />
          </FormDataContext.Provider>

          <Row>
            {!deleteMode && (
              <Col className="pr-md-4" md="12">
                <Button className="btn-success m-2 float-right" onClick={this.save}>
                  <i className="fas fa-check" />
                </Button>
                {this.state.data &&
                  Object.prototype.hasOwnProperty.call(this.state.data, "id") &&
                  this.state.data.id &&
                  this.state.data.day !== "default" && (
                    <Button className="btn-danger m-2 float-right" onClick={this.props.toggleDeleteMode}>
                      <i className="fas fa-trash-alt" />
                    </Button>
                  )}
              </Col>
            )}
          </Row>

          <Row>
            {deleteMode && (
              <Col className="pr-md-4 float-right " md="3">
                <Button className="float-right btn-danger m-2" onClick={this.delete}>
                  <i className="fas fa-trash-alt mr-2" />
                  Delete
                </Button>

                <Button className="float-right btn-secondary m-2" onClick={this.props.toggleDeleteMode}>
                  <i className="fas fa-times mr-2" />
                  Cancel
                </Button>
              </Col>
            )}
          </Row>
        </div>
        {statusType && (
          <tr className="status-container">
            <td colSpan={9}>{statusIndicator}</td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

export default OpeningHourForm;

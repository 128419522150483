import React, { Component } from "react";
import Gchat from "./Gchat";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from "reactstrap";

class GchatList extends Component {
  state = { gchats: [], showNewGchat: false };

  componentDidMount() {
    const { gchats, notifier, store } = this.props;
    this.setState({ gchats, notifier, store });
  }

  toggleAddGchat = () => {
    const { showNewGchat } = this.state;
    this.setState({ showNewGchat: !showNewGchat });
  };

  updateGchatList = (gchat) => {
    const { gchats } = this.state;
    const index = gchats.map((data) => data.id).indexOf(gchat.id);
    let { showNewGchat } = this.state;
    if (index === -1) {
      gchats.unshift(gchat);
      showNewGchat = false;
    } else {
      gchats[index] = gchat;
    }
    this.setState({ gchats, showNewGchat });
  };

  removeFromGchatList = (gchatid) => {
    const { gchats } = this.state;
    const updatedGchats = gchats.filter((gchat) => gchat.id !== gchatid);
    this.setState({ gchats: updatedGchats });
  };

  render() {
    const { gchats, notifier, store, showNewGchat } = this.state;
    const { onSaveNotifier } = this.props;
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Google Chat</CardTitle>
          <Row>
            <Col md={12}>
              <Button className="float-right" onClick={this.toggleAddGchat}>
                {showNewGchat ? "Cancel" : "Add Google Chat Space"}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {showNewGchat && (
            <Gchat
              key="new"
              gchat={null}
              notifier={notifier}
              onSaveNotifier={onSaveNotifier}
              store={store}
              toggleAddGchat={this.toggleAddGchat}
              updateGchatList={this.updateGchatList}
              removeFromGchatList={this.removeFromGchatList}
              editMode
              showNewGchat={showNewGchat}
            />
          )}
          {gchats &&
            gchats.map((gchat) => (
              <Gchat
                key={gchat.id}
                gchat={gchat}
                notifier={notifier}
                onSaveNotifier={onSaveNotifier}
                store={store}
                toggleAddGchat={this.toggleAddGchat}
                updateGchatList={this.updateGchatList}
                removeFromGchatList={this.removeFromGchatList}
                editMode={false}
                showNewGchat={showNewGchat}
              />
            ))}
        </CardBody>
      </Card>
    );
  }
}

export default GchatList;

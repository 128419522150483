import React from "react";
import { uniqueId } from "lodash";
import { Input, Label, FormGroup } from "reactstrap";
function SelectControl({ name, label, options, error, ...rest }) {
  const id = uniqueId(`${name}-`);
  const optionsLabelKey = rest.optionsLabelKey ? rest.optionsLabelKey : "name";
  return (
    <FormGroup>
      <Label htmlFor={id}>{label}</Label>
      <Input {...rest} type="select" name={name} id={id} className="custom-select">
        {options.map((option) => (
          <option disabled={option.enabled === false} key={option.id} value={option.id}>
            {option[optionsLabelKey]}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
}

export default SelectControl;

import http from "./httpService";

const apiEndPoint = "/machines/";

export function getServerByHostname(server) {
  const url = `${apiEndPoint}get_by_hostname/${server}/`;
  return http.get(url);
}

export function getServerById(id) {
  const url = `${apiEndPoint}get_by_id/${id}/`;
  return http.get(url);
}

export function getVideoRecordersByMachine(machineId) {
  const url = `${apiEndPoint}${machineId}/store_videorecorders/`;
  return http.get(url);
}

export function getAllReachableServers(params) {
  const url = `${apiEndPoint}all_reachable_servers/`;
  return http.post(url, params);
}

export function getAuthorizedGroups() {
  const url = "/locations/list_authorized_groups";
  return http.get(url);
}

export function getHardwareSpecificationsByHostname(hostname) {
  const url = `/inference-machine-specs/${hostname}/`;
  return http.get(url);
}

import Model from "../Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class ScanNetworkModel extends Model {
  constructor() {
    super();
    this.network = this.createField(
      "192.168.1.0/24",
      groups.PRIMARY,
      "Subnetwork",
      Joi.string().ip({ cidr: "required" }).required()
    );
    this.port = this.createField(554, groups.PRIMARY, "Port", Joi.number().required());
  }
}

export default ScanNetworkModel;

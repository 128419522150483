import { uniqueId } from "lodash";
import React, { Component } from "react";
import { Button, Table } from "reactstrap";
import SubChannel from "./SubChannel";
import "./SubChannels.scss";

class SubChannelsTable extends Component {
  state = { subChannels: null };

  componentDidMount() {
    const { subChannels } = this.props;
    this.setState({ subChannels });
  }

  addNewSubChannel = () => {
    const { subChannels } = this.state;
    subChannels.push(null);
    this.setState({ subChannels });
  };

  updateSubChannelList = (subChannel, index) => {
    const { subChannels } = this.state;
    if (!subChannel) {
      subChannels.splice(index, 1);
    } else {
      subChannels[index] = subChannel;
    }

    this.setState({ subChannels });
  };

  removeSubChannel = (subChannelId) => {
    const { subChannels } = this.state;
    const existingSubChannels = subChannels.filter((subChannel) => subChannel.id !== subChannelId);
    this.setState({ subChannels: existingSubChannels });
  };

  render() {
    const { subChannels } = this.state;
    const { storeId, channelId } = this.props;
    return (
      <div className="sub-channels">
        <Table className="tablesorter" responsive>
          <thead className="text-primary">
            <tr>
              <th>Sub channel number</th>
              <th>Sub channel name</th>
              <th>Favor factor</th>
              <th>View area/pts</th>
              <th>Anchor</th>
              <th>Active</th>
              <th className="text-center" />
            </tr>
          </thead>
          <tbody>
            {subChannels &&
              subChannels.map((subChannel, index) => (
                <SubChannel
                  key={subChannel ? subChannel.id : uniqueId("sub_channel-")}
                  subChannel={subChannel}
                  storeId={storeId}
                  updateSubChannelList={this.updateSubChannelList}
                  removeSubChannel={this.removeSubChannel}
                  channelId={channelId}
                  subChannelIndex={index}
                />
              ))}
            <tr>
              <td colSpan={8}>
                <Button className="float-right" onClick={this.addNewSubChannel}>
                  <i className="fas fa-plus mr-2" />
                  Add new sub channel
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SubChannelsTable;

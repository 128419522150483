/* eslint-disable no-param-reassign */

import React from "react";

function getPageNumbers(pageCount, currentPage = 1, limit = 3) {
  const pageNumbers = [];

  let startIndex = currentPage - limit;

  if (startIndex < 1) {
    limit += 1 - startIndex;
    startIndex = 1;
  }

  let endIndex = currentPage + limit;
  if (endIndex > pageCount) {
    startIndex = Math.max(startIndex - (endIndex - pageCount), 1);
    endIndex = pageCount;
  }

  for (let i = startIndex; i < endIndex + 1; i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
}

function Pagination(props) {
  const { itemsCount, pageSize, onPageChange, currentPage, label = "stores" } = props;
  if (pageSize === 0) return null;

  const pageCount = Math.ceil(itemsCount / pageSize);
  if (pageCount === 1) return null;

  const pageNumbers = getPageNumbers(pageCount, currentPage);

  let classNames = "py-2 px-3 cursor-pointer leading-tight first:rounded-l-lg last:rounded-r-lg";
  classNames += " text-gray-700 hover:text-gray-900 dark:text-gray-200 hover:dark:text-gray-100";
  const classNamesNormal = `${classNames} bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 hover:dark:bg-gray-600`;

  const activeClass = " bg-blue-500 dark:bg-blue-700 hover:bg-blue-500 hover:dark:bg-blue-500";

  const classNamesActive = classNames + activeClass;
  return (
    <div className="flex flex-col items-center">
      <span className="text-sm text-gray-700 dark:text-gray-400">
        Showing <span className="font-semibold text-gray-900 dark:text-white">{pageSize}</span> of{" "}
        <span className="font-semibold text-gray-900 dark:text-white">{itemsCount}</span> {label}
      </span>
      <br />
      <nav aria-label="Page navigation">
        <ul className="inline-flex space-x-px">
          {pageNumbers.map((pageNumber) => (
            <li
              key={pageNumber}
              onClick={() => onPageChange(pageNumber)}
              className={pageNumber === currentPage ? classNamesActive : classNamesNormal}
            >
              {pageNumber}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;

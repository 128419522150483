import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  debug: process.env.REACT_APP_ENV === "development",
  release: `manager-dashboard@${process.env.REACT_APP_VERSION}`,

  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        "127.0.0.1:8000/api",
        "localhost:8000/api",
        "manager.prod.veesion.io/api",
        "manager.preprod.veesion.io/api",
      ],
      beforeNavigate: (context) => {
        return {
          ...context,
          name: window.location.pathname.replace(/\/[a-f0-9]{32}/g, "/<hash>").replace(/\/\d+/g, "/<digits>"),
        };
      },
    }),
  ],

  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import Joi from "joi-browser";

import BaseForm from "../common/BaseForm";
import { addOrUpdateVideoTune } from "../../services/storeService";
import { toast } from "react-toastify";
import ResponsiveRow from "./base/ResponsiveRow";
import FormDataContext from "../shared/contexts/FormDataContext";
import FieldBuilder from "../shared/utils/FieldBuilder";

class VideoTuneForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  default_acceleration = 2.0;
  default_constant_rate = 28;
  default_fps = 5;

  schema = {
    acceleration: Joi.number().min(1).max(2.5).default(this.default_acceleration).label("Acceleration"),
    constant_rate: Joi.number().min(15).max(38).default(this.default_constant_rate).label("Constant Rate"),
    fps: Joi.number().min(5).max(15).default(this.default_fps).label("FPS"),
  };

  componentDidMount() {
    const { videoTune, store } = this.props;
    let data = {};
    if (videoTune) {
      data = { ...videoTune };
    } else {
      data.store = store.id;
    }
    this.setState({ data });
  }

  save = async () => {
    this.props.store.video_tune = { ...this.state.data };
    try {
      await addOrUpdateVideoTune(this.props.store);
      this.props.onSaveVideoTune(this.props.store.video_tune);
      toast.info("Store config updated.");
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 400) {
          Object.keys(ex.response.data).forEach(function (key) {
            const message = `${key}: ${ex.response.data[key][0]}`;
            toast.error(message);
          });
        } else if (ex.response.status === 404) {
          toast.error("Store not found");
        } else {
          toast.error("Failed to update");
        }
      } else {
        toast.error("Failed to update");
      }
    }
  };

  render() {
    const { data } = this.state;

    return (
      <Card className="video-tune">
        <CardHeader>
          <CardTitle tag="h4">Video Tune</CardTitle>
        </CardHeader>
        <CardBody>
          <FormDataContext.Provider value={data}>
            <ResponsiveRow
              md="4"
              fields={[
                new FieldBuilder()
                  .setId("constant_rate")
                  .setName("constant_rate")
                  .setComponent(
                    <>
                      {this.renderInput("constant_rate", "Compression", "number")}
                      <label>default : {this.default_constant_rate}</label>
                    </>
                  )
                  .build(),
                new FieldBuilder()
                  .setId("fps")
                  .setName("fps")
                  .setComponent(
                    <>
                      {this.renderInput("fps", "FPS", "number")}
                      <label>default : {this.default_fps}</label>
                    </>
                  )
                  .build(),
                new FieldBuilder()
                  .setId("acceleration")
                  .setName("acceleration")
                  .setComponent(
                    <>
                      {this.renderInput("acceleration", "Acceleration", "number")}
                      <label>default : {this.default_acceleration.toFixed(2)}</label>
                    </>
                  )
                  .build(),
              ]}
              bottomSeparator={false}
            />
          </FormDataContext.Provider>

          <Row>
            <Col>
              <Button className="float-right btn-success m-2" onClick={this.save}>
                <i className="fas fa-save mr-2" />
                Save
              </Button>
            </Col>
          </Row>
          <hr
            style={{
              borderColor: "grey",
              height: 0.5,
            }}
          />
          <p className="text-warning">
            Before you use these settings : please read the notion page (link
            <a
              href="https://www.notion.so/VM-ENG-7c05f1d36a5247778a6976ef667cc567#fc367b121a1b4a13bb06ff96ec529130"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;here
            </a>
            ), which explains in which context they should be modified, and in which context the modification won&apos;t
            have impact
          </p>
        </CardBody>
      </Card>
    );
  }
}

export default VideoTuneForm;

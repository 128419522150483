import axios from "axios";
import responseErrorsInterceptor from "./interceptors/responses";
import requestHeadersInterceptor from "./interceptors/requests";

export default class AxiosFactory {
  /**
   * Creates a new preconfigured axios instance.
   *
   * @returns {AxiosInstance}
   */
  static newInstance() {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: "application/permissions+json",
      },
    });

    axiosInstance.interceptors.request.use(requestHeadersInterceptor);
    axiosInstance.interceptors.response.use(null, responseErrorsInterceptor);

    return axiosInstance;
  }
}

import http from "./httpService";

const apiEndPoint = "/stores/videorecorders/";

function getUrl(location) {
  return `${apiEndPoint}${location}/`;
}

function getVideoRecordersList(storeLocation) {
  return http.get(getUrl(storeLocation));
}

export default getVideoRecordersList;

import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import NmapModel from "../../models/remotecontrol/NmapModel";

class NmapForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  nmap = new NmapModel();
  schema = this.nmap.getSchema();

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  componentDidMount() {
    // TODO: Add validation on network
    const data = this.nmap.getDefaultData();
    this.setState({ data });
  }

  render() {
    return (
      <Form>
        <Row>
          <Col md="6">{this.renderInput("ip", "Ip address")}</Col>
          <Col md="6">{this.renderInput("port", "Port (554 or 80-554)")}</Col>
        </Row>
        <Row>
          <Col md="12">{this.renderToggleSwitch("fullscan", "Full Scan (-Pn)")}</Col>
        </Row>
        <Col md="12">
          <Button className="float-right btn-success m-2" onClick={this.handleSubmit}>
            <i className="fas fa-search mr-2" />
            Nmap
          </Button>
        </Col>
      </Form>
    );
  }
}

export default NmapForm;

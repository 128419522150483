import http from "./httpService";

const apiEndPoint = "/watchernotifiers/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function getWatcherNotifiers() {
  return http.get(apiEndPoint);
}

export function getWatcherNotifier(watcherId) {
  return http.get(getUrl(watcherId));
}

export function updateWatcherNotifier(watcher) {
  return http.put(getUrl(watcher.id), watcher);
}

export function addWatcherNotifier(watcher) {
  return http.post(apiEndPoint, watcher);
}

export function deleteWatcherNotifier(watcherId) {
  return http.delete(getUrl(watcherId));
}

const incidentTypesGroups = [
  {
    name: "bug_machine",
    displayName: "Bug Machine",
    incidentTypes: [
      {
        name: "bug_machine:nuc",
        icon: "fa-server",
        displayName: "NUC",
        requires: ["machine"],
      },
      {
        name: "bug_machine:server",
        icon: "fa-microchip",
        displayName: "Server",
        requires: ["machine"],
      },
    ],
  },
  {
    name: "alert_zero",
    displayName: "Alert Zero",
    incidentTypes: [
      {
        name: "alert_zero:internet_problem",
        icon: "fa-wifi",
        displayName: "Internet Problem",
        requires: [],
      },
      {
        name: "alert_zero:dvr_lost",
        displayName: "DVR Lost",
        icon: "fa-plug",
        requires: [],
      },
      {
        name: "alert_zero:unstable_streams",
        icon: "fa-video-slash",
        displayName: "Unstable Streams",
        requires: [],
      },
      {
        name: "alert_zero:dvr_to_reboot",
        displayName: "DVR to Reboot",
        icon: "fa-sync",
        requires: [],
      },
      {
        name: "alert_zero:wrong_credentials",
        icon: "fa-lock",
        displayName: "Wrong Credentials",
        requires: [],
      },
    ],
  },
  {
    name: "fail_fps",
    displayName: "Fail FPS",
    incidentTypes: [
      {
        name: "fail_fps:fail_fps",
        icon: "fa-poll",
        displayName: "Fail FPS",
        requires: [],
      },
    ],
  },
];

export default incidentTypesGroups;

import React, { Component } from "react";
import OpeningHour from "./OpeningHour";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import { getStore } from "../../services/storeService";

class OpeningHoursList extends Component {
  state = { openingHours: [], showNewOpeningHour: false, highlight: null };

  componentDidMount() {
    const { openingHours } = this.props;
    this.setState({ openingHours });
  }

  toggleAddOpeningHour = () => {
    const { showNewOpeningHour } = this.state;
    this.setState({ showNewOpeningHour: !showNewOpeningHour });
  };
  fetchOpeningHours = async () => {
    const { storeId } = this.props;
    const { data: store } = await getStore(storeId);
    return store.opening_hours;
  };
  updateOpeningHoursList = (openingHour) => {
    const openingHoursData = this.fetchOpeningHours();
    openingHoursData.then((openingHours) => {
      let showNewOpeningHour = false;
      let highlight = null;
      const index = openingHours.map((data) => data.id).indexOf(openingHour.id);
      if (index === -1) {
        openingHours.unshift(openingHour);
        showNewOpeningHour = false;
        highlight = openingHour.id;
      } else {
        openingHours[index] = openingHour;
      }
      this.setState({ openingHours, showNewOpeningHour, highlight });
    });
  };

  removeFromOpeningHoursList = (openingHourId) => {
    const { openingHours } = this.state;
    const updatedOpeningHours = openingHours.filter((openingHour) => openingHour.id !== openingHourId);
    this.setState({ openingHours: updatedOpeningHours });
  };

  render() {
    const { openingHours, showNewOpeningHour, highlight } = this.state;
    const { storeId } = this.props;
    const orderedDay = {
      default: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };
    openingHours.sort((a, b) => {
      return orderedDay[a.day] > orderedDay[b.day] ? 1 : -1;
    });

    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Opening Hours</CardTitle>
          <Row>
            <Col md={12}>
              <Button className="float-right" onClick={this.toggleAddOpeningHour}>
                {showNewOpeningHour ? "Cancel" : "Add opening hour"}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {showNewOpeningHour && (
            <OpeningHour
              key="new"
              openingHour={null}
              storeId={storeId}
              toggleAddOpeningHour={this.toggleAddOpeningHour}
              updateOpeningHoursList={this.updateOpeningHoursList}
              removeFromOpeningHoursList={this.removeFromOpeningHoursList}
            />
          )}
          {openingHours &&
            openingHours.map((openingHour) => (
              <OpeningHour
                key={openingHour.id}
                openingHour={openingHour}
                updateOpeningHoursList={this.updateOpeningHoursList}
                removeFromOpeningHoursList={this.removeFromOpeningHoursList}
                highlight={highlight}
              />
            ))}
          <p className="text-warning">Stores should close at least one hour each day to work correctly</p>
        </CardBody>
      </Card>
    );
  }
}

export default OpeningHoursList;

import { Route } from "react-router-dom";
import RoleBasedAccess from "../base/RoleBasedAccess";
import auth from "../../../services/authService";

function RoleBasedRoute({ roles, component: Component, ...rest }) {
  const isAuthenticated = auth.isAuthenticated();

  if (isAuthenticated) {
    if (roles.length > 0) {
      return (
        <RoleBasedAccess roles={roles}>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </RoleBasedAccess>
      );
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

export default RoleBasedRoute;

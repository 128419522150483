import { useState, useEffect } from "react";
import { Button } from "reactstrap";
import InputControl from "../common/controls/Input";
import { validateInteger, validateTextPolygon, validatePolygon } from "../../utils/validationUtils";
import { MINIMUM_RESOLUTION, MAXIMUM_RESOLUTION } from "../shared/constants";
import {
  isResolution,
  polygonToRepresentation,
  textPolygonToInternalValue,
  getTextPolygonAnchor,
  anchorTextPolygon,
  extractTextPolygonAnchorPoints,
} from "../../utils/polygonUtils";

export default function PolygonForm({
  inputTextPolygon,
  newPolygons,
  originalResolution,
  index,
  onDeletePolygon,
  onChangeTextPolygon,
  onSetHasErrors,
}) {
  const errorNoOriginalResolutionPolygon = "Can't edit polygons without original resolution";
  const errorNoOriginalResolutionAnchor = "Can't change polygon anchor without original resolution";

  const initialAnchor = getTextPolygonAnchor(inputTextPolygon, originalResolution);
  const [initialTextPolygon, initialFirstPoint, initialLastPoint] = initialAnchor
    ? extractTextPolygonAnchorPoints(inputTextPolygon)
    : [inputTextPolygon, "", ""];

  const [textPolygon, setTextPolygon] = useState(initialTextPolygon);
  const [error, setError] = useState(null);
  const [errorX, setErrorX] = useState(null);
  const [errorY, setErrorY] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [customResolution, setCustomResolution] = useState(originalResolution);
  const [sameResolution, setSameResolution] = useState(true);
  const [anchor, setAnchor] = useState(initialAnchor);
  const [anchorFirstPoint, setAnchorFirstPoint] = useState(initialFirstPoint);
  const [anchorLastPoint, setAnchorLastPoint] = useState(initialLastPoint);

  useEffect(() => {
    if (
      (error || errorX || errorY) &&
      ![errorNoOriginalResolutionPolygon, errorNoOriginalResolutionAnchor].includes(error)
    ) {
      onSetHasErrors(true);
      return;
    }
    onSetHasErrors(false);
  }, [error, errorX, errorY, onSetHasErrors]);

  function setAnchorPoints(textPolygonToAnchor) {
    const [updatedTextPolygon, firstPoint, lastPoint] = extractTextPolygonAnchorPoints(textPolygonToAnchor);
    setTextPolygon(updatedTextPolygon);
    setAnchorFirstPoint(firstPoint);
    setAnchorLastPoint(lastPoint);
  }

  function toggleConfirmDelete() {
    setConfirmDelete(!confirmDelete);
  }

  function handleSetAnchor(newAnchor) {
    if (!isResolution(originalResolution)) {
      setError(errorNoOriginalResolutionPolygon);
      return;
    }
    const textPolygonErrorMessage = validateTextPolygon(textPolygon);
    const polygonErrorMessage = validatePolygon(textPolygonToInternalValue(textPolygon), customResolution);
    if (textPolygonErrorMessage) {
      setError(textPolygonErrorMessage);
      return;
    }
    if (polygonErrorMessage) {
      setError(polygonErrorMessage);
      return;
    }
    if (newAnchor !== "") {
      const anchoredTextPolygon = anchorTextPolygon(textPolygon, newAnchor, customResolution);
      onChangeTextPolygon(index, anchoredTextPolygon);
      setAnchorPoints(anchoredTextPolygon);
    } else {
      setAnchorFirstPoint("");
      setAnchorLastPoint("");
      onChangeTextPolygon(index, textPolygon);
    }
    setAnchor(newAnchor);
  }

  function handleChangeTextPolygon({ currentTarget: input }) {
    if (!isResolution(originalResolution)) {
      setError(errorNoOriginalResolutionAnchor);
      return;
    }
    setAnchor("");
    setAnchorFirstPoint("");
    setAnchorLastPoint("");
    setTextPolygon(input.value);
    if (errorX || errorY) {
      return;
    }
    const textPolygonErrorMessage = validateTextPolygon(input.value);
    const polygonErrorMessage = validatePolygon(textPolygonToInternalValue(input.value), customResolution);
    if (textPolygonErrorMessage) {
      setError(textPolygonErrorMessage);
      return;
    }
    if (polygonErrorMessage) {
      setError(polygonErrorMessage);
      return;
    }
    setError("");
    onChangeTextPolygon(index, input.value);
  }

  function handleChangeResolution({ currentTarget: input }) {
    const inputPixels = input.value;
    // Remove current anchor if setting a custom resolution
    setAnchor("");
    setAnchorFirstPoint("");
    setAnchorLastPoint("");
    // Update customResolution variable to the new text value currently being entered
    const updatedCustomResolution = { ...customResolution };
    updatedCustomResolution[input.name] = inputPixels;
    setCustomResolution(updatedCustomResolution);
    const errorMessage = validateInteger(inputPixels, MINIMUM_RESOLUTION, MAXIMUM_RESOLUTION);
    let copyErrorX = errorX;
    let copyErrorY = errorY;
    if (input.name === "width") {
      copyErrorX = errorMessage;
      if (errorMessage) {
        setErrorX(errorMessage);
        return;
      }
      setErrorX("");
    }
    if (input.name === "height") {
      copyErrorY = errorMessage;
      if (errorMessage) {
        setErrorY(errorMessage);
        return;
      }
      setErrorY("");
    }
    if (!copyErrorX && !copyErrorY) {
      updatedCustomResolution.width = parseInt(updatedCustomResolution.width);
      updatedCustomResolution.height = parseInt(updatedCustomResolution.height);
      setCustomResolution(updatedCustomResolution);
      setSameResolution(JSON.stringify(updatedCustomResolution) === JSON.stringify(originalResolution));
      const textPolygonErrorMessage = validateTextPolygon(textPolygon);
      const polygonErrorMessage = validatePolygon(textPolygonToInternalValue(textPolygon), updatedCustomResolution);
      if (textPolygonErrorMessage || polygonErrorMessage) {
        setError(textPolygonErrorMessage || polygonErrorMessage);
        return;
      }
      setError("");
    }
  }

  function handleApplyScaling() {
    if (errorX || errorY) {
      return;
    }
    const textPolygonErrorMessage = validateTextPolygon(textPolygon);
    const polygonErrorMessage = validatePolygon(textPolygonToInternalValue(textPolygon), customResolution);
    const xErrorMessage = validateInteger(customResolution.width, MINIMUM_RESOLUTION, MAXIMUM_RESOLUTION);
    const yErrorMessage = validateInteger(customResolution.height, MINIMUM_RESOLUTION, MAXIMUM_RESOLUTION);
    setErrorX(xErrorMessage);
    setErrorY(yErrorMessage);
    if (textPolygonErrorMessage || polygonErrorMessage) {
      setError(textPolygonErrorMessage || polygonErrorMessage);
      return;
    }
    if (xErrorMessage || yErrorMessage) {
      return;
    }
    const scaleFactorX = originalResolution.width / parseInt(customResolution.width);
    const scaleFactorY = originalResolution.height / parseInt(customResolution.height);
    const polygon = textPolygonToInternalValue(textPolygon);
    for (let i = 0; i < polygon.length; i++) {
      const point = [Math.floor(polygon[i][0] * scaleFactorX), Math.floor(polygon[i][1] * scaleFactorY)];
      polygon[i] = point;
    }
    setCustomResolution(originalResolution);
    setSameResolution(true);
    const updatedTextPolygon = polygonToRepresentation(polygon);
    setTextPolygon(polygonToRepresentation(polygon));
    onChangeTextPolygon(index, updatedTextPolygon);
  }

  function handleDeletePolygon() {
    setConfirmDelete(false);
    onDeletePolygon(index);
  }

  return (
    <div>
      <div id={`polygon${index}`} className="d-flex w-100 polygon-input" name="polygon">
        {anchor && anchorFirstPoint && (
          <div id="anchorPoint1" className="d-flex flex-column w-25">
            <label htmlFor={`${index}anchor1`} style={{ fontSize: "13px", height: "24px", paddingTop: "10px" }}>
              Anchor first point
            </label>
            <InputControl id={`${index}anchor1`} disabled value={anchorFirstPoint} />
          </div>
        )}
        <div id="textPolygon" className={`d-flex flex-column ${anchor ? "w-75" : "w-100"}`}>
          <label htmlFor={`${index}textPolygon`}>{`Polygon ${index + 1}*`}</label>
          <InputControl
            id={`${index}textPolygon`}
            disabled={errorX || errorY}
            value={textPolygon}
            onChange={handleChangeTextPolygon}
            error={error}
          />
        </div>
        {anchor && anchorLastPoint && (
          <div id="anchorPoint2" className="d-flex flex-column w-25">
            <label htmlFor="anchor2" style={{ fontSize: "13px", height: "24px", paddingTop: "10px" }}>
              Anchor last point
            </label>
            <InputControl id="anchor2" disabled value={anchorLastPoint} />
          </div>
        )}
        <span className="spacer m-1" />
        {!confirmDelete && (
          <Button
            className="float-right"
            style={{ margin: "31px 0 10px 0", maxHeight: "38px" }}
            onClick={toggleConfirmDelete}
          >
            <i className="fas fa-trash-alt" />
          </Button>
        )}
      </div>
      {newPolygons.includes(index) && (
        <div id="scaleByResolution" className="d-flex w-100">
          <InputControl name="width" value={customResolution.width} onChange={handleChangeResolution} error={errorX} />
          <span className="spacer" style={{ margin: "5px" }} />
          <InputControl
            name="height"
            value={customResolution.height}
            onChange={handleChangeResolution}
            error={errorY}
          />
          <span className="spacer" style={{ margin: "5px" }} />
          {sameResolution && (
            <Button
              disabled
              className="float-right w-100"
              style={{ margin: "0 0 10px 0", maxHeight: "38px" }}
              name="apply scaling"
            >
              Original resolution
            </Button>
          )}
          {!sameResolution && (
            <Button
              className="btn-success float-right w-100"
              style={{ margin: "0 0 10px 0", maxHeight: "38px" }}
              name="apply scaling"
              onClick={handleApplyScaling}
            >
              <i className="fas fa-trash-alt" />
              Apply scaling
            </Button>
          )}
        </div>
      )}
      {!errorX && !errorY && sameResolution && (
        <div id="anchorManagement" className="d-flex w-100">
          <p style={{ margin: "6px 0 0 0" }}>Anchor : </p>
          <span className="spacer m-1" />
          <Button className="btn btn-sm" disabled={anchor === "top"} onClick={() => handleSetAnchor("top")}>
            Top
          </Button>
          <Button className="btn btn-sm" disabled={anchor === "bot"} onClick={() => handleSetAnchor("bot")}>
            Bottom
          </Button>
          <Button className="btn btn-sm" disabled={anchor === "left"} onClick={() => handleSetAnchor("left")}>
            Left
          </Button>
          <Button className="btn btn-sm" disabled={anchor === "right"} onClick={() => handleSetAnchor("right")}>
            Right
          </Button>
          <Button className="btn btn-sm" disabled={anchor === ""} onClick={() => handleSetAnchor("")}>
            <i className="fas fa-times" />
          </Button>
        </div>
      )}
      {confirmDelete && (
        <div id="deleteConfirmation" className="d-flex w-100">
          <span className="float-left text-danger" style={{ marginTop: "14px" }}>
            Are you sure you want to delete polygon {index + 1}?
          </span>
          <Button className="float-left m-2 w-30" onClick={toggleConfirmDelete}>
            <i className="fas fa-times mr-2" /> Cancel
          </Button>
          <Button className="m-2 btn-danger float-right w-30" onClick={handleDeletePolygon}>
            <i className="fas fa-trash-alt" /> Delete
          </Button>
        </div>
      )}
      <hr />
    </div>
  );
}

import PageContainer from "./PageContainer";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";

export default function Loader() {
  return (
    <PageContainer>
      <Container fluid>
        <Card>
          <CardBody>
            <Row md="12">
              <Col md={{ size: 5 }} />
              <Col md={{ size: 2 }}>
                <ThreeDots height="80" width="80" radius="9" color="#4fa94d" ariaLabel="three-dots-loading" visible />
              </Col>
              <Col md={{ size: 5 }} />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </PageContainer>
  );
}

/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */

import Joi from "joi-browser";
import React, { Component } from "react";
import { Button } from "reactstrap";
import ChoiceControl from "./controls/ChoiceControl/ChoiceControl";
import InputControl from "./controls/Input";
import Select from "./controls/Select";
import ToggleSwitch from "./controls/ToggleSwitch/ToggleSwitch";

class BaseForm extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = {
      abortEarly: false,
      stripUnknown: true,
    };
    const { data } = this.state;
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    error.details.forEach((item) => {
      errors[item.path[0]] = item.message;
    });
    return errors;
  };

  validateProperty = ({ name, value, type, checked }) => {
    const obj = { [name]: type === "checkbox" ? checked : value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validateResponse = (response) => {
    const { errors } = this.state;
    Object.keys(response).forEach(function (key) {
      errors[key] = response[key][0];
    });
    this.setState({ errors });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) {
      console.log(errors);
      return;
    }
    this.doSubmit();
  };

  handleToggle = ({ currentTarget: input }) => {
    const { errors, data } = this.state;
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    data[input.name] = !data[input.name];
    this.setState({ data, errors });
  };

  handleChange = ({ currentTarget: input }) => {
    const { errors, data } = this.state;
    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else delete errors[input.name];
    data[input.name] = input.value;
    const status = "edited";
    this.setState({ data, errors, status });
  };

  handleChoice = ({ currentTarget: input }) => {
    const { data } = this.state;
    const key = input.name.split("-")[0];
    data[key] = input.value;
    const status = "edited";
    this.setState({ data, status });
  };

  renderButton(label) {
    return <Button>{label}</Button>;
  }

  renderInput(name, label, type, extraArgs) {
    const { data, errors } = this.state;
    return (
      <InputControl
        name={name}
        type={type}
        value={data[name] || ""}
        onChange={this.handleChange}
        label={label}
        error={errors[name]}
        {...extraArgs}
      />
    );
  }

  renderToggleSwitch(name, label, inverted, disabled, datagroup, parentgroup, childgroup, id = name) {
    /*
      name : id (no_suspicious)
      label : display text (Suspicious)
      inverted : if "Suspicious" is toggled, no_suspicious will be "False"
      id : name
      datagroup : to link toggles together
        toggle one, the others of the group get toggled
      parentgroup : to link toggles together
        change parent => all toggles with this parent as childgroup change
      childgroup : to link toggles together
        1+ child true => set parent with this childgroup as parentgroup true
        all toggles with this childgroup false => set parent false
    */
    const { data, errors } = this.state;
    return (
      <ToggleSwitch
        name={name}
        id={id}
        checked={inverted ? !data[name] : data[name]}
        onChange={this.handleToggle}
        label={label}
        inverted={inverted}
        disabled={disabled}
        datagroup={datagroup}
        parentgroup={parentgroup}
        childgroup={childgroup}
        error={errors[name]}
      />
    );
  }

  renderSelect(name, label, options, rest) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        options={options}
        onChange={this.handleChange}
        label={label}
        error={errors[name]}
        {...rest}
      />
    );
  }

  renderChoiceControl(name, options) {
    const { data, errors } = this.state;
    return (
      <ChoiceControl
        name={name}
        options={options}
        onChange={this.handleChoice}
        error={errors[name]}
        selected={data[name]}
      />
    );
  }
}

export default BaseForm;

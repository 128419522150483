import React, { Component } from "react";
import { toast } from "react-toastify";

import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import ScanVideoRecorderForm from "../Forms/ScanVideoRecorderForm";
import { getServerIp } from "../../services/locationService";
import Gallery from "../gallery/Gallery";
import "./VideoRecorder.scss";

class ScanVideoRecorder extends Component {
  state = {
    screenshots: [],
    screenshotsPolygons: [],
    formId: 0,
    isGalleryShown: false,
    serverIp: null,
  };

  async componentDidMount() {
    const { server } = this.props;
    if (server) {
      await this.fetchServerIp();
    } else {
      this.setState({ serverIp: null });
    }
  }

  handleFormSubmit = (channels, screenshots, screenshotsPolygons) => {
    const { formId } = this.state;
    this.setState({ screenshots, screenshotsPolygons, channels, formId: formId + 1 });
    this.showGallery(true);
  };

  showGallery = (isShown) => {
    this.setState({ isGalleryShown: isShown });
  };

  async fetchServerIp() {
    const { server } = this.props;
    try {
      const { data: serverIp } = await getServerIp(server);
      if (serverIp.server_ip == null) {
        toast.error(`Unable to fetch IP for server ${server}`);
      }
      this.setState({ serverIp: serverIp.server_ip });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Unable to fetch server IP");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      }
    }
  }

  render() {
    const { videoRecorder, server, index, location } = this.props;
    const { isGalleryShown, serverIp, screenshots, screenshotsPolygons, formId, channels } = this.state;
    return (
      <Card className="video-recorder">
        <CardHeader>
          <CardTitle tag="h4">
            {`DVR ${index}: `}
            {serverIp && `scanning from server ${server}`}
            {!serverIp && "unable to scan, no server assigned"}
          </CardTitle>
        </CardHeader>
        {serverIp && (
          <CardBody>
            {videoRecorder && (
              <ScanVideoRecorderForm
                videoRecorder={videoRecorder}
                serverIp={serverIp}
                handleFormSubmit={this.handleFormSubmit}
                videoRecorderIndex={index}
                location={location}
                onShowGallery={this.showGallery}
              />
            )}
            {isGalleryShown && (
              <Gallery
                key={formId}
                screenshots={screenshots}
                screenshotsPolygons={screenshotsPolygons}
                formId={formId}
                serverIp={serverIp}
                channels={channels}
                videoRecorder={videoRecorder.id}
              />
            )}
          </CardBody>
        )}
      </Card>
    );
  }
}

export default ScanVideoRecorder;

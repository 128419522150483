import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "./ImageModal.scss";

function ImageModal({ showModal, dataUri, toggle }) {
  return (
    <Modal isOpen={showModal} toggle={toggle} contentClassName="image-modal bg-transparent">
      <ModalBody>
        <center>
          <img src={dataUri} alt="" onClick={toggle} />
        </center>
      </ModalBody>
    </Modal>
  );
}

export default ImageModal;

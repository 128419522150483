import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import OpeningHourForm from "../Forms/OpeningHourForm";
import "./OpeningHour.scss";

class OpeningHour extends Component {
  state = { editMode: false, deleteMode: false };
  toggleEditMode = () => {
    const { editMode } = this.state;
    this.setState({ editMode: !editMode });
  };

  toggleDeleteMode = () => {
    const { deleteMode } = this.state;
    this.setState({ deleteMode: !deleteMode });
  };

  render() {
    const { openingHour, storeId, updateOpeningHoursList, removeFromOpeningHoursList } = this.props;
    const { editMode, deleteMode } = this.state;
    let className = "opening-hour";
    if (editMode) className = `${className} success`;
    return (
      <React.Fragment>
        {!openingHour && (
          <OpeningHourForm
            openingHour={openingHour}
            deleteMode={deleteMode}
            storeId={storeId}
            updateOpeningHoursList={updateOpeningHoursList}
            removeFromOpeningHoursList={removeFromOpeningHoursList}
            toggleDeleteMode={this.toggleDeleteMode}
          />
        )}
        {openingHour && !editMode && (
          <Row className={className}>
            <Col md={10}>
              <span>
                {openingHour && openingHour.day}
                {!openingHour.is_open && (
                  <p>
                    The store is <b>closed</b> on {openingHour.day}
                  </p>
                )}
                {openingHour && openingHour.is_open && <p>Opening Hour : {openingHour.time_open}</p>}
                {openingHour && openingHour.is_open && <p>Closing Hour : {openingHour.time_close}</p>}
              </span>
            </Col>
            <Col md={2}>
              <Button onClick={this.toggleEditMode}>
                <i className="fas fa-edit mr-2" />
                Edit
              </Button>
            </Col>
          </Row>
        )}
        {openingHour && editMode && (
          <OpeningHourForm
            openingHour={openingHour}
            deleteMode={deleteMode}
            updateOpeningHoursList={updateOpeningHoursList}
            removeFromOpeningHoursList={removeFromOpeningHoursList}
            toggleDeleteMode={this.toggleDeleteMode}
          />
        )}
        <hr
          style={{
            borderColor: "grey",
            height: 0.5,
          }}
        />
      </React.Fragment>
    );
  }
}

export default OpeningHour;

import Field from "./Field";

/**
 * Utility class using Builder pattern to help you construct valid Field object.
 *
 * @returns {{
 *   setName: (function(*): *),
 *   setVisible: (function(*): *),
 *   build: (function(): Field),
 *   setParentTagClassName: (function(*): *),
 *   setId: (function(*): *),
 *   setComponent: (function(*): *)
 * }}
 * @constructor
 */
export default function FieldBuilder() {
  this.id = undefined;
  this.name = undefined;
  this.component = undefined;
  this.parentTagClassName = undefined;
  this.isVisible = true;

  return {
    setId(id) {
      this.id = id;
      return this;
    },
    setName(name) {
      this.name = name;
      return this;
    },
    setComponent(component) {
      this.component = component;
      return this;
    },
    setParentTagClassName(className) {
      this.parentTagClassName = className;
      return this;
    },
    setVisible(isVisible) {
      this.isVisible = isVisible;
      return this;
    },
    build() {
      if (!this.id) {
        throw new Error("Missing id");
      }

      if (!this.name) {
        throw new Error("Missing name");
      }

      if (this.isVisible === undefined) {
        this.isVisible = true;
      }

      if (this.isVisible === true && !this.component) {
        this.isVisible = false;
      }

      return new Field(this.id, this.name, this.component, this.parentTagClassName, this.isVisible);
    },
  };
}

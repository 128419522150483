import Model from "./Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class SubChannelModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number());
    this.channel = this.createField(null, groups.PRIMARY, "Channel", Joi.number());
    this.sub_channel_number = this.createField(1, groups.PRIMARY, "Sub channel number", Joi.number().required());
    this.channel_name = this.createField("", groups.PRIMARY, "Sub channel name", Joi.string().allow(""));
    this.favor_factor = this.createField("", groups.PRIMARY, "Favor factor", Joi.number().allow(""));
    this.recheckings = this.createField("", groups.PRIMARY, "Recheckings", Joi.number().allow(""));
    this.anchor = this.createField("", groups.PRIMARY, "Anchor", Joi.string().allow(""));
    this.is_active = this.createField(false, groups.PRIMARY, "Is active", Joi.boolean());
  }
}

export default SubChannelModel;

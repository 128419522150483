import React from "react";
import DisplayWhen from "./DisplayWhen";
import Forbidden from "../errors/Forbidden";
import userHasRole from "../../shared/utils/authUtils";

/**
 * A React component that renders its children only if the current user has one of the given roles.
 *
 * @param {boolean} props.roles - The roles that determines whether the children should be rendered or not.
 * @param {React.ReactNode} props.children - The child elements that will be rendered if the condition is true.
 * @returns {React.ReactElement | ReactFragment} - The rendered React element or empty ReactFragment
 *  if the user does not have at least one role.
 */
function RoleBasedAccess({ roles, children }) {
  const isAuthorized = roles.filter((role) => userHasRole(role)).length > 0;

  if (isAuthorized) {
    return <DisplayWhen condition={isAuthorized}>{children}</DisplayWhen>;
  }

  return <Forbidden />;
}

export default React.memo(RoleBasedAccess);

import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardTitle, Button } from "reactstrap";
import { triggerScreenshot, fetchScreenshot } from "../../services/scanVideoRecorderService";
import { ReactComponent as Load } from "./loader.svg";
import { ReactComponent as Fail } from "./failed.svg";
import { toast } from "react-toastify";

class Screenshot extends Component {
  state = {
    screenshotState: "",
    dataUri: "",
    dataUriPolygons: "",
    storeLocation: "",
    suppliedImage: false,
    showPolygons: false,
    checked: false,
    imageDimensions: { width: 0, height: 0 },
  };

  componentDidMount() {
    this.setSreenshotState("load");
    const { channel, formData, serverIp, dataUri, storeLocation } = this.props;
    if (!dataUri) {
      this.setState({ storeLocation, suppliedImage: false }, () => {
        this.generateScreenshot(serverIp, channel, formData);
      });
    } else {
      this.setState(
        {
          dataUri,
          screenshotState: "success",
          storeLocation,
          suppliedImage: true,
        },
        () => {
          this.updateImageDimensions(dataUri);
        }
      );
    }
  }

  async componentDidUpdate(prevProps) {
    const { channel, dataUri, formId, allChecked, updateSelectedCount } = this.props;
    const { checked } = this.state;

    if (prevProps.dataUri !== dataUri) {
      this.setState({ dataUri }, () => {
        this.updateImageDimensions(dataUri);
      });
    }
    if (prevProps.formId !== formId) {
      this.setSreenshotState("load");
      const { formData, serverIp } = this.props;
      if (!prevProps.dataUri) {
        this.setState({ dataUriPolygons: "" });
        await this.generateScreenshot(serverIp, channel, formData);
      } else {
        this.setState({ dataUri, screenshotState: "success" }, () => {
          this.updateImageDimensions(dataUri);
        });
      }
    }
    if (prevProps.allChecked !== allChecked && checked !== allChecked) {
      const { screenshot } = this.props;
      this.setState({ checked: allChecked });
      screenshot[2] = allChecked;
      updateSelectedCount(allChecked);
    }
  }

  handleCheckboxChange = () => {
    const { updateSelectedCount, screenshot } = this.props;
    const { checked } = this.state;

    this.setState({ checked: !checked });
    updateSelectedCount(!checked);

    screenshot[2] = !checked;
  };

  setSreenshotState(state) {
    this.setState({ screenshotState: state });
  }

  togglePolygons = () => {
    const { showPolygons } = this.state;
    this.setState({ showPolygons: !showPolygons });
  };

  async generateScreenshot(serverIp, channel, data) {
    const { storeLocation } = this.state;
    try {
      await triggerScreenshot(serverIp, channel, data)
        .then((response) => {
          const statePoint = response.data.state_point;
          return fetchScreenshot(serverIp, statePoint, data.video_recorder_id, channel, storeLocation);
        })
        .then((response) => {
          if (response.status === "success") {
            this.setState(
              {
                dataUri: response.data_uri,
                dataUriPolygons: response.data_uri_polygons || "",
              },
              () => {
                this.updateImageDimensions(response.data_uri);
              }
            );
            this.setSreenshotState("success");
          } else {
            this.setSreenshotState("fail");
          }
        });
    } catch (ex) {
      console.log(ex.response);
      if (ex.response && ex.response.status === 500) {
        if (ex.response.data) {
          toast.error(`Channel ${channel} : Error 500 ${ex.response.data}`);
        } else {
          toast.error(`Channel ${channel}: Error 500`);
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(`Channel ${channel}: Error 401 ${ex.response.data}`);
      } else {
        toast.error(`Channel ${channel}: Unknown error`);
      }
      this.setSreenshotState("fail");
    }
  }

  updateImageDimensions(dataUri) {
    const img = new Image();
    img.onload = () => {
      this.setState({ imageDimensions: { width: img.width, height: img.height } });
    };
    img.src = dataUri;
  }

  render() {
    const { screenshotState, showPolygons, suppliedImage, dataUri, dataUriPolygons, checked, imageDimensions } =
      this.state;
    const { channel, onClick, screenshot, checkbox } = this.props;
    let { hasPolygons } = this.props;
    let dataUriSrc = dataUri;

    if (!suppliedImage && dataUriPolygons) {
      hasPolygons = true;
      if (showPolygons) {
        dataUriSrc = dataUriPolygons;
      }
    }

    const channelInfo = (
      <>
        {` Channel ${channel}`}
        {hasPolygons && <i className="fas fa-draw-polygon text-primary ml-2" />}
      </>
    );

    const renderCardTitle = () => {
      if (checkbox && screenshot !== undefined) {
        return (
          <CardTitle tag="h5">
            <input
              type="checkbox"
              checked={checked}
              onChange={this.handleCheckboxChange}
              ref={(input) => {
                this.checkbox = input;
              }}
            />
            <span
              onClick={() => {
                this.checkbox.click();
              }}
              style={{ cursor: "pointer" }}
            >
              {channelInfo}
            </span>
          </CardTitle>
        );
      }
      return <CardTitle tag="h5">{channelInfo}</CardTitle>;
    };

    return (
      <div>
        <div>
          <center>{renderCardTitle()}</center>
        </div>
        <Card>
          <CardHeader />
          <CardBody>
            <center>
              {screenshotState === "success" && (
                <>
                  <img src={dataUriSrc} alt={channel} onClick={() => onClick(dataUriSrc)} width="200px" />
                  <p>
                    {imageDimensions.width} x {imageDimensions.height}
                  </p>
                </>
              )}
              {screenshotState === "fail" && <Fail />}
              {screenshotState === "load" && <Load />}
            </center>
            {!suppliedImage && hasPolygons && (
              <Button className="ml-3" onClick={this.togglePolygons}>
                <i className="fas fa-draw-polygon text-primary mr-2" />
                {showPolygons ? "Hide polygons" : "Show polygons"}
              </Button>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Screenshot;

import React, { Component } from "react";
import { Table, Button } from "reactstrap";
class StoresTable extends Component {
  state = {};
  render() {
    const { stores, highLight } = this.props;

    return (
      <Table className="tablesorter" responsive>
        <thead className="text-primary">
          <tr>
            <th />
            <th>Name</th>
            <th>Location</th>
            <th>Host Location</th>
            <th className="text-center" />
          </tr>
        </thead>
        <tbody>
          {stores &&
            stores.map((store, index) => (
              <tr key={store.id} style={index === 0 && highLight ? { backgroundColor: "#5985f3" } : {}}>
                <td className="text-center">
                  <a href={`/stores/${store.id}`}>
                    <Button>Edit</Button>
                  </a>
                </td>
                <td>{store.name}</td>
                <td>{store.location}</td>
                <td>{store.local_inference ? "Local" : "Cloud"}</td>
                <td className="text-center">
                  <a href={`/screenshots/${store.location}`}>
                    <Button>Screenshots</Button>
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

export default StoresTable;

import React from "react";
import useRedirect from "../shared/hooks/api/redirect";

function RedirectView({ url }) {
  const redirectUrl = useRedirect(url);

  return (
    <div className="container col-lg-4 col-md-6 col-sm-6 col-xs-12">
      <div className="my-5">
        <div className="row">
          <h2>Redirecting... {redirectUrl}</h2>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RedirectView);

import Model from "./Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };
class WatcherModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number());
    this.watcher_version = this.createField(null, groups.PRIMARY, "Watcher version", Joi.string().required().max(18));
  }
}

export default WatcherModel;

import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap";

class StatusResult extends Component {
  state = {
    stdOut: "",
    stdErr: "",
  };

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      stdOut: nextProps.stdOut,
      stdErr: nextProps.stdErr,
      status: nextProps.status,
    };
  }

  render() {
    const { stdOut, stdErr, status } = this.state;
    const stdErrSplit = stdErr.split("\n");
    return (
      <Row>
        <Col md="6">
          {stdOut !== "" && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  Standard output
                </CardTitle>
              </CardHeader>
              <CardBody>
                {Object.entries(stdOut).map(([key, value], index) => (
                  <CardText key={`stdOut_${index}`}>
                    <strong>{key}:</strong> {value.toString()}
                  </CardText>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
        <Col md="6">
          {stdErr !== "" && status === "FAILED" && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  Error output
                </CardTitle>
              </CardHeader>
              <CardBody>
                {stdErrSplit.map((element, index) => (
                  <CardText key={`stdErr_${index}`}>{element}</CardText>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    );
  }
}

export default StatusResult;

import * as Sentry from "@sentry/react";
import { Button, Col, Container, Row } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import React from "react";
import { getUserHistoryEndpoint } from "../../../services/history/userHistoryService";
import useFetch from "../../../components/shared/hooks/api/fetch";
import PageContainer from "../../../components/common/PageContainer";
import ReactDiffViewer from "react-diff-viewer-continued";
import formatDate from "../utils";
import Loader from "../../../components/common/Loader";

/**
 * User diff viewer.
 *
 * @returns {JSX.Element}
 * @constructor
 */
function UserDiffViewer() {
  const history = useHistory();
  const { username, page, createdOn } = useParams();
  const [storeChanges, requestState] = useFetch(getUserHistoryEndpoint(username));

  const navigateBack = () => {
    history.go(-1);
  };

  const cleanHistory = (historyVersion) => {
    if (Object.prototype.hasOwnProperty.call(historyVersion, "revisionDetails")) {
      delete historyVersion.revisionDetails;
    }

    return historyVersion;
  };

  if (requestState.isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <Container fluid>
        <Row>
          <Col md={{ size: 2 }}>
            <Button onClick={navigateBack}>Go back</Button>
          </Col>
          <Col md={{ offset: 7, size: 3 }}>
            {username && <p className="font-bold text-warning">{username}</p>}
            <p className="font-bold text-warning">{formatDate(createdOn)}</p>
          </Col>
        </Row>
        <Row>
          {storeChanges && storeChanges[page] && (
            <ReactDiffViewer
              leftTitle="Pre user change"
              oldValue={JSON.stringify(cleanHistory(storeChanges[page].preUserChange), null, 2)}
              rightTitle="User change"
              newValue={JSON.stringify(cleanHistory(storeChanges[page].userChange), null, 2)}
              splitView
            />
          )}
        </Row>
      </Container>
    </PageContainer>
  );
}

export default Sentry.withProfiler(UserDiffViewer);

import React from "react";
import { Row, Col, Badge } from "reactstrap";
import "./SoftwareVersion.scss";

export default function SoftwareVersion({ software, icon, version }) {
  const serviceIcon = icon || "fas fa-circle";

  return (
    <div className="machine-service">
      <Row>
        <Col md={4}>
          <span>
            <i className={`${serviceIcon} mr-2`} />
            {software}
          </span>
        </Col>
        <Col md={4}>
          <Badge>{version}</Badge>
        </Col>
        <Col md={4}>
          <span className="text-success">
            <i className="fas fa-check-circle" />
          </span>
        </Col>
      </Row>
    </div>
  );
}

import http from "./httpService";

const apiEndPoint = "/locations/";

function getLocationRestartUrl(location, container) {
  return `${apiEndPoint}restart/${location}/${container}`;
}

function getServersContainersStatusUrl(location) {
  return `${apiEndPoint}containers/${location}`;
}
function getServersContainerStatusUrl(location, container) {
  return `${apiEndPoint}containers/${location}/${container}`;
}
function getServerContainersStatusUrl(serverName) {
  return `${apiEndPoint}containers_by_server/${serverName}`;
}
function getServerContainersRestartUrl(serverName, container) {
  return `${apiEndPoint}restart_by_server/${serverName}/${container}`;
}
function getServerIpUrl(serverName) {
  return `${apiEndPoint}get_vpn_ip/${serverName}`;
}
export function getChannelScreenshotFullUrl(serverName, channel, protocol, deviceIp) {
  return `${process.env.REACT_APP_API_URL}${apiEndPoint}channels/screenshot/${serverName}?channel=${channel}&protocol=${protocol}&device_ip=${deviceIp}`;
}

export function restartLocationContainer(location, container) {
  // Restart the docker container on all servers linked to the location
  return http.post(getLocationRestartUrl(location, container));
}

export function returnServersContainersStatus(location) {
  // Return the docker containers information from all servers that are linked to the location
  return http.get(getServersContainersStatusUrl(location));
}

export function restartServerContainer(serverName, container) {
  // Restart the docker container on the server
  return http.post(getServerContainersRestartUrl(serverName, container));
}

export function returnServersContainerStatus(location, container) {
  // Return the docker containers information from the server
  return http.get(getServersContainerStatusUrl(location, container));
}

export function returnServerContainersStatus(serverName, container) {
  // Return the docker containers information from the server
  return http.get(getServerContainersStatusUrl(serverName, container));
}

export function getServerIp(serverName) {
  return http.get(getServerIpUrl(serverName));
}

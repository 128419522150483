import http from "./httpService";
import stringToHash from "../utils/stringUtils";
import { toast } from "react-toastify";

const apiEndPoint = "/locations/channels/async/bulk_screenshots/";
const MAX_RETRIES = 4;
const EXPIRATION = 60 * 60;

export function random(min, max) {
  const rand = Math.random() * (max - min) + min;
  return rand;
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getUrl(serverIp) {
  return `${apiEndPoint}${serverIp}`;
}

function videoRecorderToHash(videoRecorder) {
  const hash = stringToHash(
    videoRecorder.type.name +
      videoRecorder.username +
      videoRecorder.password +
      videoRecorder.device_ip +
      videoRecorder.device_rtsp_port.toString() +
      videoRecorder.rtsp_url_suffix +
      videoRecorder.channels[0].protocol +
      videoRecorder.sub_type
  )
    .toString()
    .slice(0, 5);
  return hash;
}

export function trigggerBulkScreenshots(
  serverIp,
  storeLocation,
  screenshotsDestination,
  videoRecorders,
  videoRecorderId = 0,
  rangesStr = "1-32"
) {
  const filteredVideoRecorders = videoRecorders.filter((videoRecorder) => videoRecorder.channels.length > 0);
  if (filteredVideoRecorders.length === 0) {
    return {
      data: {
        status: "failed",
        message: "At least one video recorder should have channels assigned",
      },
    };
  }
  const hash = videoRecorderToHash(videoRecorders[0]);
  const ranges = videoRecorderId === 0 ? "" : `_${rangesStr.replaceAll(",", "+")}`;
  const body = {
    store_location: `${storeLocation}_${videoRecorderId}${ranges}_${hash}`,
    expiration: EXPIRATION,
    video_recorders: filteredVideoRecorders,
    screenshots_destination: screenshotsDestination,
  };

  return http.post(getUrl(serverIp), body);
}

export const fetchBulkScreenshots = async function (
  serverIp,
  storeLocation,
  screenshotsDestination,
  videoRecorders,
  videoRecorderId = 0,
  rangesStr = "1-32"
) {
  let retryDuration = 1000;
  const filteredVideoRecorders = videoRecorders.filter((videoRecorder) => videoRecorder.channels.length > 0);
  if (filteredVideoRecorders.length === 0) {
    return {
      data: {
        status: "failed",
        message: "At least one video recorder should have channels assigned",
      },
    };
  }
  const hash = videoRecorderToHash(videoRecorders[0]);
  const ranges = videoRecorderId === 0 ? "" : `_${rangesStr.replaceAll(",", "+")}`;
  const params = {
    store_location: `${storeLocation}_${videoRecorderId}${ranges}_${hash}`,
    expiration: EXPIRATION,
    screenshots_destination: screenshotsDestination,
  };
  let response = await http.get(getUrl(serverIp), { params });
  let retries = 0;

  while (response.status === 200 && response.data.status === "running" && retries <= MAX_RETRIES) {
    toast.warning(`Screenshots not ready, retrying in ${retryDuration / 1000} second(s)`);
    await timeout(retryDuration);
    retryDuration = retryDuration * 2;
    response = await http.get(getUrl(serverIp), { params });
    retries += 1;
  }

  if (retries > MAX_RETRIES) {
    return {
      data: {
        status: "failed",
        message: "Could not generate screenshots, check if the server can access all DVR or camera IPs",
      },
    };
  }

  if (response.status !== 200) {
    return {
      data: {
        status: "failed",
        zip_file_url: "",
        message: response.message,
      },
    };
  }

  return response;
};

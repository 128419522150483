import http from "./httpService";

const apiEndPoint = "/gchatnotifiers/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function getGchatNotifiers() {
  return http.get(apiEndPoint);
}

export function getGchatNotifier(gchatId) {
  return http.get(getUrl(gchatId));
}

export function updateGchatNotifier(gchat) {
  return http.put(getUrl(gchat.id), gchat);
}

export function addGchatNotifier(gchat) {
  return http.post(apiEndPoint, gchat);
}

export function deleteGchatNotifier(gchatId) {
  return http.delete(getUrl(gchatId));
}

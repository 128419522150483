import InferenceMachinesView from "./views/InferenceMachinesView";
import RemoteControlView from "./views/RemoteControlView";
import NetworkView from "./views/NetworkView";
import StoresView from "./views/StoresView";
import StoreDetailView from "./views/StoreDetailView";
import StoreScreenshotToolsView from "./views/StoreScreenshotToolsView";
import InferenceMachineDetailView from "./views/InferenceMachineDetailView";
import GlobalStreamConfigView from "./views/GlobalStreamConfigView";
import LoginView from "./views/LoginView";
import LogoutView from "./views/LogoutView";
import {
  adminEditorRole,
  adminViewerRole,
  csmMaintenanceRole,
  csmRole,
  qualityRole,
  technicianRole,
  techopsRole,
  viewerRole,
} from "./components/shared/constants";
import UpdateFavorFactorPage from "./views/favor-factor/UpdateFavorFactorPage";
import StoreHistory from "./views/stores-history/stores/StoreHistory";
import StoreDiffViewer from "./views/stores-history/stores/StoreDiffViewer";
import UserHistory from "./views/stores-history/users/UserHistory";
import UserDiffViewer from "./views/stores-history/users/UserDiffViewer";
import HistoryView from "./views/stores-history/HistoryView";

const routes = [
  {
    path: "stores",
    name: "Stores",
    icon: "",
    component: StoresView,
    layout: "/",
    roles: [viewerRole],
    subRoutes: [
      {
        path: ":id",
        name: "Store Details",
        icon: "",
        component: StoreDetailView,
        layout: "stores/",
        roles: [viewerRole],
      },
    ],
  },
  {
    path: "inferencemachines",
    name: "Inference Machines",
    icon: "",
    component: InferenceMachinesView,
    layout: "/",
    roles: [viewerRole],
    subRoutes: [
      {
        path: ":id",
        name: "Inference Machine",
        icon: "",
        component: InferenceMachineDetailView,
        layout: "inferencemachines/",
        roles: [viewerRole],
      },
    ],
  },
  {
    path: "update-favor-factor",
    name: "Update Favor Factor",
    icon: "",
    component: UpdateFavorFactorPage,
    layout: "/",
    roles: [qualityRole, adminViewerRole, adminEditorRole],
  },
  {
    path: "remotecontrol",
    name: "Remote Control",
    icon: "",
    component: RemoteControlView,
    layout: "/",
  },
  {
    path: "streamconfig",
    name: "Stream Config",
    icon: "",
    component: GlobalStreamConfigView,
    layout: "/",
    roles: [adminViewerRole, adminEditorRole],
  },
  {
    path: "history/",
    name: "History",
    icon: "",
    component: HistoryView,
    layout: "/",
    roles: [adminViewerRole, adminEditorRole, techopsRole, technicianRole, csmRole, csmMaintenanceRole],
    subRoutes: [
      {
        path: ":storeLocation",
        name: "Store History",
        icon: "",
        component: StoreHistory,
        layout: "history/stores/",
        roles: [adminViewerRole, adminEditorRole, techopsRole, technicianRole, csmRole, csmMaintenanceRole],
      },
      {
        path: ":storeLocation/:newRevisionIndex/:oldRevisionIndex",
        name: "Store Change Details",
        icon: "",
        component: StoreDiffViewer,
        layout: "history/diff/",
        roles: [adminViewerRole, adminEditorRole, techopsRole, technicianRole, csmRole, csmMaintenanceRole],
      },
      {
        path: ":username",
        name: "User history",
        icon: "",
        component: UserHistory,
        layout: "history/edits/",
        roles: [adminViewerRole, adminEditorRole, techopsRole],
      },
      {
        path: ":username/:page/:createdOn/",
        name: "User Change Details",
        icon: "",
        component: UserDiffViewer,
        layout: "history/u/",
        roles: [adminViewerRole, adminEditorRole, techopsRole],
      },
    ],
  },
  {
    path: "network",
    name: "Network",
    icon: "",
    component: NetworkView,
    layout: "/",
  },
  {
    path: "login",
    name: "Login",
    icon: null,
    component: LoginView,
    layout: "/connect/",
  },
  {
    path: "logout",
    name: "Logout",
    icon: null,
    component: LogoutView,
    layout: "/connect/",
  },
  {
    path: "screenshots",
    name: "Screenshots",
    icon: "",
    layout: "/",
    subRoutes: [
      {
        path: ":location",
        name: "Store Screenshot tools",
        icon: "",
        component: StoreScreenshotToolsView,
        layout: "screenshots/",
      },
    ],
  },
];

export default routes;

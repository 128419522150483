import http from "./httpService";

const apiEndPoint = "/vtips/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function getVtIps() {
  return http.get(apiEndPoint);
}

export function getVtIp(vtIpId) {
  return http.get(getUrl(vtIpId));
}

export function saveVtIp(vtIp) {
  const body = { ...vtIp };
  Object.keys(body).forEach(function (key) {
    if (body[key] === "") delete body[key];
  });
  if (body.id) {
    delete body.id;
    return http.patch(getUrl(vtIp.id), body);
  }
  return http.post(apiEndPoint, body);
}

export function deleteVtIp(vtIpId) {
  return http.delete(getUrl(vtIpId));
}

import Model from "../Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class ScreenshotModel extends Model {
  constructor() {
    super();
    this.video_recorder_type_id = this.createField(1, groups.PRIMARY, "Device type", Joi.number().required());
    this.username = this.createField("", groups.PRIMARY, "Username", Joi.string().alphanum().required());
    this.password = this.createField("", groups.PRIMARY, "Password", Joi.string().regex(/^\S+$/).required());
    this.device_ip = this.createField(
      "",
      groups.PRIMARY,
      "Device IP",
      Joi.string().ip({ cidr: "forbidden" }).required()
    );
    this.device_rtsp_port = this.createField(554, groups.PRIMARY, "RTSP port", Joi.number().required());
    this.suffix = this.createField("", groups.PRIMARY, "RTSP URL suffix", Joi.string().regex(/^\S+$/).allow(""));
    this.protocol = this.createField("tcp", groups.PRIMARY, "Protocol", Joi.string().required());
    this.channel = this.createField("", groups.PRIMARY, "Channel", Joi.number().required());
    this.subtype = this.createField("sub", groups.PRIMARY, "Subtype", Joi.string().required());
  }
}

export default ScreenshotModel;

import React from "react";
import { toast } from "react-toastify";

import ScanVideoRecorder from "./videorecorders/ScanVideoRecorder";
import getVideoRecordersList from "../services/videoRecorderListService";

class StoreScanVideoRecorder extends React.Component {
  state = {
    videoRecorders: null,
  };

  componentDidMount() {
    const { videoRecorders } = this.props;
    this.setState({ videoRecorders });
  }

  async componentDidUpdate(prevProps) {
    const prevStoreLocation = prevProps.location;
    const { location } = this.props;
    if (prevStoreLocation !== location) {
      await this.fetchVideoRecorders(location);
    }
  }

  async fetchVideoRecorders(storeLocation) {
    try {
      const { data: videoRecorders } = await getVideoRecordersList(storeLocation);
      this.setState({ videoRecorders });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Unable to fetch video recorders list");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      } else {
        toast.error("Unhandled error");
      }
    }
  }

  render() {
    const { videoRecorders } = this.state;
    const { location } = this.props;
    return (
      <div className="content">
        {videoRecorders &&
          videoRecorders.map((videoRecorder, index) => (
            <ScanVideoRecorder
              key={videoRecorder.id}
              videoRecorder={videoRecorder}
              index={index + 1}
              server={
                videoRecorder.channels[0]
                  ? videoRecorder.channels[0].inference_machine_data
                    ? videoRecorder.channels[0].inference_machine_data.hostname
                    : null
                  : null
              }
              location={location}
            />
          ))}
      </div>
    );
  }
}

export default StoreScanVideoRecorder;

import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap";

class ScreenshotResult extends Component {
  state = {
    stdOut: "",
    stdErr: "",
    status: "",
    imageDimensions: { width: 0, height: 0 },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {
      stdOut: nextProps.stdOut,
      stdErr: nextProps.stdErr,
      status: nextProps.status,
    };

    if (nextProps.stdOut !== prevState.stdOut && nextProps.stdOut) {
      newState.imageDimensions = ScreenshotResult.getImageDimensions(nextProps.stdOut);
    }

    return newState;
  }

  static getImageDimensions(dataUri) {
    const img = new Image();
    img.src = dataUri;
    return {
      width: img.width,
      height: img.height,
    };
  }

  componentDidMount() {
    const { stdOut } = this.props;
    if (stdOut) {
      this.updateImageDimensions(stdOut);
    }
  }

  updateImageDimensions(dataUri) {
    const img = new Image();
    img.onload = () => {
      this.setState({ imageDimensions: { width: img.width, height: img.height } });
    };
    img.src = dataUri;
  }

  render() {
    const { stdOut, stdErr, status, imageDimensions } = this.state;
    const { channel } = this.props;
    const stdErrSplit = stdErr ? stdErr.split("\n") : "";
    return (
      <Row>
        <Col md="6">
          {status === "OK" && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  Standard output
                </CardTitle>
              </CardHeader>
              <CardBody>
                {stdOut && (
                  <center>
                    <img src={stdOut} alt={channel} width="400px" />
                    <p>
                      {imageDimensions.width} x {imageDimensions.height}
                    </p>
                  </center>
                )}
              </CardBody>
            </Card>
          )}
        </Col>
        <Col md="6">
          {stdErr !== "" && status === "FAILED" && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  Error output
                </CardTitle>
              </CardHeader>
              <CardBody>
                {stdErrSplit.map((element, index) => (
                  <CardText key={`stdErr_${index}`}>{element}</CardText>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    );
  }
}

export default ScreenshotResult;

import React from "react";
import { useHistory } from "react-router-dom";

import { Table } from "reactstrap";

function MachinesTable({ machines, highLight }) {
  const history = useHistory();
  const openUrl = (machine) => {
    history.push(`/inferencemachines/${machine.id}`);
  };

  return (
    <Table className="tablesorter" responsive hover>
      <thead className="text-primary">
        <tr>
          <th>Hostname</th>
          <th>Group</th>
        </tr>
      </thead>

      <tbody>
        {machines &&
          machines.map((machine, index) => (
            <tr
              key={machine.id}
              style={index === 0 && highLight ? { backgroundColor: "#5985f3" } : {}}
              onClick={() => openUrl(machine)}
            >
              <td>{machine.machine.hostname}</td>
              <td>{machine.machine.group}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default MachinesTable;

import PageContainer from "./PageContainer";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export default function NoDataAvailable() {
  return (
    <PageContainer>
      <Container fluid>
        <Card>
          <CardBody>
            <Row md="12">
              <Col md={{ size: 5 }} />
              <Col md={{ size: 2 }}>
                <p className="font-bold text-white">No data available</p>
              </Col>
              <Col md={{ size: 5 }} />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </PageContainer>
  );
}

import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../common/BaseForm";
import getVideoRecorderTypes from "../../services/videoRecorderTypeService";
import VideoRecorder from "../models/VideoRecorderModel";
import { deleteVideoRecorder, saveVideoRecorder } from "../../services/videoRecorderService";
import StatusIndicator from "../common/StatusIndicator/StatusIndicator";
import ResponsiveRow from "./base/ResponsiveRow";
import FormDataContext from "../shared/contexts/FormDataContext";
import FieldBuilder from "../shared/utils/FieldBuilder";

class VideoRecorderForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    deviceTypes: [],
    cachingOptions: [],
    subTypes: [],
    status: "started",
  };
  videoRecorder = new VideoRecorder();
  schema = this.videoRecorder.getSchema();
  async fetchDeviceTypes() {
    const { data: deviceTypes } = await getVideoRecorderTypes();
    this.setState({ deviceTypes });
  }
  setCachingOptions() {
    const cachingOptions = [
      { key: 1, name: 1 },
      { key: 3, name: 3 },
    ];
    this.setState({ cachingOptions });
  }
  setSubTypeOptions() {
    const response = [
      { key: 0, name: "main" },
      { key: 1, name: "sub" },
    ];

    // Adding a request to a db to get subtype (for evolution purpose)
    const subTypes = [];
    response.forEach(function (obj) {
      subTypes.push(obj);
    });

    this.setState({ subTypes });
  }
  async componentDidMount() {
    await this.fetchDeviceTypes();
    this.setCachingOptions();
    this.setSubTypeOptions();
    const { videoRecorder } = this.props;
    let data;
    if (videoRecorder) {
      data = videoRecorder;
    } else {
      data = this.videoRecorder.getDefaultData();
      data.store = this.props.storeId;
    }
    this.setState({ data });
  }

  delete = async () => {
    let status = "deleting";
    this.setState({ status });
    try {
      const res = await deleteVideoRecorder(this.state.data.id);
      if (res.status === 204) {
        this.props.removeFromVideoRecorderList(this.state.data.id);
      }
    } catch (exception) {
      if (exception.response) {
        status = "Failed to delete!";
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  save = async () => {
    let status = "saving";
    this.setState({ status });
    try {
      const { data: videoRecorder, status: resCode } = await saveVideoRecorder(this.state.data);
      status = resCode < 300 ? "success" : "Could not save video recorder";
      if (status === "success") {
        this.props.updateVideoRecorderList(videoRecorder);
      }
      this.setState({ status });
    } catch (exception) {
      if (exception.response) {
        status = "Invalid data provided!";
        if (exception.response.data.non_field_errors) {
          status = exception.response.data.non_field_errors;
        }
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  render() {
    const { deleteMode } = this.props;
    const { data, status } = this.state;
    let statusType = null;
    let message = "";
    switch (status) {
      case "edited":
        statusType = null;
        message = null;
        break;
      case "started":
        statusType = null;
        message = null;
        break;
      case "saving":
        statusType = "progress";
        message = "saving...";
        break;
      case "deleting":
        statusType = "progress";
        message = "deleting...";
        break;
      case "success":
        statusType = "success";
        message = "saved!";
        break;
      default:
        statusType = "failed";
        message = status;
    }
    const statusIndicator = (
      <div className="float-right m-2">
        <StatusIndicator status={statusType} message={message} />
      </div>
    );
    return (
      <div>
        <Form>
          <FormDataContext.Provider value={data}>
            <ResponsiveRow
              fields={[
                new FieldBuilder()
                  .setId("type")
                  .setName("type")
                  .setComponent(this.renderSelect("type", "Device type", this.state.deviceTypes))
                  .build(),
                new FieldBuilder()
                  .setId("username")
                  .setName("username")
                  .setComponent(this.renderInput("username", "Username"))
                  .build(),
                new FieldBuilder()
                  .setId("password")
                  .setName("password")
                  .setComponent(
                    this.renderInput("password", "Password", "text", {
                      "data-lpignore": true, // Disable last pass autofill.
                    })
                  )
                  .build(),
              ]}
              bottomSeparator={false}
            />

            <ResponsiveRow
              md="4"
              fields={[
                new FieldBuilder()
                  .setId("device_ip")
                  .setName("device_ip")
                  .setComponent(this.renderInput("device_ip", "Device IP"))
                  .build(),
                new FieldBuilder()
                  .setId("device_rtsp_port")
                  .setName("device_rtsp_port")
                  .setComponent(this.renderInput("device_rtsp_port", "RTSP port"))
                  .build(),
                new FieldBuilder()
                  .setId("device_api_port")
                  .setName("device_api_port")
                  .setComponent(this.renderInput("device_api_port", "API port"))
                  .build(),
              ]}
            />

            <ResponsiveRow
              fields={[
                new FieldBuilder()
                  .setId("sub_type")
                  .setName("sub_type")
                  .setComponent(this.renderSelect("sub_type", "Sub Type", this.state.subTypes))
                  .build(),
                new FieldBuilder()
                  .setId("rtsp_url_suffix")
                  .setName("rtsp_url_suffix")
                  .setComponent(this.renderInput("rtsp_url_suffix", "RTSP URL suffix"))
                  .build(),
                new FieldBuilder()
                  .setId("caching")
                  .setName("caching")
                  .setComponent(this.renderSelect("caching", "Caching", this.state.cachingOptions))
                  .build(),
                new FieldBuilder()
                  .setId("set_date")
                  .setName("set_date")
                  .setComponent(this.renderToggleSwitch("set_date", "Set date"))
                  .build(),
              ]}
            />

            <Row>
              {!deleteMode && (
                <Col>
                  <Button className="float-right btn-success m-2" onClick={this.save}>
                    <i className="fas fa-save mr-2" />
                    Save
                  </Button>
                  {this.videoRecorder.id && (
                    <Button className="float-right btn-danger m-2" onClick={this.props.toggleDeleteMode}>
                      <i className="fas fa-trash-alt mr-2" /> Delete
                    </Button>
                  )}
                  {!this.videoRecorder.id && (
                    <Button className="float-right btn-danger m-2" onClick={this.props.toggleAddDevice}>
                      <i className="fas fa-times mr-2" /> Cancel
                    </Button>
                  )}
                  {status && statusIndicator}
                </Col>
              )}

              {deleteMode && (
                <Col className="delete-confirmation">
                  {this.videoRecorder.id && (
                    <Button className="float-right btn-danger m-2" onClick={this.delete}>
                      <i className="fas fa-trash-alt mr-2" /> Delete
                    </Button>
                  )}
                  <Button className="float-right m-2" onClick={this.props.toggleDeleteMode}>
                    <i className="fas fa-times mr-2" /> Cancel
                  </Button>
                  <span className="float-right">Are you sure?</span>
                  {status && statusIndicator}
                </Col>
              )}
            </Row>
            <hr />
          </FormDataContext.Provider>
        </Form>
      </div>
    );
  }
}

export default VideoRecorderForm;

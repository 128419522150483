import React from "react";
import { Button, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row } from "reactstrap";

import BaseForm from "../common/BaseForm";
import getCountries from "../../services/countryService";
import { updateStoreLanguageAndRegion } from "../../services/storeService";
import DropDown from "../common/controls/DropDown";
import { toast } from "react-toastify";
import getTimeZones from "../../services/timeZoneService";
import getCurrency from "../../services/currencyService";
import getLanguage from "../../services/languageService";

class LanguageRegionForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    isNewStore: false,
    storeEdited: {},
    currencies: [],
    languages: [],
    countries: [],
    timezones: [],
    currentCountry: undefined,
    currentCurrency: undefined,
    currentLanguage: undefined,
  };

  async fetchTimeZones() {
    const { data: timezones } = await getTimeZones();
    timezones.sort(function (timezoneA, timezoneB) {
      const zoneA = timezoneA.code;
      const zoneB = timezoneB.code;
      if (zoneA < zoneB) {
        return -1;
      }
      if (zoneA > zoneB) {
        return 1;
      }

      return 0;
    });

    return { timezones };
  }

  async fetchCurrency(id) {
    const { data: currency } = await getCurrency(id);
    return { currency };
  }

  async fetchLanguage(id) {
    const { data: language } = await getLanguage(id);
    return { language };
  }

  async fetchCountries(defaultCountry) {
    const { data: countries } = await getCountries();
    let currencies = [];
    let languages = [];
    let selectedCountry;

    if (defaultCountry !== undefined) {
      selectedCountry = countries.find((country) => country.id === defaultCountry);
    } else {
      selectedCountry = countries[0];
    }

    if (defaultCountry === undefined) {
      selectedCountry = countries[0];
    }

    if (selectedCountry) {
      if (selectedCountry.currencies.length > 0) {
        currencies = selectedCountry.currencies;
      }
      if (selectedCountry.languages.length > 0) {
        languages = selectedCountry.languages;
      }
    }

    return {
      countries,
      currencies,
      languages,
    };
  }

  // TODO: Remove the refresh of the page. (BAD UX)
  refreshPage = () => {
    window.location.reload();
  };

  save = async () => {
    try {
      const { data: store } = await updateStoreLanguageAndRegion(this.state.storeEdited);

      if (store) {
        this.setState({ data: store, storeEdited: { ...store } });
        this.refreshPage();
        toast.info("Store config updated.");
      }
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 400) {
          console.log(ex.response);
          Object.keys(ex.response.data).forEach(function (key) {
            const message = `${key}: ${ex.response.data[key][0]}`;
            toast.error(message);
          });
        } else if (ex.response.status === 404) {
          toast.error("Store not found");
        } else {
          toast.error("Failed to update");
        }
      } else {
        toast.error("Failed to update");
      }
    }
  };

  componentDidMount() {
    this.fetchTimeZones().then((response) => {
      this.setState({ timezones: response.timezones });
    });

    const isNewStore = this.props.isNewStore !== undefined && this.props.isNewStore === true;
    const { store } = this.props;

    if (store) {
      this.setState({
        data: store,
        storeEdited: { ...store },
        isNewStore,
      });

      this.fetchCountries(store.country).then((response) => {
        this.setState({
          countries: response.countries,
          currencies: response.currencies,
          languages: response.languages,
        });
      });
      this.fetchCurrency(store.currency).then((response) => {
        this.setState({ currentCurrency: response.currency });
      });
      this.fetchLanguage(store.language).then((response) => {
        this.setState({ currentLanguage: response.language });
      });

      const { countries } = this.state;
      let currentCountry = undefined;

      if (store.country !== undefined) {
        currentCountry = countries.find((country) => country.id === store.country);
        if (currentCountry !== undefined) {
          currentCountry = {
            id: currentCountry.id,
            value: currentCountry.code,
            label: currentCountry.name,
          };
          this.setState({ currentCountry });
        }
      }
    } else {
      this.fetchCountries(undefined).then((response) => {
        this.setState({
          countries: response.countries,
          currencies: response.currencies,
          languages: response.languages,
        });
      });
      this.setState({ data: store, storeEdited: {}, isNewStore });
    }
  }

  onSelectedTimezoneChanged = (id) => {
    const { timezones, storeEdited } = this.state;
    if (timezones && timezones.length > 0) {
      const selectedTimezone = timezones.find((timezone) => timezone.id === parseInt(id));
      if (selectedTimezone) {
        this.setState({
          storeEdited: Object.assign(storeEdited, { timezone: selectedTimezone.id }),
        });
      }
    }
  };

  onSelectedCountryChanged = (id) => {
    const { countries, storeEdited, currentCurrency, currentLanguage } = this.state;
    if (countries && countries.length > 0) {
      const selectedCountry = countries.find((country) => country.code === id);
      if (selectedCountry) {
        if (!selectedCountry.currencies.includes(currentCurrency)) {
          selectedCountry.currencies.push(currentCurrency);
        }
        if (!selectedCountry.languages.includes(currentLanguage)) {
          selectedCountry.languages.push(currentLanguage);
        }

        const selectedCurrency = selectedCountry.currencies.find((currency) => currency.id === currentCurrency.id);
        const selectedLanguage = selectedCountry.languages.find((language) => language.id === currentLanguage.id);

        let currencyId = selectedCountry.currencies[0].id;
        let languageId = selectedCountry.languages[0].id;

        if (selectedCurrency !== undefined) {
          currencyId = selectedCurrency.id;
        }
        if (selectedLanguage !== undefined) {
          languageId = selectedLanguage.id;
        }

        this.setState({
          currencies: selectedCountry.currencies,
          languages: selectedCountry.languages,
          storeEdited: Object.assign(storeEdited, {
            currency: currencyId,
            language: languageId,
            country: selectedCountry.id,
          }),
        });
      }
    }
  };

  onSelectedCurrencyChanged = (id) => {
    const { currencies, storeEdited, currentCurrency } = this.state;
    if (currencies && currencies.length > 0) {
      const selectedCurrency = currencies.find((currency) => currency.code === id);
      if (selectedCurrency) {
        this.setState({
          storeEdited: Object.assign(storeEdited, { currency: selectedCurrency.id }),
        });
      } else if (id === currentCurrency.code) {
        this.setState({
          storeEdited: Object.assign(storeEdited, { currency: currentCurrency.id }),
        });
      }
    }
  };

  onSelectedLanguageChanged = (id) => {
    const { languages, storeEdited, currentLanguage } = this.state;
    if (languages && languages.length > 0) {
      const selectedLanguage = languages.find((language) => language.code === id);
      if (selectedLanguage) {
        this.setState({
          storeEdited: Object.assign(storeEdited, { language: selectedLanguage.id }),
        });
      } else if (id === currentLanguage.code) {
        this.setState({
          storeEdited: Object.assign(storeEdited, { language: currentLanguage.id }),
        });
      }
    }
  };

  /**
   * Adds the current store's currency to the given currencies array if missing.
   *
   * Please note that this function has side effect since it will update the given currencies array.
   *
   * @param currencies list of currencies in which currency will be added if necessary.
   */
  addCurrentStoreCurrencyIfMissing = (currencies) => {
    const { currentCurrency } = this.state;
    if (currentCurrency !== undefined) {
      const currenciesGroupedById = currencies.reduce((group, currency) => {
        const { id } = currency;
        group[id] = group[id] ?? [];
        group[id] = currency;
        return group;
      }, {});
      if (!(currentCurrency.id in currenciesGroupedById)) {
        currencies.push({
          id: currentCurrency.id,
          value: currentCurrency.code,
          label: currentCurrency.name,
        });
      }
    }
  };

  /**
   * Adds the current store's language to the given languages array if missing.
   *
   * Please note that this function has side effect since it will update the given languages array.
   *
   * @param languages list of languages in which language will be added if necessary.
   */
  addCurrentStoreLanguageIfMissing = (languages) => {
    const { currentLanguage } = this.state;
    if (currentLanguage !== undefined) {
      const languagesGroupedById = languages.reduce((group, language) => {
        const { id } = language;
        group[id] = group[id] ?? [];
        group[id] = language;
        return group;
      }, {});
      if (!(currentLanguage.id in languagesGroupedById)) {
        languages.push({
          id: currentLanguage.id,
          value: currentLanguage.code,
          label: currentLanguage.name,
        });
      }
    }
  };

  render() {
    const className = "language-region";
    const { timezones, countries, currencies, languages, currentCurrency, currentLanguage, storeEdited, isNewStore } =
      this.state;
    const adaptedCurrencies = currencies.map((currency) => ({
      id: currency.id,
      value: currency.code,
      label: currency.name,
      ...currency,
    }));
    const adaptedLanguages = languages.map((language) => ({
      id: language.id,
      value: language.code,
      label: language.name,
      ...language,
    }));
    const adaptedCountries = countries.map((country) => ({
      id: country.id,
      value: country.code,
      label: country.name,
      ...country,
    }));
    const adaptedTimezones = timezones.map((timezone) => ({
      id: timezone.id,
      value: timezone.id,
      label: timezone.code,
      ...timezone,
    }));

    this.addCurrentStoreCurrencyIfMissing(adaptedCurrencies);
    this.addCurrentStoreLanguageIfMissing(adaptedLanguages);

    return (
      <Card className={className}>
        <CardHeader>
          <CardTitle tag="h4">Language & Region</CardTitle>
          {currentCurrency && currentLanguage && (
            <CardSubtitle>
              <small className="text-muted">
                Store is running in production with: {currentCurrency.name} - {currentLanguage.name}
              </small>
            </CardSubtitle>
          )}
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="pr-md-1" md="3">
              {isNewStore ? (
                <DropDown
                  label="TimeZone"
                  items={adaptedTimezones}
                  onSelectionChanged={this.onSelectedTimezoneChanged}
                />
              ) : (
                <DropDown
                  label="TimeZone"
                  items={adaptedTimezones}
                  selectedId={storeEdited.timezone}
                  onSelectionChanged={this.onSelectedTimezoneChanged}
                />
              )}
            </Col>
            <Col className="pr-md-1" md="3">
              {isNewStore ? (
                <DropDown label="Country" items={adaptedCountries} onSelectionChanged={this.onSelectedCountryChanged} />
              ) : (
                <DropDown
                  label="Country"
                  items={adaptedCountries}
                  selectedId={storeEdited.country}
                  onSelectionChanged={this.onSelectedCountryChanged}
                />
              )}
            </Col>
            <Col className="pr-md-1" md="3">
              {isNewStore ? (
                <DropDown
                  label="Currency"
                  items={adaptedCurrencies}
                  onSelectionChanged={this.onSelectedCurrencyChanged}
                />
              ) : (
                <DropDown
                  label="Currency"
                  items={adaptedCurrencies}
                  selectedId={storeEdited.currency}
                  onSelectionChanged={this.onSelectedCurrencyChanged}
                />
              )}
            </Col>
            <Col className="pr-md-1" md="3">
              {isNewStore ? (
                <DropDown
                  label="Language"
                  items={adaptedLanguages}
                  onSelectionChanged={this.onSelectedLanguageChanged}
                />
              ) : (
                <DropDown
                  label="Language"
                  items={adaptedLanguages}
                  selectedId={storeEdited.language}
                  onSelectionChanged={this.onSelectedLanguageChanged}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="float-right btn-success m-2" onClick={this.save}>
                <i className="fas fa-save mr-2" />
                Save
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default LanguageRegionForm;

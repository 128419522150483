import { Col, Container, Input, Row } from "reactstrap";
import StoresTable from "./StoresTable";
import Pagination from "../../../components/common/pagination";
import { useMemo, useState } from "react";
import useFetch from "../../../components/shared/hooks/api/fetch";
import { getStoresHistoriesPaginatedEndpoint } from "../../../services/history/storeHistoryService";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import Loader from "../../../components/common/Loader";

function StoresTab({ page = 1, pageSize = 10, query = "" }) {
  const [currentPage, setCurrentPage] = useState(page);
  const [queryFilter, setQueryFilter] = useState(query);
  const [storesPaginator] = useFetch(
    getStoresHistoriesPaginatedEndpoint({
      page: currentPage,
      pageSize,
      query: queryFilter,
    })
  );

  const onPageChanged = (targetPage) => {
    setCurrentPage(targetPage);
  };

  const filterStores = (value) => {
    setQueryFilter(value.trim());
  };

  /**
   * Debounce of updates of query text.
   *
   * The purpose of using a debounced function is to limit the number of times filterStore function is called
   * during a specific time period (300ms).
   *
   * This improves the performance of the application by reducing the number of unnecessary queries.
   *
   * @type {DebouncedFunc<filterStores>}
   */
  const debouncedOnQueryChanged = useMemo(() => debounce(filterStores, 300), []);

  if (storesPaginator === null) {
    return <Loader />;
  }

  return (
    <Container fluid>
      <Row>
        <Col md="4">
          <Input
            data-testid="query_input"
            name="filter_store"
            id="filter_store"
            placeholder="eg: fr-location or -10023456789"
            onChange={(e) => debouncedOnQueryChanged(e.currentTarget.value)}
          />
        </Col>
      </Row>
      <Row className="pt-2">
        <StoresTable stores={storesPaginator.results} />
      </Row>
      <Row>
        <Pagination
          itemsCount={storesPaginator.count}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={onPageChanged}
        />
      </Row>
    </Container>
  );
}

StoresTab.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  query: PropTypes.string,
};

export default StoresTab;

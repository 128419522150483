import React from "react";

import { Card, CardHeader, CardBody, Button } from "reactstrap";
import ServerServices from "./ServerServices";
import DisplayWhen from "./common/base/DisplayWhen";
import userHasRole from "./shared/utils/authUtils";
import { csmRole, csmMaintenanceRole } from "./shared/constants";

function ServerServicesList({ servers }) {
  return (
    <Card>
      <CardHeader className="mb-2">
        <h5 className="card-category">Servers</h5>
      </CardHeader>

      {Object.keys(servers.status).map((server, index) => (
        <CardBody key={`server-${index}`}>
          <h4>
            {server}
            <DisplayWhen
              condition={servers.status[server].id && (!userHasRole(csmRole) || userHasRole(csmMaintenanceRole))}
            >
              <a className="ml-3" href={`/remotecontrol?server=${servers.status[server].id}&command=screenshot`}>
                <Button>Remote control</Button>
              </a>
            </DisplayWhen>
          </h4>
          <ServerServices server={servers.status[server]} serverName={server} />
        </CardBody>
      ))}
    </Card>
  );
}

export default ServerServicesList;

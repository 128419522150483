import React, { Component } from "react";
import { Button } from "reactstrap";
import ToggleSwitch from "../common/controls/ToggleSwitch/ToggleSwitch";

import SubChannelForm from "../Forms/SubChannelForm";
class SubChannel extends Component {
  state = { editMode: false, deleteMode: false };

  toggleEditMode = () => {
    const { subChannel, updateSubChannelList, subChannelIndex } = this.props;
    const { editMode } = this.state;
    if (!subChannel) updateSubChannelList(null, subChannelIndex);
    this.setState({ editMode: !editMode });
  };

  toggleDeleteMode = () => {
    const { deleteMode } = this.state;
    this.setState({ deleteMode: !deleteMode });
  };

  flatenViewArea(viewArea) {
    if (viewArea) return `${viewArea.length} view points`;
    return "0 points";
  }

  render() {
    const { subChannel, storeId, updateSubChannelList, removeSubChannel, subChannelIndex, channelId } = this.props;
    const { editMode, deleteMode } = this.state;
    let className = "sub-channel";
    if (deleteMode) className = `${className} danger`;
    return (
      <React.Fragment>
        {subChannel && !editMode && (
          <tr>
            <td>{subChannel.sub_channel_number}</td>
            <td>{subChannel.channel_name}</td>
            <td>{subChannel.favor_factor}</td>
            <td>{this.flatenViewArea(subChannel.view_area)}</td>
            <td>{subChannel.anchor}</td>
            <td>
              <ToggleSwitch checked={subChannel.is_active} disabled />
            </td>
            <td className="text-center">
              <Button onClick={this.toggleEditMode}>
                <i className="fas fa-edit mr-2" />
                Edit
              </Button>
            </td>
          </tr>
        )}

        {subChannel && editMode && (
          <SubChannelForm
            subChannel={subChannel}
            storeId={storeId}
            toggleEditMode={this.toggleEditMode}
            updateSubChannelList={updateSubChannelList}
            removeSubChannel={removeSubChannel}
            toggleDeleteMode={this.toggleDeleteMode}
            deleteMode={deleteMode}
            className={className}
            subChannelIndex={subChannelIndex}
            channelId={channelId}
          />
        )}
        {!subChannel && (
          <SubChannelForm
            subChannel={subChannel}
            storeId={storeId}
            toggleEditMode={this.toggleEditMode}
            updateSubChannelList={updateSubChannelList}
            subChannelIndex={subChannelIndex}
            channelId={channelId}
          />
        )}
      </React.Fragment>
    );
  }
}

export default SubChannel;

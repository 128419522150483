import * as Sentry from "@sentry/react";
import { Button, Col, Container, Row } from "reactstrap";
import PageContainer from "../../../components/common/PageContainer";
import ReactDiffViewer from "react-diff-viewer-continued";
import { useParams, useHistory } from "react-router-dom";
import useFetch from "../../../components/shared/hooks/api/fetch";
import { getStoreHistoryEndpoint } from "../../../services/history/storeHistoryService";
import React, { useState } from "react";
import TooltipButton from "../../../components/common/TooltipButton";
import formatDate from "../utils";
import Loader from "../../../components/common/Loader";

/**
 * Store diff viewer.
 *
 * @returns {JSX.Element}
 * @constructor
 */
function StoreDiffViewer() {
  const history = useHistory();
  const { storeLocation, newRevisionIndex, oldRevisionIndex } = useParams();
  const [indexes, setIndexes] = useState({
    newRevisionIndex: parseInt(newRevisionIndex),
    oldRevisionIndex: parseInt(oldRevisionIndex),
  });
  const [storeHistories, requestState] = useFetch(getStoreHistoryEndpoint(storeLocation));
  const { currentVersion, historyVersions } = storeHistories ?? {
    currentVersion: null,
    historyVersions: null,
  };

  const onPreviousClicked = () => {
    setIndexes((currentIndexes) => {
      return {
        newRevisionIndex: currentIndexes.newRevisionIndex + 1,
        oldRevisionIndex: currentIndexes.oldRevisionIndex + 1,
      };
    });
  };

  const onNextClicked = () => {
    setIndexes((currentIndexes) => {
      return {
        newRevisionIndex: currentIndexes.newRevisionIndex - 1,
        oldRevisionIndex: currentIndexes.oldRevisionIndex - 1,
      };
    });
  };

  const navigateBack = () => {
    history.go(-1);
  };

  const cleanArrayField = ({ oldHistoryVersion, newHistoryVersion, fieldName }) => {
    const ids = Object.fromEntries(oldHistoryVersion[fieldName].map((item) => [item.id, item]));
    newHistoryVersion[fieldName] = newHistoryVersion[fieldName].filter((item) => {
      return item.id in ids;
    });
  };

  const clone = (obj) => {
    return { ...obj };
  };

  const cleanHistoryVersion = (newHistoryVersion, oldHistoryVersion) => {
    if (!Object.prototype.hasOwnProperty.call(newHistoryVersion, "time_updated")) {
      cleanArrayField({
        oldHistoryVersion,
        newHistoryVersion,
        fieldName: "opening_hours",
      });

      cleanArrayField({
        oldHistoryVersion,
        newHistoryVersion,
        fieldName: "video_recorders",
      });

      cleanArrayField({
        oldHistoryVersion,
        newHistoryVersion,
        fieldName: "vt_ips",
      });
    }
  };

  const extractTitlesAndUser = (oldHistoryVersion, newHistoryVersion) => {
    let oldTitle = "Previous version";
    let newTitle = "Today's version";

    if (Object.prototype.hasOwnProperty.call(oldHistoryVersion, "revisionDetails")) {
      oldTitle = `Version from ${formatDate(oldHistoryVersion.revisionDetails.createdOn)}`;
      delete oldHistoryVersion.revisionDetails;
    }

    let user = null;

    if (Object.prototype.hasOwnProperty.call(newHistoryVersion, "revisionDetails")) {
      user = newHistoryVersion.revisionDetails.user;
      newTitle = `Version from ${formatDate(newHistoryVersion.revisionDetails.createdOn)}`;
      delete newHistoryVersion.revisionDetails;
    }

    return { oldTitle, newTitle, user };
  };

  const getHistoryVersions = () => {
    if (currentVersion === null && historyVersions === null) {
      return {
        oldHistoryVersion: {},
        newHistoryVersion: {},
        oldTitle: "Old Change",
        newTitle: "New Change",
        user: null,
      };
    }

    let oldHistoryVersion = {};
    let newHistoryVersion = {};

    if (indexes.oldRevisionIndex >= 0 && indexes.oldRevisionIndex < historyVersions.length) {
      oldHistoryVersion = clone(historyVersions[indexes.oldRevisionIndex]);
    }

    if (indexes.newRevisionIndex < 0) {
      newHistoryVersion = clone(currentVersion);
    } else {
      newHistoryVersion = clone(historyVersions[indexes.newRevisionIndex]);
    }

    cleanHistoryVersion(newHistoryVersion, oldHistoryVersion);

    if (Object.prototype.hasOwnProperty.call(newHistoryVersion, "time_updated")) {
      delete newHistoryVersion.time_updated;
    }

    if (Object.prototype.hasOwnProperty.call(oldHistoryVersion, "time_updated")) {
      delete oldHistoryVersion.time_updated;
    }

    const { oldTitle, newTitle, user } = extractTitlesAndUser(oldHistoryVersion, newHistoryVersion);

    return { oldHistoryVersion, newHistoryVersion, oldTitle, newTitle, user };
  };

  const { oldHistoryVersion, newHistoryVersion, oldTitle, newTitle, user } = getHistoryVersions();

  if (requestState.isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer>
      <Container fluid>
        <Row>
          <Col md={{ size: 2 }}>
            <Button onClick={navigateBack}>Go back</Button>
          </Col>
          <Col md={{ offset: 3, size: 4 }}>
            {user && user.username && (
              <p className="font-bold text-warning">
                <span className="text-white">changed by: </span>
                {user.username}
              </p>
            )}
          </Col>
          <Col md={{ size: 3 }}>
            {historyVersions && historyVersions.length > 1 && (
              <p className="font-bold text-warning">
                <span className="text-white">Change</span> {indexes.oldRevisionIndex + 1}{" "}
                <span className="text-white">of</span> {historyVersions.length}
              </p>
            )}
          </Col>
        </Row>
        <Row className="pt-2">
          {historyVersions && historyVersions.length > 0 && (
            <>
              <TooltipButton
                id="previous-button"
                tooltipLabel="View past changes"
                className="col align-self-end fa fa-arrow-left"
                color="success"
                disabled={indexes.oldRevisionIndex + 1 > historyVersions.length - 1}
                onClick={onPreviousClicked}
              />

              <TooltipButton
                id="next-button"
                tooltipLabel="View future changes"
                className="col align-self-end fa fa-arrow-right"
                color="success"
                disabled={indexes.newRevisionIndex - 1 < -1}
                onClick={onNextClicked}
              />
            </>
          )}
        </Row>
        <Row>
          <ReactDiffViewer
            leftTitle={oldTitle}
            oldValue={JSON.stringify(oldHistoryVersion, null, 2)}
            rightTitle={newTitle}
            newValue={JSON.stringify(newHistoryVersion, null, 2)}
            splitView
          />
        </Row>
      </Container>
    </PageContainer>
  );
}

export default Sentry.withProfiler(StoreDiffViewer);

import Model from "./Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };
class VideoRecorderModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number());
    this.store = this.createField(null, groups.PRIMARY, "store", Joi.number());
    this.type = this.createField(1, groups.PRIMARY, "Device type", Joi.number().required());
    this.username = this.createField("", groups.PRIMARY, "Username", Joi.string().required());
    this.password = this.createField("", groups.PRIMARY, "Password", Joi.string().required());
    this.device_ip = this.createField(
      "",
      groups.PRIMARY,
      "Device IP",
      Joi.string()
        .regex(/^(?:(?:25[0-5]|2[0-4]\d|1?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|1?\d{1,2}|\{\})$/)
        .required()
        .error((errors) => {
          return errors.map((err) => {
            if (err.type === "string.regex.base") {
              err.message = "'Device IP' must be a valid IP address like '192.168.1.1' or '192.168.1.{}'";
            }
            return err;
          });
        })
    );
    this.device_rtsp_port = this.createField(554, groups.PRIMARY, "RTSP port", Joi.number().required());
    this.device_api_port = this.createField("", groups.PRIMARY, "API port", Joi.number().allow(""));
    this.sub_type = this.createField("sub", groups.PRIMARY, "Sub type", Joi.string().required());
    this.rtsp_url_suffix = this.createField("", groups.PRIMARY, "RTSP URL suffix", Joi.string().allow(""));
    this.caching = this.createField(3, groups.PRIMARY, "Caching", Joi.number().required());
    this.set_date = this.createField(false, groups.PRIMARY, "Set data", Joi.boolean());
  }
}

export default VideoRecorderModel;

import http from "./httpService";

const apiEndPoint = "/notifiers/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function addNotifier(notifier) {
  return http.post(apiEndPoint, notifier);
}
export function deleteNotifier(notifierId) {
  return http.delete(getUrl(notifierId));
}

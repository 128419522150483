import Joi from "joi-browser";
import * as _ from "lodash";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Row, Spinner } from "reactstrap";
import getAlertChannels from "../../services/alertChannelService";
import { saveChannel } from "../../services/channelService";
import getDepthOfFields from "../../services/depthOfFieldService";
import { getInferenceMachines } from "../../services/inferenceMachineService";
import getRelevances from "../../services/relevanceService";
import DisplayWhen from "../common/base/DisplayWhen";
import { editorRole } from "../shared/constants";
import userHasRole from "../shared/utils/authUtils";
import Channel from "./Channel";
import "./ChannelsTable.scss";
import SortListItem from "./SortListItem";

class ChannelsTable extends Component {
  state = {
    bulkFieldChanged: false,
    channels: null,
    channelsBackup: [],
    selectedChannelIdList: [],
    inferenceMachines: [],
    showMachinesList: false,
    currentPage: 1,
    query: "",
    currentMachine: "",
    currentValue: "",
    showBulkActions: false,
    isAllChecked: false,
    bulkErrorMessageRecheckings: "",
    bulkErrorMessageFavorFactor: "",
    alertChannels: [],
    relevances: [],
    depthOfFields: [],
    showSortingActions: false,
    sortOrder: "asc",
    sortElt: "number",
  };
  sortingMenuRef = React.createRef();

  bulkActionButtons = [
    { value: "tcp", name: "Set to TCP" },
    { value: "udp", name: "Set to UDP" },
    { value: "active", name: "Activate" },
    { value: "inactive", name: "Deactivate" },
  ];

  async componentDidMount() {
    let { channels } = this.props;
    channels = channels.sort(function (a, b) {
      return a.channel_number - b.channel_number;
    });
    this.setState({ channels });
    await this.fetchMachines();
    await this.fetchAll();
    window.addEventListener("click", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { query } = this.state;
    if (prevState.query !== query) {
      this.timer = setTimeout(() => this.fetchMachines(), 200);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { showSortingActions } = this.state;
    if (showSortingActions && this.sortingMenuRef.current && !this.sortingMenuRef.current.contains(event.target)) {
      this.setState({ showSortingActions: false });
    }
  };

  fetchAlertChannels = async () => {
    let { data: alertChannels } = await getAlertChannels();
    alertChannels = alertChannels.map((alertChannel) => {
      if (!alertChannel.name.includes("DNS")) {
        return { ...alertChannel, enabled: false };
      }
      return alertChannel;
    });
    alertChannels.unshift({ id: "0", name: "Global Value" });
    return alertChannels;
  };

  fetchDepthOfFields = async () => {
    const { data: depthOfFields } = await getDepthOfFields();
    depthOfFields.unshift({ id: "0", name: "" });
    return depthOfFields;
  };

  fetchRelevances = async () => {
    let { data: relevances } = await getRelevances();
    relevances = relevances
      .map((relevance) => ({
        name: `${relevance.value} ${relevance.name}`,
        value: relevance.value,
        id: relevance.id,
      }))
      .sort((a, b) => a.id - b.id);
    relevances.unshift({ id: "0", name: "" });
    return relevances;
  };

  fetchAll = async () => {
    const [alertChannels, depthOfFields, relevances] = await Promise.all([
      this.fetchAlertChannels(),
      this.fetchDepthOfFields(),
      this.fetchRelevances(),
    ]);
    this.setState({ alertChannels, depthOfFields, relevances });
  };

  fetchMachines = async () => {
    const { currentPage, query } = this.state;
    try {
      const { data: pagedMachines } = await getInferenceMachines(currentPage, query);
      let inferenceMachines = pagedMachines.results;
      inferenceMachines = inferenceMachines.map((machine) => {
        return {
          value: machine.id,
          name: machine.machine.hostname,
        };
      });
      inferenceMachines = inferenceMachines.sort(function (a, b) {
        return a.name > b.name;
      });
      inferenceMachines.unshift({ value: 0, name: "Not assigned" });

      this.setState({ inferenceMachines });
    } catch (ex) {
      console.log(ex);
      toast.error("Failed to connect, please log in");
    }
  };

  validateNumber = (value, min = -Number.MAX_VALUE, max = Number.MAX_VALUE) => {
    const obj = { value };
    const schema = Joi.object({
      value: Joi.number().precision(2).min(min).max(max),
    });
    const { error } = schema.validate(obj, { convert: false });
    if (!error) {
      return null;
    }
    if (!Number.isNaN(parseFloat(value)) && !Number.isInteger(value * 100)) {
      return " must have 2 decimals maximum";
    }
    if (value < min) {
      return ` must be a larger than or equal to ${min}`;
    }
    if (value > max) {
      return ` must be smaller than or equal to ${max}`;
    }
    return " must be a number";
  };

  validateInteger = (value, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER) => {
    const obj = { value };
    const schema = Joi.object({
      value: Joi.number().integer().min(min).max(max),
    });
    const { error } = schema.validate(obj);
    if (!error) {
      return null;
    }
    if (value < min) {
      return ` must be larger than or equal to ${min}`;
    }
    if (value > max) {
      return ` must be smaller than or equal to ${max}`;
    }
    return " must be an integer";
  };

  addNewChannel = () => {
    const { channels } = this.state;
    channels.push(null);
    this.setState({ channels });
  };

  updateChannelList = (channel, index) => {
    let { channels } = this.state;
    if (!channel) {
      channels.splice(index, 1);
    } else {
      channels[index] = channel;
    }
    channels = channels.sort(function (a, b) {
      return a.channel_number - b.channel_number;
    });
    this.setState({ channels });
  };

  removeChannel = (channelId) => {
    const { channels } = this.state;
    const filteredChannels = channels.filter((channel) => channel.id !== channelId);
    this.setState({ channels: filteredChannels });
  };

  setBulkFieldChanged = (changed) => {
    const { channels } = this.state;
    let { channelsBackup } = this.state;
    if (channelsBackup.length === 0) {
      channelsBackup = _.cloneDeep(channels);
    }
    const bulkFieldChanged = changed;
    this.setState({ bulkFieldChanged, channelsBackup });
    return channelsBackup;
  };

  setBulkButton = (event) => {
    const { selectedChannelIdList } = this.state;
    if (selectedChannelIdList.length > 0) {
      this.setBulkFieldChanged(true);
      const fieldValue = event;
      const { channels } = this.state;
      channels.forEach((channel) => {
        if (selectedChannelIdList.includes(channel.channel_number)) {
          if (fieldValue === "tcp") {
            channel.protocol = fieldValue;
          } else if (fieldValue === "udp") {
            channel.protocol = fieldValue;
          } else if (fieldValue === "active") {
            channel.is_active = true;
          } else if (fieldValue === "inactive") {
            channel.is_active = false;
          }
        }
      });
      this.setState({ channels });
    }
  };

  setBulkRecheckings = (event) => {
    const { selectedChannelIdList } = this.state;
    if (selectedChannelIdList.length <= 0) {
      const bulkErrorMessageRecheckings = "No channel selected";
      this.setState({ bulkErrorMessageRecheckings });
    } else {
      this.setState({ bulkErrorMessageRecheckings: "" });
      const strValue = event.target.value;
      if (!strValue) {
        return;
      }
      const value = +strValue;
      const errorMessage = this.validateInteger(
        +value,
        parseInt(process.env.REACT_APP_MIN_RECHECKING),
        parseInt(process.env.REACT_APP_MAX_RECHECKING)
      );
      if (errorMessage != null) {
        const bulkErrorMessageRecheckings = `"Recheckings"${errorMessage}`;
        this.setState({ bulkErrorMessageRecheckings });
        return;
      }
      this.setBulkFieldChanged(true);
      const { channels } = this.state;
      channels.forEach((channel) => {
        if (selectedChannelIdList.includes(channel.channel_number)) {
          channel.recheckings = value;
        }
      });
      this.setState({ channels });
    }
  };

  setBulkFavorFactor = (event) => {
    const { selectedChannelIdList } = this.state;
    if (selectedChannelIdList.length <= 0) {
      const bulkErrorMessageFavorFactor = "No channel selected";
      this.setState({ bulkErrorMessageFavorFactor });
    } else {
      this.setState({ bulkErrorMessageFavorFactor: "" });
      const strValue = event.target.value;
      const value = strValue ? +strValue : 0;
      let errorMessage = this.validateNumber(value);
      if (errorMessage != null) {
        const bulkErrorMessageFavorFactor = `"Favor Factor"${errorMessage}`;
        this.setState({ bulkErrorMessageFavorFactor });
        return;
      }
      const channelsBackup = this.setBulkFieldChanged(true);
      const { channels } = this.state;
      channels.forEach((channel) => {
        if (selectedChannelIdList.includes(channel.channel_number)) {
          const channelBackup = channelsBackup.find((x) => x.channel_number === channel.channel_number);
          let originalValue = +channelBackup.favor_factor;
          if (!originalValue) {
            originalValue = 1;
          }
          errorMessage = this.validateNumber(
            parseFloat((originalValue + value).toFixed(2)),
            parseInt(process.env.REACT_APP_MIN_FAVOR_FACTOR)
          );
          if (errorMessage != null) {
            const bulkErrorMessageFavorFactor = `Channel ${
              channel.channel_number
            } Favor Factor${errorMessage} (actual : ${(originalValue + value).toFixed(2)})`;
            this.setState({ bulkErrorMessageFavorFactor });
            return;
          }
          if (originalValue + value === 1) {
            channel.favor_factor = null;
          } else {
            channel.favor_factor = originalValue + value;
            channel.favor_factor = channel.favor_factor.toFixed(2);
          }
        }
      });
      this.setState({ channels });
    }
  };

  setMachine = (event, machine) => {
    const { selectedChannelIdList } = this.state;
    if (selectedChannelIdList.length > 0) {
      this.setBulkFieldChanged(true);
      const { channels } = this.state;
      channels.forEach((channel) => {
        if (selectedChannelIdList.includes(channel.channel_number)) {
          channel.inference_machine = machine.value;
          channel.inference_machine_data = { id: machine.value, hostname: machine.name };
        }
      });
      const currentMachine = machine.name;
      const currentValue = "";
      const query = "";
      this.setState({ channels, currentMachine, currentValue, query });
    }
  };

  resetBulk = () => {
    document.getElementById("favor_factor").value = "";
    document.getElementById("recheckings").value = "";
    this.setBulkFieldChanged(false);
    let { channels } = this.state;
    let { channelsBackup } = this.state;
    if (channelsBackup.length > 0) {
      channels = _.cloneDeep(channelsBackup);
      channelsBackup = [];
      const currentMachine = "";
      this.setState({ channels, channelsBackup, currentMachine });
    }
  };

  applyBulk = async () => {
    document.getElementById("favor_factor").value = "";
    document.getElementById("recheckings").value = "";
    const { channels } = this.state;
    // can't have an await inside a forEach, forced for loop
    // eslint-disable-next-line no-restricted-syntax
    for (const channel of channels) {
      await saveChannel(channel);
    }
    const channelsBackup = [];
    this.setBulkFieldChanged(false);
    const currentMachine = "";
    this.setState({ channels, channelsBackup, currentMachine });
    toast.info("Channel configs updated.");
  };

  showHideBulk = () => {
    const { showBulkActions } = this.state;
    const updatedShowBulkActions = !showBulkActions;
    this.setState({ showBulkActions: updatedShowBulkActions });
  };

  showSortingMenu = () => {
    const { showSortingActions } = this.state;
    const updatedShowSortingActions = !showSortingActions;
    this.setState({ showSortingActions: updatedShowSortingActions });
  };

  handleInferenceMachineChange = (value) => {
    clearTimeout(this.timer);
    this.setState({ query: value, currentPage: 1, currentValue: value });
  };

  showMachineList = () => {
    const showMachinesList = true;
    this.setState({ showMachinesList });
  };

  hideMachineList = () => {
    const showMachinesList = false;
    const currentValue = "";
    const query = "";
    this.setState({ showMachinesList, currentValue, query });
  };

  selectChannel = (isSelect, channelNumber) => {
    const { bulkErrorMessageRecheckings, bulkErrorMessageFavorFactor } = this.state;
    this.setState((prevState) => {
      if (bulkErrorMessageRecheckings === "No channel selected") {
        this.setState({ bulkErrorMessageRecheckings: "" });
      }
      if (bulkErrorMessageFavorFactor === "No channel selected") {
        this.setState({ bulkErrorMessageFavorFactor: "" });
      }
      let tmpSelectedChannelIdList = [...prevState.selectedChannelIdList];
      if (isSelect) {
        if (!tmpSelectedChannelIdList.includes(channelNumber)) {
          tmpSelectedChannelIdList.push(channelNumber);
          const { channels } = this.state;
          if (channels.length === tmpSelectedChannelIdList.length) {
            this.setState({ isAllChecked: true });
          }
        }
      } else {
        tmpSelectedChannelIdList = tmpSelectedChannelIdList.filter((id) => id !== channelNumber);
        this.setState({ isAllChecked: false });
      }
      return {
        selectedChannelIdList: tmpSelectedChannelIdList,
      };
    });
  };

  selectAllChannels = (isSelect) => {
    const { channels } = this.state;
    channels.forEach((channel) => {
      this.selectChannel(isSelect, channel.channel_number);
    });
    this.setState({ isAllChecked: isSelect });
  };

  sortBy = (factor, order) => {
    const { channels } = this.state;
    const sortedChannels = [...channels];

    const compareFunction = (a, b) => {
      if (order === "asc") {
        return a[factor] - b[factor];
      }
      return b[factor] - a[factor];
    };

    sortedChannels.sort(compareFunction);
    this.setState({ channels: sortedChannels, sortElt: factor, sortOrder: order });
  };

  render() {
    const {
      channels,
      selectedChannelIdList,
      inferenceMachines,
      currentMachine,
      currentValue,
      bulkFieldChanged,
      isAllChecked,
      bulkErrorMessageRecheckings,
      bulkErrorMessageFavorFactor,
      alertChannels,
      relevances,
      depthOfFields,
      showBulkActions,
      showMachinesList,
      showSortingActions,
      sortOrder,
      sortElt,
    } = this.state;

    const { storeId, canHaveSubChannels, videoRecorderId } = this.props;

    return (
      <div>
        <div>
          <hr style={{ borderColor: "gray", marginTop: 15 }} />
          <Row>
            <Col md="9" className="device-channels">
              <Button onClick={() => this.showHideBulk()}>
                <i className="fas fa-wrench mr-2" />
                Bulk setup
                <i className={showBulkActions ? "fa-angle-up fas" : "fa-angle-down fas"} />
              </Button>
              <div id="bulk-actions" style={{ display: showBulkActions ? "block" : "none" }}>
                {this.bulkActionButtons.map((option, index) => (
                  <Button key={`button-${index}`} className="btn" onClick={() => this.setBulkButton(option.value)}>
                    {option.name}
                  </Button>
                ))}
                <FormGroup className="bulk-input">
                  <label htmlFor="favor_factor">Add to Favor Factor (+/-)</label>
                  <Input
                    id="favor_factor"
                    placeholder="Favor factor"
                    onChange={(event) => this.setBulkFavorFactor(event)}
                  />
                  {bulkErrorMessageFavorFactor && (
                    <div style={{ color: "#ff576c", fontSize: "12px" }}>{bulkErrorMessageFavorFactor}</div>
                  )}
                </FormGroup>
                <FormGroup className="bulk-input">
                  <label htmlFor="recheckings">Recheckings</label>
                  <Input
                    id="recheckings"
                    placeholder="Recheckings"
                    onChange={(event) => this.setBulkRecheckings(event)}
                  />
                  {bulkErrorMessageRecheckings && (
                    <div style={{ color: "#ff576c", fontSize: "12px" }}>{bulkErrorMessageRecheckings}</div>
                  )}
                </FormGroup>
                <div id="inference-machine-select">
                  <label htmlFor="find_machine" className="choose-machine">
                    Inference machine
                  </label>
                  <Input
                    name="find_machine"
                    id="find_machine"
                    placeholder="Search"
                    value={!showMachinesList ? currentMachine : currentValue}
                    onChange={(e) => this.handleInferenceMachineChange(e.currentTarget.value)}
                    onClick={this.showMachineList}
                    onBlur={this.hideMachineList}
                    style={{ fontWeight: currentValue === "" ? "bold" : "normal" }}
                  />
                  <div
                    className="machine-list machine-list-bulk"
                    style={{ display: showMachinesList ? "block" : "none" }}
                  >
                    {inferenceMachines &&
                      inferenceMachines.map((infMachine, index) => (
                        <div key={`machine-item-${index}`} className="machine-item">
                          <Button className="text-left" onMouseDown={(e) => this.setMachine(e, infMachine)}>
                            {infMachine.name}
                          </Button>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="inline-block">
                  <Button className="btn btn-success btn-apply" disabled={!bulkFieldChanged} onClick={this.applyBulk}>
                    <i className="fas fa-check mr-2" />
                    Apply
                  </Button>
                  <Button className="btn" disabled={!bulkFieldChanged} onClick={this.resetBulk}>
                    <i className="fas fa-times mr-2" />
                    Reset
                  </Button>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div ref={this.sortingMenuRef} className="mb-4">
                <Button className="float-right" onClick={() => this.showSortingMenu()}>
                  Sorting
                  <i className={showSortingActions ? "fa-angle-up fas" : "fa-angle-down fas"} />
                </Button>
                {showSortingActions && (
                  <div className="z-50 absolute divide-y darker_default_bg_color divide-gray-100 rounded-lg shadow w-44 top-12 right-0">
                    <ul className="pt-2 text-sm text-gray-700" aria-labelledby="dropdownBottomButton">
                      <SortListItem
                        sortElt="channel_number"
                        sortOrder={sortOrder}
                        fieldName="Number"
                        onClick={this.sortBy}
                        active={sortElt === "channel_number"}
                      />
                      <SortListItem
                        sortElt="depth_of_field"
                        sortOrder={sortOrder}
                        fieldName="Depth of Field"
                        onClick={this.sortBy}
                        active={sortElt === "depth_of_field"}
                      />
                      <SortListItem
                        sortElt="favor_factor"
                        sortOrder={sortOrder}
                        fieldName="Favor Factor"
                        onClick={this.sortBy}
                        active={sortElt === "favor_factor"}
                      />
                      <SortListItem
                        sortElt="recheckings"
                        sortOrder={sortOrder}
                        fieldName="Rechecks"
                        onClick={this.sortBy}
                        active={sortElt === "recheckings"}
                      />
                      <hr style={{ borderColor: "gray", marginTop: 15 }} />
                      <SortListItem
                        sortElt={sortElt}
                        sortOrder="asc"
                        fieldName="Ascending"
                        onClick={this.sortBy}
                        active={sortOrder === "asc"}
                      />
                      <SortListItem
                        sortElt={sortElt}
                        sortOrder="desc"
                        fieldName="Descending"
                        onClick={this.sortBy}
                        active={sortOrder === "desc"}
                      />
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        {channels && (
          <Row className="selectAll" style={{ display: showBulkActions ? "flex" : "none" }}>
            <Col className="pr-md-1 select-all-input" md="0">
              <input
                type="checkbox"
                checked={isAllChecked}
                onChange={(event) => this.selectAllChannels(event.target.checked)}
              />
            </Col>
          </Row>
        )}
        <hr style={{ borderColor: "gray", marginTop: 15 }} />
        <div className="tablesorter" responsive>
          {!channels && <Spinner color="primary" />}
          {channels &&
            channels.map((channel, index) => (
              <Channel
                key={channel && channel.channel_number ? channel.channel_number : `channel-${index}`}
                channel={channel}
                alertChannels={alertChannels}
                relevances={relevances}
                depthOfFields={depthOfFields}
                storeId={storeId}
                videoRecorderId={videoRecorderId}
                updateChannelList={this.updateChannelList}
                removeChannel={this.removeChannel}
                channelIndex={index}
                selectChannel={this.selectChannel}
                checked={channel ? selectedChannelIdList.includes(channel.channel_number) : false}
                showBulkActions={showBulkActions}
                canHaveSubChannels={canHaveSubChannels}
              />
            ))}
          <DisplayWhen condition={userHasRole(editorRole)}>
            <Button className="float-right" onClick={this.addNewChannel}>
              <i className="fas fa-plus mr-2" />
              Add new channel
            </Button>
          </DisplayWhen>
        </div>
      </div>
    );
  }
}

export default ChannelsTable;

import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import MachineStream from "./MachineStream";
function MachineStreams({ streams }) {
  return (
    <Card>
      <CardHeader>
        <h5 className="card-category">Streams</h5>
        <hr />
      </CardHeader>
      <CardBody>
        {streams.map((stream) => (
          <MachineStream
            key={stream.id}
            location={stream.location}
            forwarder={stream.forwarder}
            channel={stream.channel_number}
          />
        ))}
      </CardBody>
    </Card>
  );
}

export default MachineStreams;

import { Button, Form } from "reactstrap";
import BaseForm from "../common/BaseForm";
import StatusIndicator from "../common/StatusIndicator/StatusIndicator";
import { deleteVtIp, saveVtIp } from "../../services/vtIpService";
import VtIp from "../models/VtIpModel";
import "../vtip/VtIp.scss";

class VtIpForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  vtIp = new VtIp();
  schema = this.vtIp.getSchema();

  componentDidMount() {
    const { vtIp } = this.props;
    let data = null;
    if (vtIp) {
      data = this.vtIp.getObject(vtIp);
    } else {
      data = this.vtIp.getDefaultData();
      data.store = this.props.storeId;
    }
    this.setState({ data });
  }

  delete = async () => {
    let status = "deleting";
    this.setState({ status });
    try {
      await deleteVtIp(this.state.data.id);
      this.props.removeFromVtIpList(this.state.data.id);
    } catch (exception) {
      if (exception.response) {
        status = "Failed to delete!";
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  save = async () => {
    let status = "saving";
    this.setState({ status });
    try {
      const { data: vtIp } = await saveVtIp(this.state.data);
      this.props.updateVtIpList(vtIp);
      status = "success";
      this.setState({ status });
      this.props.toggleEditMode();
    } catch (exception) {
      if (exception.response) {
        status = "Invalid data provided!";
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  render() {
    const { showNewVtIp } = this.props;
    const { status } = this.state;
    let statusType = null;
    let message = "";
    switch (status) {
      case "edited":
        statusType = null;
        message = null;
        break;
      case "started":
        statusType = null;
        message = null;
        break;
      case "saving":
        statusType = "progress";
        message = "saving...";
        break;
      case "deleting":
        statusType = "progress";
        message = "deleting...";
        break;
      case "success":
        statusType = "success";
        message = "saved!";
        break;
      default:
        statusType = "failed";
        message = status;
    }
    const statusIndicator = (
      <div className="float-right m-2">
        <StatusIndicator status={statusType} message={message} />
      </div>
    );
    return (
      <div>
        <Form>
          <div className="vt-ip">
            {this.renderInput("ip")}
            <Button className="float-right btn-success m-2" onClick={this.save}>
              <i className="fas fa-save mr-2" />
              Save
            </Button>
            {!showNewVtIp && (
              <Button className="float-right btn-danger m-2" onClick={this.delete}>
                <i className="fas fa-trash-alt mr-2" />
                Delete
              </Button>
            )}
            {message && statusIndicator}
          </div>
        </Form>
      </div>
    );
  }
}

export default VtIpForm;

import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      label: undefined,
      selectedId: undefined,
      onSelectionChanged: () => {},
      readOnly: false,
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(props, state) {
    return {
      items: props.items,
      readOnly: props.readOnly,
      label: props.label,
      selectedId: props.selectedId,
      onSelectionChanged: props.onSelectionChanged,
    };
  }

  render() {
    const { items, label, selectedId, onSelectionChanged, readOnly } = this.state;

    let selectedItem = items.find((item) => selectedId === item.id);
    if (selectedItem === undefined) {
      selectedItem = items[0];
    }

    const defaultItem = items.find((item) => selectedItem.id === item.id);
    const options = items.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ));

    return (
      <FormGroup>
        {label && <Label for={label}>{label}</Label>}
        {defaultItem ? (
          <Input
            id={label}
            name="select"
            type="select"
            value={defaultItem.value}
            disabled={readOnly}
            onChange={(event) => {
              onSelectionChanged(event.target.value);
            }}
          >
            {options}
          </Input>
        ) : (
          <Input
            id={label}
            name="select"
            type="select"
            disabled={readOnly}
            onChange={(event) => {
              onSelectionChanged(event.target.value);
            }}
          >
            {options}
          </Input>
        )}
      </FormGroup>
    );
  }
}

export default DropDown;

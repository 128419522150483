import Model from "../Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class NmapModel extends Model {
  constructor() {
    super();
    this.ip = this.createField("", groups.PRIMARY, "Ip Address", Joi.string().ip({ cidr: "forbidden" }).required());
    this.port = this.createField(
      "554",
      groups.PRIMARY,
      "Port",
      Joi.string()
        .regex(/^[0-9-]+$/)
        .allow("")
    );
    this.fullscan = this.createField(false, groups.PRIMARY, "Full Scan (-Pn)", Joi.boolean());
  }
}

export default NmapModel;

import { Button, Col, Container, Form, FormGroup, FormText, Row } from "reactstrap";
import PageContainer from "../../components/common/PageContainer";
import { useMemo, useRef, useState } from "react";
import FavorFactorTable from "./FavorFactorTable";
import { readString } from "react-papaparse";
import { favorFactorRowToUpdateRequest, isFavorFactorRowValid } from "./validator_utils";

export default function UpdateFavorFactorPage() {
  const [headers, setHeaders] = useState(undefined);
  const [rows, setRows] = useState(undefined);
  const fileReader = useMemo(() => {
    return new FileReader();
  }, []);

  fileReader.onload = (event) => {
    const text = event.target.result;
    csvFileToArray(text);
  };

  // Create a reference to the hidden file input element
  const hiddenFileInputRef = useRef(null);

  const handleOnChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      fileReader.readAsText(selectedFile);
    }
  };

  const onUploadClicked = () => {
    hiddenFileInputRef.current.click();
  };

  const csvFileToArray = (fileContent) => {
    const { data: readRows } = readString(fileContent);
    const updateRows = [];

    const readHeaders = [];
    readRows[0].forEach((header) => {
      readHeaders.push(header);
    });

    for (let i = 1; i < readRows.length; i++) {
      const updateRequest = favorFactorRowToUpdateRequest(readRows[i]);
      const isValid = isFavorFactorRowValid(readRows[i]);

      const updateRow = Object.keys(updateRequest).map((key) => ({
        value: updateRequest[key],
        className: isValid ? "" : "bg-red-900 text-white font-weight-bold",
      }));
      updateRows.push(updateRow);
    }

    setRows(updateRows);
    setHeaders(readHeaders);
  };

  return (
    <PageContainer>
      <Container className="border" fluid>
        <Row>
          <Col md={3}>
            <Form>
              <FormGroup>
                <input
                  id="fileInput"
                  name="file"
                  type="file"
                  accept=".csv"
                  ref={hiddenFileInputRef}
                  onChange={handleOnChange}
                  style={{ display: "none" }}
                />
                <Button onClick={onUploadClicked}>Upload CSV file</Button>
                <FormText>Only valid CSV file for favor factor update will be accepted.</FormText>
              </FormGroup>
            </Form>
          </Col>
        </Row>

        {rows && headers && (
          <Row className="p-2.5">
            <Col md={12}>
              <FavorFactorTable initialUpdateRows={rows} headers={headers} />
            </Col>
          </Row>
        )}
      </Container>
    </PageContainer>
  );
}

import { buildWebStorage, setupCache } from "axios-cache-interceptor";
import responseErrorsInterceptor from "../components/shared/network/interceptors/responses";
import requestHeadersInterceptor from "../components/shared/network/interceptors/requests";
import AxiosFactory from "../components/shared/network/axios-factory";

const axiosInstance = AxiosFactory.newInstance();

const http = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  patch: axiosInstance.patch,
  delete: axiosInstance.delete,
};

const axiosCacheInstance = setupCache(AxiosFactory.newInstance(), {
  cache: {
    ttl: 1000 * 60 * 5, // 5 minutes
  },
  storage: buildWebStorage(sessionStorage, "axios-cache:"),
});

axiosCacheInstance.interceptors.response.use(null, responseErrorsInterceptor);
axiosCacheInstance.interceptors.request.use(requestHeadersInterceptor);

export const cachedHttp = {
  get: axiosCacheInstance.get,
};

export default http;

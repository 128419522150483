import Model from "./Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };
class GchatModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number());
    this.space_id = this.createField(null, groups.PRIMARY, "Space Id", Joi.string().required().max(18));
    this.space_name = this.createField(null, groups.PRIMARY, "Space Name", Joi.string());
    this.bot = this.createField(null, groups.PRIMARY, "Bot", Joi.number().required());
  }
}

export default GchatModel;

import React from "react";
import { Link } from "react-router-dom";

function AuthenticationError() {
  return (
    <div className="flex items-center h-screen justify-center">
      <div className="bg-neutral-900 p-4 rounded-md text-center">
        <div className="text-xl">An error occurred during authentication</div>
        <Link to="/">
          <button type="button" className="bg-neutral-800 text-gray-200 p-2 rounded-md mt-2">
            Back to Home
          </button>
        </Link>
      </div>
    </div>
  );
}

export default React.memo(AuthenticationError);

import Model from "../Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class ConnectDvrInterfaceModel extends Model {
  constructor() {
    super();
    this.dvr_ip = this.createField(
      "",
      groups.PRIMARY,
      "DVR IP Address",
      Joi.string().ip({ cidr: "forbidden" }).required()
    );
    this.port = this.createField(80, groups.PRIMARY, "Port", Joi.number().required());
  }
}

export default ConnectDvrInterfaceModel;

import http from "./httpService";

const apiEndPoint = "/locations/channels/async/screenshot/";

function getUrl(serverIp) {
  return `${apiEndPoint}${serverIp}`;
}

function random(min, max) {
  const rand = Math.random() * (max - min) + min;
  return rand;
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function triggerScreenshot(serverIp, channel, data) {
  const body = { ...data };

  Object.keys(body).forEach(function (key) {
    if (body[key] === "" || typeof body[key] === "undefined") body[key] = null;
  });
  body.channel = channel;
  await timeout(random(0, 3000));
  return http.post(getUrl(serverIp), body);
}

export const fetchScreenshot = async function (serverIp, statePoint, videoRecorderId, channelNumber, storeLocation) {
  await timeout(random(0, 2000));

  const params = {
    state_point: statePoint,
    store_location: `${storeLocation}_${videoRecorderId}_${channelNumber}`,
  };
  let response = await http.get(getUrl(serverIp), { params });

  while (response.status === 200 && response.data.status === "running") {
    await timeout(random(1000, 3000));
    response = await http.get(getUrl(serverIp), { params });
  }

  if (response.status !== 200) {
    return {
      status: "failed",
      data_uri: "",
    };
  }
  return response.data;
};

import { useEffect } from "react";

/** A hook that redirects to the given url. */
export default function useRedirect(url) {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return [url];
}

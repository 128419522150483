import { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import PropTypes from "prop-types";

function TooltipButton({
  id,
  tooltipLabel,
  onClick = () => {},
  disabled = false,
  placement = "top",
  className = "col align-self-end",
  color = "success",
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Button className={className} id={`tooltip-${id}`} color={color} disabled={disabled} onClick={onClick} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={`tooltip-${id}`} toggle={toggle}>
        {tooltipLabel}
      </Tooltip>
    </>
  );
}

TooltipButton.propTypes = {
  id: PropTypes.string.isRequired,
  tooltipLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  color: PropTypes.string,
  className: PropTypes.string,
};

export default TooltipButton;

import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import ScanNetworkModel from "../../models/remotecontrol/ScanNetworkModel";

class ScanNetworkForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  scanNetwork = new ScanNetworkModel();
  schema = this.scanNetwork.getSchema();

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  componentDidMount() {
    // TODO: Add validation on network
    const data = this.scanNetwork.getDefaultData();
    this.setState({ data });
  }

  render() {
    return (
      <Form>
        <Row>
          <Col md="6">{this.renderInput("network", "Subnetwork")}</Col>
          <Col md="6">{this.renderInput("port", "Port")}</Col>

          <Col md="12">
            <Button className="float-right btn-success m-2" onClick={this.handleSubmit}>
              <i className="fas fa-search mr-2" />
              Scan network
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default ScanNetworkForm;

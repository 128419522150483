import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import Model from "../../models/Model";

class HardwareSpecificationsForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  model = new Model();
  schema = this.model.getSchema();

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  componentDidMount() {
    const data = this.model.getDefaultData();
    this.setState({ data });
  }

  render() {
    return (
      <Form>
        <Row>
          <Col md="12">
            <Button className="float-right btn-success m-2" onClick={this.handleSubmit}>
              <i className="fas fa-cog mr-2" />
              Get hardware specifications
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default HardwareSpecificationsForm;

import React from "react";
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col, Button } from "reactstrap";

import { addNotifier } from "../../services/notifierService";
import BaseForm from "../common/BaseForm";
import GchatList from "../gchat/GchatList";
import WatcherList from "../watcher/WatcherList";
class NotifierForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  componentDidMount() {
    const { notifiers, store } = this.props;
    let data = {};
    if (notifiers) {
      data = { ...notifiers };
      Object.keys(notifiers).forEach(function (key) {
        if (notifiers[key].gchats && notifiers[key].name === "gchat") {
          data.gchats = notifiers[key].gchats;
          data.gchatNotifier = notifiers[key];
        }
        if (notifiers[key].watchers && notifiers[key].name === "watcher") {
          data.watchers = notifiers[key].watchers;
          data.watcherNotifier = notifiers[key];
        }
      });
    } else {
      data.store = store.id;
    }
    this.setState({ data });
  }

  addGchat = async () => {
    const { data } = this.state;
    const { data: notifier } = await addNotifier({ name: "gchat", store: this.props.store.id });
    data.gchats = [];
    data.gchatNotifier = notifier;
    this.setState({ data });
  };

  addVeesionWatcher = async () => {
    const { data } = this.state;
    const { data: notifier } = await addNotifier({ name: "watcher", store: this.props.store.id });
    data.watchers = [];
    data.watcherNotifier = notifier;
    this.setState({ data });
  };

  render() {
    const className = "notifier";
    const { gchats, gchatNotifier } = this.state.data;
    const { watchers, watcherNotifier } = this.state.data;
    const { store, onSaveNotifier } = this.props;

    return (
      <div>
        <Card className={className}>
          <CardHeader>
            <CardTitle tag="h4">Alert Notifiers</CardTitle>
          </CardHeader>
          <CardBody>
            {gchats && (
              <GchatList gchats={gchats} notifier={gchatNotifier} onSaveNotifier={onSaveNotifier} store={store} />
            )}
            {watchers && (
              <WatcherList
                watchers={watchers}
                notifier={watcherNotifier}
                onSaveNotifier={onSaveNotifier}
                store={store}
              />
            )}
          </CardBody>

          <CardFooter>
            <Row>
              {!gchatNotifier && (
                <Col className="px-md-3" md="2">
                  <Button className="float-left" onClick={this.addGchat}>
                    <i className="fas fa-plus mr-2" />
                    Add Google chat
                  </Button>
                </Col>
              )}
              {!watcherNotifier && (
                <Col className="px-md-3" md="2">
                  <Button className="float-left" onClick={this.addVeesionWatcher}>
                    <i className="fas fa-plus mr-2" />
                    Add Veesion Watcher
                  </Button>
                </Col>
              )}
            </Row>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default NotifierForm;

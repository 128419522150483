import * as Sentry from "@sentry/react";
import useFetch from "../../../components/shared/hooks/api/fetch";
import { getStoresHistoriesSummaryPaginatedEndpoint } from "../../../services/history/storeHistoryService";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import PageContainer from "../../../components/common/PageContainer";
import React from "react";
import StoreChangesTable from "./StoreChangesTable";
import NoDataAvailable from "../../../components/common/NoDataAvailable";

/**
 * A component that displays the list of changes (by date) for one store.
 *
 * @returns {JSX.Element}
 * @constructor
 */
function StoreHistory() {
  const history = useHistory();
  const { storeLocation } = useParams();
  const [storeHistories] = useFetch(getStoresHistoriesSummaryPaginatedEndpoint(storeLocation));

  const navigateBack = () => {
    history.go(-1);
  };

  if (storeHistories === null) {
    return <NoDataAvailable />;
  }

  return (
    <PageContainer>
      <Container fluid>
        <Card>
          <CardBody>
            <Row>
              <Col md={{ size: 2 }}>
                <Button onClick={navigateBack}>Go back</Button>
              </Col>
            </Row>
            <Row>
              <StoreChangesTable storeLocation={storeLocation} storeHistories={storeHistories} />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </PageContainer>
  );
}

export default Sentry.withProfiler(StoreHistory);

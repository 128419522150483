import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import Pagination from "../components/common/pagination";
import StoresTable from "../components/StoresTable";
import { getStoreByLocation, getStores } from "../services/storeService";
import { Link } from "react-router-dom";
import DisplayWhen from "../components/common/base/DisplayWhen";
import userHasRole from "../components/shared/utils/authUtils";
import { editorRole, qualityRole } from "../components/shared/constants";
import * as Sentry from "@sentry/react";

class StoresView extends React.Component {
  state = {
    stores: [],
    currentPage: 1,
    count: 0,
    pageSize: 1,
    highLight: false,
    query: "",
  };

  timer = null;

  async componentDidMount() {
    await this.fetchStores();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { query, currentPage } = this.state;
    if (prevState.query !== query) {
      if (query === "" || query.length > 2) this.timer = setTimeout(() => this.fetchStores(), 500);
    } else if (prevState.currentPage !== currentPage) {
      await this.fetchStores();
    }
  }

  fetchStores = async () => {
    const { currentPage, query } = this.state;
    try {
      const { data: pagedStores } = await getStores(currentPage, query);
      const stores = pagedStores.results;
      const { count } = pagedStores;

      let pageSize = 1;
      if (pagedStores.next) {
        pageSize = stores.length;
      } else {
        pageSize = Math.ceil(count / currentPage);
      }

      this.setState({ stores, count, pageSize });
    } catch (ex) {
      console.log(ex);
      toast.error("Failed to connect, please log in");
    }
  };

  handleFindStore = async () => {
    const { query, stores } = this.state;
    const location = query;

    try {
      const { data: store } = await getStoreByLocation(location);
      const originalStores = stores;
      let filteredStores = originalStores.filter((originalStore) => originalStore.id !== store.id);
      filteredStores = [store, ...filteredStores];
      const highLight = true;
      this.setState({ stores: filteredStores, highLight });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Store not found");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      } else {
        toast.error("Unhandled error");
      }
    }
  };

  handleChange = (value) => {
    clearTimeout(this.timer);
    this.setState({ query: value, currentPage: 1 });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { count, pageSize, currentPage, stores, highLight } = this.state;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Stores</CardTitle>
                <Row>
                  <Col md="4">
                    <InputGroup>
                      <Input
                        name="find_store"
                        id="find_store"
                        placeholder="Store location"
                        onChange={(e) => this.handleChange(e.currentTarget.value)}
                      />
                      <InputGroupAddon addonType="append">
                        <Button className="input-group-button" onClick={this.handleFindStore}>
                          Find store
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>

                  <DisplayWhen condition={userHasRole(editorRole) && !userHasRole(qualityRole)}>
                    <Col md="4">
                      <Link to="/stores/new">
                        <Button>Add new store</Button>
                      </Link>
                    </Col>
                  </DisplayWhen>
                </Row>
              </CardHeader>
              <CardBody>
                <StoresTable stores={stores} highLight={highLight} />
                <Pagination
                  itemsCount={count}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Sentry.withProfiler(StoresView);

import Model from "../Model";
import Joi from "joi-browser";

const groups = { PRIMARY: "primary" };

class NetworkCheckModel extends Model {
  constructor() {
    super();
    this.internet = this.createField(false, groups.PRIMARY, "Internet", Joi.boolean().required());
    this.duplicate = this.createField(false, groups.PRIMARY, "Duplicate", Joi.boolean().required());
    this.dns = this.createField(false, groups.PRIMARY, "DNS", Joi.boolean().required());
    this.ntp = this.createField(false, groups.PRIMARY, "NTP", Joi.boolean().required());
    this.veesion = this.createField(false, groups.PRIMARY, "Veesion", Joi.boolean().required());
    this.aws = this.createField(false, groups.PRIMARY, "AWS", Joi.boolean().required());
    this.apt = this.createField(false, groups.PRIMARY, "APT", Joi.boolean().required());
    this.wireguard = this.createField(false, groups.PRIMARY, "WireGuard", Joi.boolean().required());
    this.rabbit = this.createField(false, groups.PRIMARY, "RabbitMQ", Joi.boolean().required());
    this.mtu = this.createField(false, groups.PRIMARY, "MTU", Joi.boolean().required());
    this.speedtest = this.createField(false, groups.PRIMARY, "Speedtest", Joi.boolean().required());
  }
}

export default NetworkCheckModel;

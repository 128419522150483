import React, { Component } from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import VideoRecorderForm from "../Forms/VideoRecorderForm";
import ChannelsTable from "../channels/ChannelsTable";
import "./VideoRecorder.scss";

class VideoRecorder extends Component {
  state = { showChannels: false, editMode: false, deleteMode: false };

  toggleChannels = () => {
    const { showChannels } = this.state;
    this.setState({ showChannels: !showChannels });
  };

  toggleEditMode = () => {
    const { editMode } = this.state;
    this.setState({ editMode: !editMode });
  };

  toggleDeleteMode = () => {
    const { deleteMode } = this.state;
    this.setState({ deleteMode: !deleteMode });
  };

  render() {
    const { videoRecorder, highlight, updateVideoRecorderList, removeFromVideoRecorderList, toggleAddDevice, storeId } =
      this.props;
    const { showChannels, editMode, deleteMode } = this.state;
    let className = "video-recorder";
    if (deleteMode) className = `${className} danger`;
    else if (videoRecorder && highlight === videoRecorder.id) className = `${className} highlight`;

    return (
      <Card className={className}>
        <CardHeader>
          <CardTitle tag="h4">
            <Row>
              <Col className="device-type" md={6}>
                <i className="fas fa-hdd mr-2" />
                <span className="device-badge mr-2">
                  {videoRecorder && videoRecorder.video_recorder_type_name}
                  <p>{videoRecorder && `${videoRecorder.device_ip}:${videoRecorder.device_rtsp_port}`}</p>
                  {!videoRecorder && "Add new video recorder"}
                </span>
              </Col>
              <Col className="device-badge" md={6}>
                {videoRecorder && (
                  <Button className="float-right" onClick={this.toggleEditMode}>
                    {editMode ? "Collapse" : "Expand"}
                  </Button>
                )}
              </Col>
            </Row>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {editMode && (
            <VideoRecorderForm
              videoRecorder={videoRecorder}
              updateVideoRecorderList={updateVideoRecorderList}
              removeFromVideoRecorderList={removeFromVideoRecorderList}
              toggleDeleteMode={this.toggleDeleteMode}
              deleteMode={deleteMode}
            />
          )}
          {!videoRecorder && (
            <VideoRecorderForm
              videoRecorder={videoRecorder}
              toggleAddDevice={toggleAddDevice}
              storeId={storeId}
              updateVideoRecorderList={updateVideoRecorderList}
              removeFromVideoRecorderList={removeFromVideoRecorderList}
            />
          )}
          <hr style={{ borderColor: "gray", marginTop: 0 }} />
          {videoRecorder && (
            <Row>
              <Col className="device-channels" md={6}>
                <i className="fas fa-video mr-2" />
                <span className="mr-2">Channels</span>
              </Col>
              <Col className="device-badge" md={6}>
                <Button onClick={this.toggleChannels} className="float-right">
                  {showChannels ? "Hide" : "Show"} channels
                </Button>
              </Col>
            </Row>
          )}
          {videoRecorder && showChannels && (
            <ChannelsTable
              channels={videoRecorder.channels}
              storeId={videoRecorder.store}
              videoRecorderId={videoRecorder.id}
              canHaveSubChannels={videoRecorder.video_recorder_type_name === "IP"}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

export default VideoRecorder;

import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import PageContainer from "../../components/common/PageContainer";
import { Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import StoresTab from "./stores/StoresTab";
import React, { useState } from "react";
import UsersTab from "./users/UsersTab";
import {
  adminEditorRole,
  adminViewerRole,
  csmMaintenanceRole,
  csmRole,
  technicianRole,
  techopsRole,
} from "../../components/shared/constants";
import userHasRole from "../../components/shared/utils/authUtils";
import DisplayWhen from "../../components/common/base/DisplayWhen";

/**
 * A component that display all the stores that have history.
 *
 * @param page
 * @returns {JSX.Element}
 * @constructor
 */
function HistoryView({ selectedTab = "stores-tab" }) {
  const [activeTab, setActiveTab] = useState(selectedTab);

  const canSeeStoresTab =
    userHasRole(adminEditorRole) ||
    userHasRole(adminViewerRole) ||
    userHasRole(csmRole) ||
    userHasRole(csmMaintenanceRole) ||
    userHasRole(technicianRole) ||
    userHasRole(techopsRole);
  const canSeeUsersTab = userHasRole(adminEditorRole) || userHasRole(adminViewerRole) || userHasRole(techopsRole);

  const selectTab = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <PageContainer>
      <Container fluid>
        <Card>
          <CardBody>
            <Nav tabs>
              <DisplayWhen condition={canSeeStoresTab}>
                <NavItem>
                  <NavLink
                    className={activeTab === "stores-tab" ? "active" : ""}
                    onClick={() => {
                      selectTab("stores-tab");
                    }}
                  >
                    <p>Stores</p>
                  </NavLink>
                </NavItem>
              </DisplayWhen>
              <DisplayWhen condition={canSeeUsersTab}>
                <NavItem>
                  <NavLink
                    className={activeTab === "users-tab" ? "active" : ""}
                    onClick={() => {
                      selectTab("users-tab");
                    }}
                  >
                    <p>Users</p>
                  </NavLink>
                </NavItem>
              </DisplayWhen>
            </Nav>
            <TabContent activeTab={activeTab}>
              <DisplayWhen condition={canSeeStoresTab}>
                <TabPane tabId="stores-tab">
                  <StoresTab />
                </TabPane>
              </DisplayWhen>
              <DisplayWhen condition={canSeeUsersTab}>
                <TabPane tabId="users-tab">
                  <UsersTab />
                </TabPane>
              </DisplayWhen>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </PageContainer>
  );
}

HistoryView.propTypes = {
  selectedTab: PropTypes.string,
};

export default Sentry.withProfiler(HistoryView);

import "./App.css";
import "./index.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/dark-matter.scss";

import Home from "./layouts/home";
import * as Sentry from "@sentry/react";
import LoginCallback from "./components/common/base/LoginCallback";
import AuthenticationError from "./components/common/errors/AuthenticationError";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/login/error" component={AuthenticationError} />
        <Route path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);

import Model from "./Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class OpeningHourModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number());
    this.store = this.createField(null, groups.PRIMARY, "store", Joi.number());
    this.day = this.createField("monday", groups.PRIMARY, "Day", Joi.string());

    this.time_open = this.createField("08:00:00", groups.PRIMARY, "Opening Hour", Joi.string().allow(""));

    this.time_close = this.createField("22:00:00", groups.PRIMARY, "Closing Hour", Joi.string().allow(""));
    this.is_open = this.createField(true, groups.PRIMARY, "Is open", Joi.boolean());
  }
}

export default OpeningHourModel;

import { useEffect, useState } from "react";
import http from "../../../../services/httpService";

/** A hook to fetch data at the given url. */
export default function useFetch(url) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const { data: json } = await http.get(url);
      if (!ignore) {
        setData(json);
        setIsLoading(false);
      }
      return json;
    }

    setIsLoading(true);
    fetchData().catch(console.error);

    return () => {
      ignore = true;
      setIsLoading(false);
    };
  }, [setData, url]);

  return [data, { isLoading }];
}

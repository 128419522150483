import React from "react";
import { Row } from "reactstrap";

function PageContainer({ children }) {
  return (
    <div className="content">
      <Row>{children}</Row>
    </div>
  );
}

export default React.memo(PageContainer);

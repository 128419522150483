import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, FormGroup, Label, InputGroup, InputGroupAddon } from "reactstrap";
import WatcherForm from "../Forms/WatcherForm";
import { addWatcherCredentials } from "../../services/WatcherCredentialsService";
import { toast } from "react-toastify";
import DisplayWhen from "../common/base/DisplayWhen";
import { technicianRole, adminEditorRole } from "../shared/constants";
import userHasRole from "../shared/utils/authUtils";

/** A functional component to render and edit watcher details. */
const Watcher = function ({
  editMode: initialEditMode,
  watcher,
  notifier,
  onSaveNotifier,
  store,
  updateWatcherList,
  removeFromWatcherList,
  showNewWatcher,
}) {
  const [editMode, setEditMode] = useState(false);
  const [credentials, setCredentials] = useState({ username: "", password: "" });
  const [showCredentials, setShowCredentials] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility

  useEffect(() => {
    setEditMode(initialEditMode);
  }, [initialEditMode]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const addCredential = () => {
    const storeLocation = store.location;

    addWatcherCredentials(storeLocation, {
      watcher_version: watcher.watcher_version,
    })
      .then((response) => {
        setCredentials({ username: response.data.username, password: response.data.password });
        setShowCredentials(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail || "An error occurred while generating credentials");
        } else {
          toast.error("An unexpected error occurred");
        }
        console.error("Error generating credentials:", error);
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!");
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  return (
    <div className="w-full flex items-center flex-column">
      <Row className="w-full">
        {!editMode && watcher && (
          <Col className="px-3" md="2">
            <label className="block">Watcher version</label>
            <p className="whitespace-nowrap">{watcher.watcher_version}</p>
          </Col>
        )}

        {editMode && (
          <WatcherForm
            watcher={watcher}
            notifier={notifier}
            onSaveNotifier={onSaveNotifier}
            store={store}
            updateWatcherList={updateWatcherList}
            toggleEditMode={toggleEditMode}
            removeFromWatcherList={removeFromWatcherList}
            showNewWatcher={showNewWatcher}
          />
        )}
        {!showNewWatcher && (
          <Row className="px-7">
            <Col md="auto">
              <Button className="ml-2 mt-2" onClick={toggleEditMode}>
                <i className="fas fa-edit mr-2" />
                {editMode ? "Cancel" : "Edit"}
              </Button>
            </Col>
            <Col md="auto">
              <DisplayWhen condition={userHasRole(technicianRole) || userHasRole(adminEditorRole)}>
                <Button className="ml-2 mt-2" onClick={addCredential}>
                  <i className="fas fa-key mr-2" />
                  Generate credentials
                </Button>
              </DisplayWhen>
            </Col>
          </Row>
        )}
      </Row>

      {/* Show credentials fields only if they are generated */}
      {showCredentials && (
        <Row className="w-full mt-4">
          <Col md="4">
            <FormGroup>
              <Label for="username">Username</Label>
              <InputGroup>
                <Input type="text" id="username" value={credentials.username} readOnly />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={() => copyToClipboard(credentials.username)}>
                    <i className="fas fa-copy" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label for="password">Password</Label>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  id="password"
                  value={credentials.password}
                  readOnly
                />
                <InputGroupAddon addonType="append">
                  <InputGroupAddon addonType="append">
                    <Button color="secondary" onClick={toggleShowPassword}>
                      <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"} />
                    </Button>
                  </InputGroupAddon>
                  <Button color="secondary" onClick={() => copyToClipboard(credentials.password)}>
                    <i className="fas fa-copy" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Watcher;

import http from "./httpService";

const apiEndPoint = "/globalstreamconfigs/";

function getUrl() {
  return `${apiEndPoint}`;
}

export function getGlobalStreamConfigs() {
  return http.get(apiEndPoint);
}

export function updateGlobalStreamConfig(streamConfig) {
  return http.post(getUrl(), streamConfig);
}

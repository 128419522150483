import Cookies from "js-cookie";

const apiTokenKey = "SESSION_TOKEN";

const requestHeadersInterceptor = (config) => {
  const apiToken = Cookies.get(apiTokenKey);
  const updatedConfig = config;
  if (apiToken) {
    updatedConfig.headers.Authorization = `Bearer ${apiToken}`;
  }

  return updatedConfig;
};

export default requestHeadersInterceptor;

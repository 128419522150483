import React from "react";

/**
 * A React component that renders its children only if a certain condition is true.
 *
 * @param {boolean} props.condition - The condition that determines whether the children should be rendered or not.
 * @param {React.ReactNode} props.children - The child elements that will be rendered if the condition is true.
 * @returns {React.ReactElement | ReactFragment} - The rendered React element or empty ReactFragment
 *  if the condition is false.
 */
function DisplayWhen({ condition = false, children }) {
  if (condition) {
    return children;
  }
  return null;
}

export default React.memo(DisplayWhen);

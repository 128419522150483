import getArrayDepth from "../utils/arrayUtils";
import http from "./httpService";

const apiEndPoint = "/channels/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function getChannels() {
  return http.get(apiEndPoint);
}

function cleanIndex(data, key) {
  const value = parseInt(data[key]);
  if (value === 0) return null;
  if (value === 1) return true;
  if (value === 2) return false;
  return value;
}

function cleanPolygons(data, key) {
  let polygons = data[key];
  if (!polygons) {
    return [];
  }
  const polygonsDepth = getArrayDepth(polygons);
  if (polygonsDepth === 2) {
    polygons = [polygons];
  }
  polygons = polygons.map((polygon) => polygon.map(([x, y]) => [parseInt(x), parseInt(y)]));
  return polygons;
}

function cleanResolution(data, key) {
  const resolution = data[key];
  if (!resolution || !resolution.width || !resolution.height) {
    return null;
  }
  return { width: parseInt(resolution.width), height: parseInt(resolution.height) };
}

export function getChannel(channelId) {
  return http.get(getUrl(channelId));
}

export async function saveChannel(channel) {
  const body = { ...channel };
  if (body.recheckings === "") body.recheckings = null;
  Object.keys(body).forEach(function (key) {
    if (body[key] === "") delete body[key];
  });
  body.view_area = cleanPolygons(body, "view_area");
  body.original_resolution = cleanResolution(body, "original_resolution");
  body.inference_machine = parseInt(body.inference_machine);
  body.favor_factor = parseFloat(body.favor_factor) === 0 ? null : body.favor_factor;
  body.alert_channel = parseInt(body.alert_channel) === 0 ? null : body.alert_channel;
  body.relevance = parseInt(body.relevance) === 0 ? null : body.relevance;
  body.depth_of_field = parseInt(body.depth_of_field) === 0 ? null : body.depth_of_field;

  body.no_suspicious = cleanIndex(body, "no_suspicious");
  body.no_suspicious_bag = cleanIndex(body, "no_suspicious_bag");
  body.no_stroller = cleanIndex(body, "no_stroller");
  body.send_client_normal_bag = cleanIndex(body, "send_client_normal_bag");
  body.send_store_normal_bag = cleanIndex(body, "send_store_normal_bag");
  body.send_gesture_into_body = cleanIndex(body, "send_gesture_into_body");

  body.gesture_into_body = cleanIndex(body, "gesture_into_body");
  body.product_in_personal_bag = cleanIndex(body, "product_in_personal_bag");
  body.product_into_backpack = cleanIndex(body, "product_into_backpack");
  body.suspicious_bag = cleanIndex(body, "suspicious_bag");
  body.product_into_stroller = cleanIndex(body, "product_into_stroller");
  body.client_normal_bag = cleanIndex(body, "client_normal_bag");
  body.store_normal_bag = cleanIndex(body, "store_normal_bag");
  body.deblistering = cleanIndex(body, "deblistering");
  body.consumption = cleanIndex(body, "consumption");
  body.burst_shot = cleanIndex(body, "burst_shot");
  body.other_suspicious = cleanIndex(body, "other_suspicious");

  if (!body.inference_machine_data) body.inference_machine_data = {};
  if (body.inference_machine === 0) body.inference_machine = null;
  if (body.id) {
    delete body.id;
    const response = await http.patch(getUrl(channel.id), body);
    return response;
  }
  const response = await http.post(apiEndPoint, body);
  return response;
}

export function deleteChannel(channelId) {
  return http.delete(getUrl(channelId));
}

import http from "./httpService";

const apiEndPoint = "/videorecorders/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function getVideoRecorders() {
  return http.get(apiEndPoint);
}

export function getVideoRecorder(videoRecorderId) {
  return http.get(getUrl(videoRecorderId));
}

export function saveVideoRecorder(videoRecorder) {
  const body = { ...videoRecorder };
  Object.keys(body).forEach(function (key) {
    if (body[key] === "") body[key] = null;
  });
  if (body.id) {
    delete body.id;
    return http.patch(getUrl(videoRecorder.id), body);
  }
  return http.post(apiEndPoint, body);
}

export function deleteVideoRecorder(videoRecorderId) {
  return http.delete(getUrl(videoRecorderId));
}

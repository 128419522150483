import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Modal, ModalBody, Row } from "reactstrap";
import { createIncident, getIncidents } from "../../services/incidentService";
import IncidentForm from "../Forms/IncidentForm";
import Incident from "./Incident";

export default function IncidentList({ store }) {
  const [incidents, setIncidents] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetchIncidents();
  }, []);

  async function fetchIncidents() {
    try {
      const incidentData = await getIncidents(store.location);
      setIncidents(incidentData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }

  function onIncidentCreated(incidentType, machine) {
    toggleModal();
    createIncident(store.location, incidentType, machine).then(() => fetchIncidents());
    toast.info("Incident created");
  }

  function toggleModal() {
    setModal(!modal);
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggleModal} contentClassName="image-modal bg-transparent">
        <ModalBody style={{ borderRadius: "10px", background: "#27293d", width: "700px" }}>
          <IncidentForm onIncidentCreated={onIncidentCreated} store={store} />
        </ModalBody>
      </Modal>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Incidents</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <Row>
              <Col md={12}>
                <Button className="float-right" onClick={toggleModal}>
                  {modal ? "Cancel" : "Add incident"}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={2}>Type</Col>
              <Col md={1}>Status</Col>
              <Col md={2}>Machine</Col>
              <Col md={2}>Created at</Col>
              <Col md={3}>Created by</Col>
            </Row>
            {incidents &&
              incidents.map((incident) => (
                <div>
                  <hr
                    style={{
                      borderColor: "light-grey",
                      height: 0.5,
                    }}
                  />
                  <Incident key={incident.id} incident={incident} store={store} />
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

import http from "./httpService";

const apiEndPoint = "/subchannels/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

export function getSubChannels() {
  return http.get(apiEndPoint);
}

export function getSubChannel(subChannelId) {
  return http.get(getUrl(subChannelId));
}

export function saveSubChannel(subChannel) {
  const body = { ...subChannel };
  Object.keys(body).forEach(function (key) {
    if (body[key] === "") delete body[key];
  });
  body.favor_factor = parseFloat(body.favor_factor) === 0 ? null : body.favor_factor;
  if (body.id) {
    delete body.id;
    return http.patch(getUrl(subChannel.id), body);
  }
  return http.post(apiEndPoint, body);
}

export function deleteSubChannel(subChannelId) {
  return http.delete(getUrl(subChannelId));
}

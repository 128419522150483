import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import getVideoRecorderTypes from "../../../services/videoRecorderTypeService";
import ScreenshotModel from "../../models/remotecontrol/ScreenshotModel";

class ScreenshotForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    deviceTypes: [],
  };
  screenshotModel = new ScreenshotModel();
  schema = this.screenshotModel.getSchema();

  async fetchDeviceTypes() {
    const { data: deviceTypes } = await getVideoRecorderTypes();
    return deviceTypes;
  }

  async componentDidMount() {
    const deviceTypes = await this.fetchDeviceTypes();
    const { videoRecorder } = this.props;
    let data = {};
    if (videoRecorder) {
      data = {
        video_recorder_type_id: videoRecorder.video_recorder_type_id || undefined,
        suffix: videoRecorder.rtsp_url_suffix || undefined,
        username: videoRecorder.username || undefined,
        password: videoRecorder.password || undefined,
        device_ip: videoRecorder.device_ip || undefined,
        device_rtsp_port: videoRecorder.device_rtsp_port || undefined,
        protocol: "tcp",
        subtype: "sub",
      };
    } else {
      data = this.screenshotModel.getDefaultData();
    }
    this.setState({ deviceTypes, data });
  }

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  render() {
    return (
      <Form>
        <Row>
          <Col md="6">{this.renderSelect("video_recorder_type_id", "Device type", this.state.deviceTypes)}</Col>
          <Col md="6">{this.renderInput("suffix", "RTSP URL suffix")}</Col>
        </Row>
        <Row>
          <Col md="6">{this.renderInput("username", "Username")}</Col>
          <Col md="6">{this.renderInput("password", "Password")}</Col>
        </Row>
        <Row>
          <Col md="6">{this.renderInput("device_ip", "Device IP")}</Col>
          <Col md="3">{this.renderInput("device_rtsp_port", "RTSP port")}</Col>
          <Col md="3">
            {this.renderSelect("protocol", "Protocol", [
              { name: "tcp", id: "tcp" },
              { name: "udp", id: "udp" },
            ])}
          </Col>
        </Row>
        <Row>
          <Col md="6">{this.renderInput("channel", "Channel")}</Col>
          <Col md="6">
            {this.renderSelect("subtype", "subtype", [
              { name: "sub", id: "sub" },
              { name: "main", id: "main" },
            ])}
          </Col>
        </Row>
        <Col>
          <Button
            className="float-right btn-success m-2"
            disabled={this.props.isDisabledButton}
            onClick={this.handleSubmit}
          >
            <i className="fa fa-play mr-2" />
            Screenshot
          </Button>
        </Col>
      </Form>
    );
  }
}

export default ScreenshotForm;

import React from "react";
import { uniqueId } from "lodash";
import { Input, FormGroup } from "reactstrap";

function InputControl({ name, label, error, id = uniqueId(`${name}-`), ...rest }) {
  const errorClass = error ? { borderColor: "#ff576c" } : {};
  return (
    <FormGroup>
      {label && <label htmlFor={id}>{label}</label>}
      <Input
        {...rest}
        name={name}
        id={id}
        placeholder={label}
        style={errorClass}
        onWheel={(event) => {
          if (event.target.type === "number") document.activeElement.blur();
        }}
      />
      {error && <div style={{ color: "#ff576c", fontSize: "12px" }}>{error}</div>}
    </FormGroup>
  );
}

export default InputControl;

import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap";

class DiscoverResult extends Component {
  state = {
    stdOut: "",
    stdErr: "",
    status: "",
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      stdOut: nextProps.stdOut,
      stdErr: nextProps.stdErr,
      status: nextProps.status,
    };
  }

  render() {
    const { stdOut, stdErr, status } = this.state;
    const stdOutSplit = stdOut.split("\n");
    const stdErrSplit = stdErr.split("\n");

    return (
      <Row>
        <Col md="6">
          {stdOut && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  Standard Output
                </CardTitle>
              </CardHeader>
              <CardBody>
                {stdOutSplit.map((element, index) => (
                  <CardText key={`stdOut_${index}`}>{element}</CardText>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
        <Col md="6">
          {stdErr && status === "FAILED" && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  Error Output
                </CardTitle>
              </CardHeader>
              <CardBody>
                {stdErrSplit.map((element, index) => (
                  <CardText key={`stdErr_${index}`}>{element}</CardText>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    );
  }
}

export default DiscoverResult;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class SideBar extends Component {
  state = {};

  activeRoute(routeName) {
    const { location } = this.props;
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  render() {
    const { color, image, hasImage, routes } = this.props;
    const sidebarBackground = {
      backgroundImage: `url(${image})`,
    };
    return (
      <div id="sidebar" className="sidebar" data-color={color} data-image={image}>
        {hasImage ? <div className="sidebar-background" style={sidebarBackground} /> : null}
        <div className="logo">
          <a href="/" className="simple-text logo-normal">
            Veesion Manager
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {routes
              .filter((prop) => prop.path !== "scandvr" && prop.path !== "screenshots" && prop.path !== "network")
              .map((prop, key) => {
                if (!prop.redirect && prop.layout === "/")
                  return (
                    <li
                      className={prop.upgrade ? "active active-pro" : this.activeRoute(prop.layout + prop.path)}
                      key={key}
                    >
                      <NavLink to={prop.layout + prop.path} className="sidebar-link" activeClassName="active">
                        <p>
                          <i className={prop.icon} />
                          {prop.name}
                        </p>
                      </NavLink>
                    </li>
                  );
                return null;
              })}
          </ul>
        </div>
        <div className="sidebar-footer">
          <span>
            {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}
          </span>
        </div>
      </div>
    );
  }
}

export default SideBar;

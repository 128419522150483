import { Button, Col, Container, Row } from "reactstrap";
import PageContainer from "../../components/common/PageContainer";
import React, { useEffect, useMemo, useState } from "react";
import { batchUpdateFavorFactor } from "../../services/UpdateFavorFactorService";
import LogMessage from "./LogMessage";
import DisplayWhen from "../../components/common/base/DisplayWhen";
import Loader from "react-js-loader";
import PropTypes from "prop-types";
import Spreadsheet from "react-spreadsheet";
import {
  favorFactorRowToUpdateRequest,
  isFavorFactorRowValid,
  sliceIntoChunks,
  spreadsheetRowToUpdateRow,
  updateRowToFavorFactorRow,
} from "./validator_utils";
import { debounce } from "lodash";
import * as Sentry from "@sentry/react";

function FavorFactorTable({ headers, initialUpdateRows }) {
  const [logs, setLogs] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const chunkSize = 5;

  const computeValidRows = (rows) => {
    const fixedRows = rows.map((row) => {
      const updateRow = spreadsheetRowToUpdateRow(row);
      const updateRequest = favorFactorRowToUpdateRequest(updateRow);
      const isValid = isFavorFactorRowValid(updateRow);

      return Object.keys(updateRequest).map((key) => ({
        value: updateRequest[key],
        className: isValid ? "" : "bg-red-900 text-white font-weight-bold",
      }));
    });
    setUpdateRows(fixedRows);
  };

  /**
   * Debounce of updates of cell changes.
   *
   * The purpose of using a debounced function is to limit the number of times computeValidRows function is called
   * during a specific time period (300ms).
   *
   * This improves the performance of the application by reducing the number of unnecessary calculations.
   *
   * @type {DebouncedFunc<computeValidRows>}
   */
  const debouncedOnCellChanged = useMemo(() => debounce(computeValidRows, 300), []);

  useEffect(() => {
    setUpdateRows([...initialUpdateRows]);
    setCanUpdate(true);
  }, [initialUpdateRows]);

  useEffect(() => {
    return () => {
      debouncedOnCellChanged.cancel();
    };
  }, [debouncedOnCellChanged]);

  const updateFavorFactor = async () => {
    // Find invalid items
    const invalidUpdateRequestIndexes = updateRows.reduce((prevValue, item, index) => {
      const favorFactorRow = updateRowToFavorFactorRow(item);
      return !isFavorFactorRowValid(favorFactorRow) ? prevValue.concat(index) : prevValue;
    }, []);

    // Delete invalid items
    const validSpreadsheetRows = updateRows.slice();
    for (let i = invalidUpdateRequestIndexes.length - 1; i >= 0; i--) {
      validSpreadsheetRows.splice(invalidUpdateRequestIndexes[i], 1);
    }

    const validUpdateRequests = validSpreadsheetRows.map((item) =>
      favorFactorRowToUpdateRequest(spreadsheetRowToUpdateRow(item))
    );

    const updateRequestsBatches = sliceIntoChunks(validUpdateRequests, chunkSize);

    setIsLoading(true);
    setLogs([]);
    // can't have an await inside a forEach, forced for loop
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, updateRequestsBatch] of updateRequestsBatches.entries()) {
      const fromMessage = `rows ${index * chunkSize + 1} to ${index * chunkSize + updateRequestsBatch.length}`;

      try {
        setLogs((currentLogs) => [
          ...currentLogs,
          { message: `⇢ [Starting batch updates of item(s) from ${fromMessage}]`, type: "info" },
        ]);
        const { data: updateResponses } = await batchUpdateFavorFactor(updateRequestsBatch);
        const newLogs = updateResponses.flatMap((updateResponse) => updateResponse.logs);

        setLogs((currentLogs) => [...currentLogs, ...newLogs]);
      } catch (error) {
        setLogs((currentLogs) => [
          ...currentLogs,
          {
            message: `An error occurred while updating ${fromMessage}.: ${error.message || "No error message"}`,
            type: "error",
          },
        ]);
      } finally {
        setLogs((currentLogs) => [
          ...currentLogs,
          { message: `⇠ [Ending batch updates of item(s) from ${fromMessage}]`, type: "info", lineBreak: true },
        ]);
      }
    }
    setIsLoading(false);
    setCanUpdate(false);
  };

  return (
    <PageContainer>
      <Container fluid>
        <Row>
          <div>
            <Spreadsheet
              data={updateRows}
              onChange={debouncedOnCellChanged}
              columnLabels={headers}
              data-testid="spreadsheet"
            />
          </div>
        </Row>
        <Row>
          <Col>
            <Button onClick={updateFavorFactor} disabled={isLoading || !canUpdate}>
              Launch update
            </Button>
          </Col>
        </Row>
        <Row className={logs && logs.length > 0 ? "bg-black p-2.5" : ""}>
          <Col md={12}>
            {logs &&
              logs.map((log, index) => {
                return (
                  <LogMessage
                    key={`log-msg-${index}`}
                    message={log.message}
                    logLevel={log.type}
                    lineBreak={Object.prototype.hasOwnProperty.call(log, "lineBreak") && log.lineBreak}
                  />
                );
              })}
          </Col>
          <DisplayWhen condition={isLoading}>
            <Col md={12}>
              <Loader type="bubble-scale" bgColor="#FFFFFF" title="loading" size={50} />
            </Col>
          </DisplayWhen>
        </Row>
      </Container>
    </PageContainer>
  );
}

FavorFactorTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  initialUpdateRows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    )
  ),
};

export default Sentry.withProfiler(FavorFactorTable);

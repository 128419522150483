import React from "react";
import { toast } from "react-toastify";

import VideoRecorderScreenshot from "./videorecorders/VideoRecorderScreenshot";
import getVideoRecordersList from "../services/videoRecorderListService";

class StoreScreenshots extends React.Component {
  state = {
    videoRecorders: null,
    storeLocation: null,
  };

  componentDidMount() {
    const { location, videoRecorders } = this.props;
    this.setState({ storeLocation: location, videoRecorders });
  }

  async componentDidUpdate(prevProps) {
    const prevStoreLocation = prevProps.location;
    const { location } = this.props;
    if (prevStoreLocation !== location) {
      await this.fetchVideoRecorders(location);
    }
  }

  async fetchVideoRecorders(storeLocation) {
    try {
      const { data: videoRecorders } = await getVideoRecordersList(storeLocation);
      this.setState({ videoRecorders });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Unable to fetch video recorders list");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      } else {
        toast.error("Unhandled error");
      }
    }
  }

  render() {
    const { videoRecorders, storeLocation } = this.state;
    const { serverIp } = this.props;
    return (
      <div className="content">
        {videoRecorders &&
          videoRecorders.map((videoRecorder, index) => (
            <VideoRecorderScreenshot
              key={videoRecorder.id}
              index={index + 1}
              videoRecorder={videoRecorder}
              channels={videoRecorder.channels}
              serverIp={serverIp}
              storeLocation={storeLocation}
            />
          ))}
      </div>
    );
  }
}

export default StoreScreenshots;

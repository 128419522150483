import Model from "../Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class ArpScanModel extends Model {
  constructor() {
    super();
    this.interface = this.createField(0, groups.PRIMARY, "Interface", Joi.number().required());
  }
}

export default ArpScanModel;

import React, { Component } from "react";

class NetworkView extends Component {
  state = {};
  render() {
    return (
      <div className="content">
        <h1>Network status and configs</h1>
      </div>
    );
  }
}

export default NetworkView;

import { toast } from "react-toastify";

const responseErrorsInterceptor = (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
  if (!expectedError) {
    toast.error("Unexpected error!");
  }
  return Promise.reject(error);
};

export default responseErrorsInterceptor;

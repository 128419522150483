import "../components/store/store.scss";
import React from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Button } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

import StoreScreenshots from "../components/StoreScreenshots";
import StoreScanVideoRecorder from "../components/StoreScanVideoRecorder";
import { getServerIp } from "../services/locationService";
import { trigggerBulkScreenshots, fetchBulkScreenshots } from "../services/bulkScreenshotsService";
import getVideoRecordersList from "../services/videoRecorderListService";
import { getStoreByLocation } from "../services/storeService";
import downloadFile from "../utils/downloadUtils";

class StoreScreenshotToolsView extends React.Component {
  state = {
    storeLocation: null,
    storeId: null,
    currentCard: "screenshots",
    videoRecorders: null,
    screenshotState: null,
    serverIp: null,
  };

  async componentDidMount() {
    const { match } = this.props;
    const storeLocation = match.params.location;
    await getStoreByLocation(storeLocation).then((response) => this.setState({ storeId: response.data.id }));
    await this.fetchVideoRecorders(storeLocation).then((videoRecorders) => {
      this.setState({ videoRecorders });
      this.searchServerIp(videoRecorders);
    });
    this.setState({ storeLocation });
  }

  setSreenshotState(state) {
    this.setState({ screenshotState: state });
  }

  handleScreenshotGeneration = async () => {
    this.setSreenshotState("load");
    const { storeLocation, videoRecorders, serverIp } = this.state;
    videoRecorders.forEach((videoRecorder) => {
      videoRecorder.type = { name: videoRecorder.video_recorder_type_name };
    });
    if (videoRecorders.length !== 0 && videoRecorders[0].channels.length !== 0) {
      if (serverIp) {
        try {
          await trigggerBulkScreenshots(serverIp, storeLocation, "zip", videoRecorders).then((triggerResponse) => {
            if (triggerResponse.data.status === "running") {
              toast.info("Zip screenshots is running!");
              fetchBulkScreenshots(serverIp, storeLocation, "zip", videoRecorders).then((fetchResponse) => {
                if (fetchResponse.data.status === "success") {
                  this.setSreenshotState("success");
                  downloadFile(fetchResponse.data.zip_file_url);
                } else {
                  this.setSreenshotState("failed");
                  toast.error(fetchResponse.data.message);
                }
              });
            } else if (triggerResponse.data.status === "success") {
              this.setSreenshotState("success");
              downloadFile(triggerResponse.data.zip_file_url);
            } else {
              this.setSreenshotState("failed");
              toast.error(triggerResponse.data.message);
            }
          });
        } catch (ex) {
          this.setSreenshotState("failed");
          toast.error("Failed fetching screenshots!");
        }
      }
    } else {
      this.setSreenshotState("failed");
      toast.error("No Cameras found!");
    }
  };

  switchCurrentCard = (event, card) => {
    if (card) {
      this.setState({ currentCard: card });
    } else {
      let { currentCard } = this.state;
      if (currentCard === "screenshots") {
        currentCard = "scanDVR";
      } else {
        currentCard = "screenshots";
      }
      this.setState({ currentCard });
    }
  };

  async fetchVideoRecorders(storeLocation) {
    try {
      const { data: videoRecorders } = await getVideoRecordersList(storeLocation);
      return videoRecorders;
    } catch (ex) {
      this.setSreenshotState("failed");
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
          return null;
        }
        toast.error("Unable to fetch video recorders list");
        return null;
      }
      if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
        return null;
      }
      if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
        return null;
      }
      toast.error("Unhandled error");
      return null;
    }
  }

  async fetchServerIp(server) {
    try {
      const { data: serverIp } = await getServerIp(server);
      return serverIp.server_ip;
    } catch (ex) {
      this.setSreenshotState("failed");
      if (ex.response && ex.response.status === 404) {
        if (ex.response.data && ex.response.data.detail) {
          toast.error(ex.response.data.detail);
        } else {
          toast.error("Unable to fetch server IP");
        }
      } else if (ex.response && ex.response.status === 400) {
        Object.keys(ex.response.data).forEach(function (key) {
          const message = `${key}: ${ex.response.data[key][0]}`;
          toast.error(message);
        });
      } else if (ex.response && ex.response.status === 401) {
        toast.error(ex.response.data.error);
      } else {
        toast.error("Unhandled error while fetching server IP");
      }
    }
  }

  searchServerIp(videoRecorders) {
    let serverIp = null;
    // with Array.prototype.every() :
    // return false == break
    // return true == continue
    videoRecorders.every((videoRecorder) => {
      if (videoRecorder.channels.length === 0) {
        return true;
      }
      videoRecorder.channels.every((channel) => {
        if (!channel.inference_machine_data || !channel.inference_machine_data.hostname) {
          return true;
        }
        serverIp = this.fetchServerIp(channel.inference_machine_data.hostname).then((fetchedServerIp) => {
          if (fetchedServerIp) {
            this.setState({ serverIp: fetchedServerIp });
          }
          if (serverIp) {
            return false;
          }
        });
        return true;
      });
      return true;
    });
  }

  render() {
    const { storeLocation, storeId, currentCard, videoRecorders, serverIp, screenshotState } = this.state;
    const className = "store-config";
    return (
      <div className="content">
        <Row>
          <Col md="12">
            {storeLocation && (
              <Card className={className}>
                <CardHeader className="mb-2">
                  <h5 className="card-category">Store Screenshot tools</h5>
                  <CardTitle tag="h2">
                    {storeLocation}
                    <a className="ml-3" href={`/stores/${storeId}`}>
                      <Button>Store config</Button>
                    </a>
                  </CardTitle>
                  <CardTitle tag="h4">
                    <span
                      className={`inline-block${currentCard === "scanDVR" ? " text-gray-500" : ""}`}
                      onClick={(e) => this.switchCurrentCard(e, "screenshots")}
                    >
                      Camera Screenshots
                    </span>
                    <div className="inline-block m-2">
                      <i
                        className={`cursor-pointer fa fa-toggle-on text-3xl m-2${
                          currentCard === "screenshots" ? " rotate-180" : ""
                        }`}
                        onClick={this.switchCurrentCard}
                      />
                    </div>
                    <span
                      className={`inline-block${currentCard === "screenshots" ? " text-gray-500" : ""}`}
                      onClick={(e) => this.switchCurrentCard(e, "scanDVR")}
                    >
                      Scan DVR
                    </span>
                    {currentCard === "screenshots" && (
                      <Button className="float-right btn-info m-2" onClick={this.handleScreenshotGeneration}>
                        {screenshotState === "load" && (
                          <i style={{ display: "inline-block" }} className="m-2">
                            <ThreeDots
                              height="15"
                              width="20"
                              radius="5"
                              color="#ffffff"
                              ariaLabel="three-dots-loading"
                              visible
                            />
                          </i>
                        )}
                        {screenshotState !== "load" && <i className="fa fa-file-archive m-2" />}
                        Download ZIP
                      </Button>
                    )}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {currentCard === "screenshots" && serverIp && (
                    <StoreScreenshots location={storeLocation} videoRecorders={videoRecorders} serverIp={serverIp} />
                  )}
                  {currentCard === "scanDVR" && serverIp && (
                    <StoreScanVideoRecorder location={storeLocation} videoRecorders={videoRecorders} />
                  )}
                  {!serverIp && (
                    <p>
                      No server found, please have at least one DVR with a channel or one IP camera with an inference
                      machine assigned.
                    </p>
                  )}
                </CardBody>
                <CardFooter />
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreScreenshotToolsView;

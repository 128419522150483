import http from "./httpService";

const apiEndPoint = "/watcher/credentials/";

function getUrl(storeLocation) {
  return `${apiEndPoint}${storeLocation}/`;
}

export function getWatcherCredential(storeLocation, passwordHash) {
  const url = getUrl(storeLocation);
  return http.get(url, {
    params: { password_hash: passwordHash },
  });
}

export function addWatcherCredentials(storeLocation, credentials) {
  const url = getUrl(storeLocation);
  return http.post(url, credentials);
}

export function deleteWatcherCredentials(storeLocation) {
  const url = getUrl(storeLocation);
  return http.delete(url);
}

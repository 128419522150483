const historyBaseEndPoint = "/history";

export function getUsersHistoriesPaginatedEndpoint({ page = 1, pageSize = 10, query = "" }) {
  return `${historyBaseEndPoint}/users/?page=${page}&page_size=${pageSize}&filter=${query}`;
}

export function getUsersHistoriesSummaryPaginatedEndpoint(username) {
  return `${historyBaseEndPoint}/users/${username}/summary/`;
}

export function getUserHistoryEndpoint(username) {
  return `${historyBaseEndPoint}/users/${username}/`;
}

import Joi from "joi-browser";
import Model from "./Model";
const groups = { PRIMARY: "primary" };

class ChannelModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number().allow(null));
    this.video_recorder = this.createField(null, groups.PRIMARY, "video_recorder", Joi.number());
    this.channel_number = this.createField(1, groups.PRIMARY, "Channel number", Joi.number().required());
    this.channel_name = this.createField("", groups.PRIMARY, "Channel name", Joi.string().allow("", null));
    this.view_area = this.createField("", groups.PRIMARY, "View Area", Joi.array().allow("", null));
    this.favor_factor = this.createField(
      "",
      groups.PRIMARY,
      "Favor factor",
      Joi.number()
        .min(parseInt(process.env.REACT_APP_MIN_FAVOR_FACTOR))
        .max(parseInt(process.env.REACT_APP_MAX_FAVOR_FACTOR_CAMERA_LEVEL))
        .allow("", null)
    );
    this.recheckings = this.createField(
      "",
      groups.PRIMARY,
      "Recheckings",
      Joi.number()
        .min(parseInt(process.env.REACT_APP_MIN_RECHECKING))
        .max(parseInt(process.env.REACT_APP_MAX_RECHECKING))
        .allow("", null)
    );
    this.anchor = this.createField("", groups.PRIMARY, "Anchor", Joi.string().allow("", null));
    this.is_active = this.createField(true, groups.PRIMARY, "Is active", Joi.boolean());
    this.inference_machine = this.createField(0, groups.PRIMARY, "Inference machine", Joi.number().allow(null));
    this.alert_channel = this.createField(0, groups.PRIMARY, "Alert Channel", Joi.number().allow(null));
    this.protocol = this.createField("tcp", groups.PRIMARY, "Protocol", Joi.string());
    this.depth_of_field = this.createField(0, groups.PRIMARY, "Depth of Field", Joi.number().allow(null));
    this.relevance = this.createField(0, groups.PRIMARY, "Relevance", Joi.number().allow(null));
    /*
    Old alert labels
    */
    this.no_suspicious = this.createField(0, groups.PRIMARY, "No suspicious", Joi.number());
    this.no_suspicious_bag = this.createField(0, groups.PRIMARY, "No suspicious bag", Joi.number());
    this.no_stroller = this.createField(0, groups.PRIMARY, "No stroller", Joi.number());
    this.send_store_normal_bag = this.createField(0, groups.PRIMARY, "Send store normal bag", Joi.number());
    this.send_gesture_into_body = this.createField(0, groups.PRIMARY, "Send gesture into body", Joi.number());
    this.send_client_normal_bag = this.createField(0, groups.PRIMARY, "Send client normal bag", Joi.number());
    /*
    New alert labels
    */
    this.gesture_into_body = this.createField(0, groups.PRIMARY, "Gesture into body", Joi.number());
    this.product_in_personal_bag = this.createField(0, groups.PRIMARY, "Product in personal bag", Joi.number());
    this.product_into_backpack = this.createField(0, groups.PRIMARY, "Product into backpack", Joi.number());
    this.suspicious_bag = this.createField(0, groups.PRIMARY, "Suspicious bag", Joi.number());
    this.product_into_stroller = this.createField(0, groups.PRIMARY, "Product into stroller", Joi.number());
    this.client_normal_bag = this.createField(0, groups.PRIMARY, "Client normal bag", Joi.number());
    this.store_normal_bag = this.createField(0, groups.PRIMARY, "Store normal bag", Joi.number());
    this.deblistering = this.createField(0, groups.PRIMARY, "Deblistering", Joi.number());
    this.consumption = this.createField(0, groups.PRIMARY, "Consumption", Joi.number());
    this.burst_shot = this.createField(0, groups.PRIMARY, "Burst shot", Joi.number());
    this.other_suspicious = this.createField(0, groups.PRIMARY, "Other", Joi.number());
  }

  getObject(data) {
    let updatedData = super.getObject(data);
    updatedData = this.cleanIndex(updatedData);
    return updatedData;
  }
  cleanIndex(data) {
    const keyToReplace = [
      "no_suspicious",
      "no_suspicious_bag",
      "no_stroller",
      "send_client_normal_bag",
      "send_store_normal_bag",
      "send_gesture_into_body",
    ];
    keyToReplace.forEach((key) => {
      data[key] = this.convertBoolToInt(data[key]);
    });
    return data;
  }
  convertBoolToInt(value) {
    // This function convert the boolean to a 3 states dropdown to allow us to handle multiple cases
    switch (value) {
      case true:
        return 1;
      case false:
        return 2;
      default:
        return 0;
    }
  }
}

export default ChannelModel;

import React, { Component } from "react";
import { toast } from "react-toastify";
import VtIp from "./VtIp";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import ToggleSwitch from "../common/controls/ToggleSwitch/ToggleSwitch";
import { patchStoreField } from "../../services/storeService";

class VtIpList extends Component {
  state = { vtIps: [], showNewVtIp: false, enabled: false };

  componentDidMount() {
    const { vtIps, store } = this.props;
    const enabled = store.anaveo_vt_alert;
    this.setState({ vtIps, store, enabled });
  }

  toggleAddVtIp = () => {
    const { showNewVtIp } = this.state;
    this.setState({ showNewVtIp: !showNewVtIp });
  };

  toggleAnaveoVtAlert = async () => {
    const { store, enabled } = this.state;
    const updatedEnabled = !enabled;
    store.anaveo_vt_alert = updatedEnabled;
    try {
      const res = await patchStoreField(store.id, "anaveo_vt_alert", updatedEnabled);
      if (res) {
        this.setState({ store, enabled: updatedEnabled });
        toast.info("Store config updated.");
      }
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 400) {
          Object.keys(ex.response.data).forEach(function (key) {
            const message = `${key}: ${ex.response.data[key][0]}`;
            toast.error(message);
          });
        } else if (ex.response.status === 404) {
          toast.error("Store not found");
        } else {
          toast.error("Failed to update");
        }
      } else {
        toast.error("Failed to update");
      }
    }
  };

  updateVtIpList = (vtIp) => {
    const { vtIps } = this.state;
    const index = vtIps.map((data) => data.id).indexOf(vtIp.id);
    let { showNewVtIp } = this.state;
    if (index === -1) {
      vtIps.unshift(vtIp);
      showNewVtIp = false;
    } else {
      vtIps[index] = vtIp;
    }
    this.setState({ vtIps, showNewVtIp });
  };

  removeFromVtIpList = (vtIpId) => {
    const { vtIps } = this.state;
    const updatedVtIps = vtIps.filter((vtIp) => vtIp.id !== vtIpId);
    this.setState({ vtIps: updatedVtIps });
  };

  render() {
    const { vtIps, showNewVtIp, enabled } = this.state;
    const { storeId } = this.props;
    return (
      <Card>
        <CardHeader>
          <div className="vt-ip">
            <Col md="0">
              <CardTitle tag="h4">Anaveo VT IPs</CardTitle>
            </Col>
            <div className="col mb-2" key="anaveo_vt_alert">
              <ToggleSwitch checked={enabled} onChange={this.toggleAnaveoVtAlert} />
            </div>
          </div>
          <Row>
            <Col md={12}>
              <Button className="float-right" onClick={this.toggleAddVtIp}>
                {showNewVtIp ? "Cancel" : "Add VT IP"}
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {showNewVtIp && (
            <VtIp
              key="new"
              vtIp={null}
              storeId={storeId}
              toggleAddVtIp={this.toggleAddVtIp}
              updateVtIpList={this.updateVtIpList}
              removeFromVtIpList={this.removeFromVtIpList}
              editMode
              showNewVtIp={showNewVtIp}
            />
          )}
          {vtIps &&
            vtIps.map((vtIp) => (
              <VtIp
                key={vtIp.id}
                vtIp={vtIp}
                storeId={storeId}
                updateVtIpList={this.updateVtIpList}
                removeFromVtIpList={this.removeFromVtIpList}
                editMode={false}
                showNewVtIp={showNewVtIp}
              />
            ))}
        </CardBody>
      </Card>
    );
  }
}

export default VtIpList;

import Model from "./Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class ScanVideoRecorderModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number());
    this.store = this.createField(null, groups.PRIMARY, "store", Joi.number());
    this.video_recorder_type_id = this.createField(1, groups.PRIMARY, "Device type", Joi.number().required());
    this.username = this.createField("", groups.PRIMARY, "Username", Joi.string().required());
    this.password = this.createField("", groups.PRIMARY, "Password", Joi.string().required());
    this.device_ip = this.createField("", groups.PRIMARY, "Device IP", Joi.string().required());
    this.device_rtsp_port = this.createField(554, groups.PRIMARY, "RTSP port", Joi.number().required());
    this.rtsp_url_suffix = this.createField("", groups.PRIMARY, "RTSP URL suffix", Joi.string().allow(""));
    this.protocol = this.createField("tcp", groups.PRIMARY, "Protocol", Joi.string().required());
    this.sub_type = this.createField("sub", groups.PRIMARY, "Sub type", Joi.string().required());
  }
}

export default ScanVideoRecorderModel;

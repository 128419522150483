import http from "./httpService";

const apiEndPoint = "/locations/list_remotecmd";

async function getCommands() {
  const response = await http.get(apiEndPoint);
  const adaptedCommands = [];
  response.data.commands.forEach((command, index) => {
    adaptedCommands[index] = {
      id: index,
      value: index,
      label: command,
    };
  });

  return adaptedCommands;
}

export default getCommands;

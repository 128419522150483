import Joi from "joi-browser";

export function validateInteger(value, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER) {
  const obj = { value };
  const schema = Joi.object({
    value: Joi.number().integer().min(min).max(max),
  });
  const { error } = schema.validate(obj);
  return error ? error.details[0].message : null;
}

export function validateTextPolygon(value) {
  // allowed format example : 10,10;50,40;30,30
  const textPolygonPattern = /^(\d+,\d+)(;\d+,\d+)+$/;
  const obj = { value };
  const schema = Joi.object({
    value: Joi.string().regex(textPolygonPattern),
  });
  const { error } = schema.validate(obj);
  return error ? "Please follow this format : x1,y1;x2,y2;x3,y3 (minimum 2 points)" : null;
}

export function validatePolygon(value, resolution) {
  const obj = { value };
  const schema = Joi.object({
    value: Joi.array()
      .items(
        Joi.array()
          .length(2)
          .ordered(Joi.number().integer().max(resolution.width), Joi.number().integer().max(resolution.height))
      )
      .min(2)
      .required(),
  });
  const { error } = schema.validate(obj);
  if (!error) {
    return null;
  }
  return `Please follow this format : x1,y1;x2,y2;x3,y3 with x < ${resolution.width} and y < ${resolution.height}`;
}

import React from "react";
import { Card, CardHeader } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import SoftwareVersion from "./SoftwareVersion";
import useFetch from "./shared/hooks/api/fetch";
import { softwareVersionBaseEndPoint } from "../services/SoftwareVersionService";

export default function SoftwareVersions({ hostname }) {
  const [softwareVersions] = useFetch(`${softwareVersionBaseEndPoint}/${hostname}/`);

  if (softwareVersions && Object.keys(softwareVersions).length > 0) {
    return (
      <Card>
        <CardHeader className="mb-2">
          <h5 className="card-category">Services</h5>
        </CardHeader>
        <CardBody>
          {Object.keys(softwareVersions).map((software) => {
            const version = softwareVersions[software];
            let icon;
            if (software.includes("replay")) {
              icon = "fas fa-play-circle";
            } else if (software.includes("upload")) {
              icon = "fas fa-cloud-upload-alt";
            } else {
              icon = "fas fa-stroopwafel";
            }

            return <SoftwareVersion software={software} icon={icon} version={version} key={software} />;
          })}
        </CardBody>
      </Card>
    );
  }
  return (
    <Card>
      <CardHeader className="mb-2">
        <h5 className="card-category">Services</h5>
      </CardHeader>
      <CardBody>
        <p>No software available</p>
      </CardBody>
    </Card>
  );
}

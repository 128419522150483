import React from "react";
import { toast } from "react-toastify";
import BaseForm from "../../common/BaseForm";
import { Row, Col, Button, Form } from "reactstrap";
import DiscoverModel from "../../models/remotecontrol/DiscoverModel";
import triggerRemoteCommand from "../../../services/remoteControlService";

class DiscoverForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  discoverModel = new DiscoverModel();
  schema = this.discoverModel.getSchema();

  componentDidMount() {
    const { discover } = this.props;
    this.setState({ data: discover || this.discoverModel.getDefaultData() });
  }

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  installDiscover = async () => {
    try {
      toast.success("Discover command downloading...");
      const data = {
        hostname: this.props.hostname,
        cmd: "discover-install",
        cmdArgs: {},
      };
      const result = await triggerRemoteCommand(data);

      if (result.stderr) {
        if (result.stderr.toLowerCase().includes("warning")) {
          toast.warning(result.stderr);
        } else {
          toast.error(result.stderr);
        }
      } else {
        toast.success("Discover command installed successfully!");
      }
    } catch (error) {
      toast.error("Failed to install discover command. Please try again.");
    }
  };

  render() {
    return (
      <Form>
        <Row>
          <Col md="6">{this.renderInput("ip", "IP Address")}</Col>
          <Col md="6">{this.renderInput("port", "Port")}</Col>
        </Row>
        <Row>
          <Col md="6">{this.renderInput("username", "Username")}</Col>
          <Col md="6">{this.renderInput("password", "Password")}</Col>
        </Row>
        <Row>
          <Col md="6">{this.renderInput("url", "URL")}</Col>
          <Col md="6">{this.renderInput("channel", "Channel")}</Col>
        </Row>
        <Row>
          <Col md="6">{this.renderToggleSwitch("list_subnetwork", "List Subnetwork")}</Col>
          <Col md="6">{this.renderToggleSwitch("print_all", "Print All Matches")}</Col>
        </Row>
        <Row>
          <Col md="12">{this.renderToggleSwitch("password_guess", "Password Guess")}</Col>
        </Row>
        <Row>
          <Col md="12">
            <Button className="float-left btn-info mt-3 mr-2" onClick={this.installDiscover}>
              Install Discover
            </Button>
            <Button className="float-right btn-success mt-3" onClick={this.handleSubmit}>
              Discover
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default DiscoverForm;

import http from "./httpService";

export function getMachines(location) {
  return http.get(`/stores/${location}/inference-machines/`).then((response) => response.data);
}

export function getIncidents(location) {
  return http.get(`/stores/${location}/incidents/`).then((response) => response.data);
}

export function createIncident(location, incidentType, machine) {
  const incident = {
    store: { location },
    incident_type: { name: incidentType.name },
    machine,
  };
  return http.post(`/stores/${location}/incidents/`, incident).then((response) => response.data);
}

export function updateIncident(location, incident) {
  const data = {
    id: incident.id,
    status: incident.status,
  };
  return http.patch(`/stores/${location}/incidents/`, data).then((response) => response.data);
}

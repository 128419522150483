import React from "react";

export default function LogMessage({ message, logLevel = "info", lineBreak = false }) {
  const textColor = "text-light";
  const errorTextColor = "text-danger";
  const warningTextColor = "text-warning";
  const successTextColor = "text-success";

  if (logLevel === "warning") {
    return (
      <>
        <p className={warningTextColor} data-testid="log-message" role="note">
          {message}
        </p>
        {lineBreak && <br />}
      </>
    );
  }
  if (logLevel === "error") {
    return (
      <>
        <p className={errorTextColor} data-testid="log-message" role="note">
          {message}
        </p>
        {lineBreak && <br />}
      </>
    );
  }
  if (logLevel === "success") {
    return (
      <>
        <p className={successTextColor} data-testid="log-message" role="note">
          {message}
        </p>
        {lineBreak && <br />}
      </>
    );
  }
  return (
    <>
      <p className={textColor} data-testid="log-message" role="note">
        {message}
      </p>
      {lineBreak && <br />}
    </>
  );
}

import React from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap";

function HardwareSpecificationsResult({ specifications }) {
  if (!specifications || specifications.length === 0) {
    return <div>No hardware specifications available.</div>;
  }

  return (
    <Row>
      {specifications.map((spec, index) => (
        <Col md="12" key={`spec_${index}`} className="mb-4">
          <Card>
            <CardHeader>
              <CardTitle tag="h4" className="text-center">
                Hardware Specifications {index + 1}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6" className="mb-2">
                  <CardText>CPU: {spec.cpu}</CardText>
                </Col>
                <Col md="6" className="mb-2">
                  <CardText>Memory: {spec.memory} GB</CardText>
                </Col>
                <Col md="6" className="mb-2">
                  <CardText>GPU: {spec.gpu}</CardText>
                </Col>
                <Col md="6" className="mb-2">
                  <CardText>Type: {spec.type}</CardText>
                </Col>
                <Col md="6" className="mb-2">
                  <CardText>Streams: {spec.streams}</CardText>
                </Col>
                <Col md="6" className="mb-2">
                  <CardText>Inference Machine: {spec.inference_machine}</CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default HardwareSpecificationsResult;

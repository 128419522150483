class Model {
  createField(defaultValue, group, label, type) {
    return {
      defaultValue,
      label,
      group,
      type: type.label(label),
      value: defaultValue,
    };
  }

  setValue(field, value) {
    if (value !== null) field.value = value;
    return field.value;
  }

  getObject(data) {
    const dataObject = {};
    Object.keys(this).forEach((key) => {
      dataObject[key] = this.setValue(this[key], data[key]);
    });
    return dataObject;
  }

  getSchema() {
    const schema = {};
    Object.keys(this).forEach((key) => {
      schema[key] = this[key].type;
    });
    return schema;
  }

  getDefaultData() {
    const dataObject = {};
    Object.keys(this).forEach((key) => {
      dataObject[key] = this[key].defaultValue;
    });
    return dataObject;
  }
}

export default Model;

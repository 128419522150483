import React, { useState, useEffect } from "react";
import Watcher from "./Watcher";
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from "reactstrap";

/** A functional component to manage a list of watchers. */
const WatcherList = function ({
  watchers: initialWatchers,
  notifier: initialNotifier,
  store: initialStore,
  onSaveNotifier,
}) {
  const [watchers, setWatchers] = useState(initialWatchers);
  const [showNewWatcher, setShowNewWatcher] = useState(false);
  const [notifier, setNotifier] = useState(initialNotifier);
  const [store, setStore] = useState(initialStore);

  useEffect(() => {
    setWatchers(initialWatchers);
    setNotifier(initialNotifier);
    setStore(initialStore);
  }, [initialWatchers, initialNotifier, initialStore]);

  /** Toggle the form for adding a new watcher. */
  const toggleAddWatcher = () => {
    setShowNewWatcher(!showNewWatcher);
  };

  /** Update the watcher list with a new or updated watcher. */
  const updateWatcherList = (watcher) => {
    const index = watchers.map((data) => data.id).indexOf(watcher.id);
    if (index === -1) {
      setWatchers([watcher, ...watchers]);
      setShowNewWatcher(false);
    } else {
      const updatedWatchers = [...watchers];
      updatedWatchers[index] = watcher;
      setWatchers(updatedWatchers);
    }
  };

  /** Remove a watcher from the list. */
  const removeFromWatcherList = (watcherId) => {
    const updatedWatchers = watchers.filter((watcher) => watcher.id !== watcherId);
    setWatchers(updatedWatchers);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Watcher</CardTitle>
        <Row>
          <Col md={12}>
            {watchers.length === 0 && (
              <Button className="float-right" onClick={toggleAddWatcher}>
                {showNewWatcher ? "Cancel" : "Add Veesion Watcher"}
              </Button>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {showNewWatcher && (
          <Watcher
            key="new"
            watcher={null}
            notifier={notifier}
            onSaveNotifier={onSaveNotifier}
            store={store}
            toggleAddWatcher={toggleAddWatcher}
            updateWatcherList={updateWatcherList}
            removeFromWatcherList={removeFromWatcherList}
            editMode
            showNewWatcher={showNewWatcher}
          />
        )}
        {watchers.map((watcher) => (
          <Watcher
            key={watcher.id}
            watcher={watcher}
            notifier={notifier}
            onSaveNotifier={onSaveNotifier}
            store={store}
            toggleAddWatcher={toggleAddWatcher}
            updateWatcherList={updateWatcherList}
            removeFromWatcherList={removeFromWatcherList}
            editMode={false}
            showNewWatcher={showNewWatcher}
          />
        ))}
      </CardBody>
    </Card>
  );
};

export default WatcherList;

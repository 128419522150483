import React, { Component, Fragment } from "react";
import { Switch } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// core components
import MainNavbar from "../components/Navbars/MainNavbar";

import Sidebar from "../components/Sidebar/Sidebar";

import routes from "../routes";
import RoleBasedRoute from "../components/common/routes/RoleBasedRoute";
import auth from "../services/authService";
import RedirectView from "../components/common/RedirectView";
import userHasRole from "../components/shared/utils/authUtils";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: "blue",
      sidebarOpened: document.documentElement.className.indexOf("nav-open") !== -1,
    };
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    const { sidebarOpened } = this.state;
    this.setState({ sidebarOpened: !sidebarOpened });
  };

  getRoutes = () => {
    const routeComponents = [];
    routes.forEach((route) => {
      if (route.layout === "/") {
        if (route.subRoutes) {
          route.subRoutes.forEach((subRoute) => {
            let roles = [];
            let isRouteAuthorized = true;
            if (Object.prototype.hasOwnProperty.call(subRoute, "roles")) {
              roles = subRoute.roles;
              isRouteAuthorized = roles.filter((role) => userHasRole(role)).length > 0;
            }
            console.log(`${route.layout + subRoute.layout + subRoute.path}`);

            if (isRouteAuthorized) {
              routeComponents.push(
                <RoleBasedRoute
                  roles={roles}
                  path={route.layout + subRoute.layout + subRoute.path}
                  component={subRoute.component}
                  key={route.layout + subRoute.layout + subRoute.path}
                />
              );
            }
          });
        }

        let roles = [];
        let isRouteAuthorized = true;
        if (Object.prototype.hasOwnProperty.call(route, "roles")) {
          roles = route.roles;
          isRouteAuthorized = roles.filter((role) => userHasRole(role)).length > 0;
        }

        if (isRouteAuthorized === true) {
          routeComponents.push(
            <RoleBasedRoute
              roles={roles}
              path={route.layout + route.path}
              component={route.component}
              key={route.layout + route.path}
            />
          );
        }
      }
    });

    return routeComponents;
  };

  getBrandText = () => {
    const { location } = this.props;
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };

  loginUrl = () => {
    const loginPageUrl = new URL(process.env.REACT_APP_KEYCLOAK_AUTH_SERVER);
    loginPageUrl.searchParams.append("client_id", process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
    loginPageUrl.searchParams.append("redirect_uri", process.env.REACT_APP_KEYCLOAK_REDIRECT_URI);
    loginPageUrl.searchParams.append("response_type", process.env.REACT_APP_KEYCLOAK_LOGIN_PARAMS_RESPONSE_TYPE);
    loginPageUrl.searchParams.append("scope", "openid profile email");
    loginPageUrl.searchParams.append("state", "");

    return loginPageUrl.href;
  };

  render() {
    const authorizedRoutes = routes.filter((route) => {
      if (Object.prototype.hasOwnProperty.call(route, "roles")) {
        return route.roles.filter((role) => userHasRole(role)).length > 0;
      }

      return true;
    });

    if (!auth.isAuthenticated()) {
      return <RedirectView url={this.loginUrl()} />;
    }

    const { backgroundColor, sidebarOpened } = this.state;
    return (
      <Fragment>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Slide}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={authorizedRoutes}
            bgColor={backgroundColor}
            toggleSidebar={this.toggleSidebar}
          />
          <div className="main-container">
            <MainNavbar
              {...this.props}
              brandText={this.getBrandText()}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={sidebarOpened}
            />
            <Switch>{this.getRoutes(routes)}</Switch>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Home;

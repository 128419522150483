import React from "react";
import { toast } from "react-toastify";
import { Button, Col, Input, Row } from "reactstrap";
import { deleteChannel, saveChannel } from "../../services/channelService";
import { getInferenceMachines } from "../../services/inferenceMachineService";
import "../channels/Channel.scss";
import MissedTheftsTable from "../channels/MissedTheftsTable";
import PolygonsTable from "../channels/PolygonsTable";
import SubChannelsTable from "../channels/SubChannelsTable";
import BaseForm from "../common/BaseForm";
import StatusIndicator from "../common/StatusIndicator/StatusIndicator";
import Channel from "../models/ChannelModel";
import FormDataContext from "../shared/contexts/FormDataContext";
import FieldBuilder from "../shared/utils/FieldBuilder";
import ResponsiveRow from "./base/ResponsiveRow";

class ChannelForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    status: "started",
    inferenceMachines: [],
    showMachines: false,
    viewAreaUpdated: false,
    query: "",
    currentMachine: "",
    currentValue: "",
    cameraFilters: [
      { id: 0, name: "Global Value" },
      { id: 1, name: "Enabled" },
      { id: 2, name: "Disabled" },
    ],
    cameraFiltersInverted: [
      { id: 0, name: "Global Value" },
      { id: 2, name: "Enabled" },
      { id: 1, name: "Disabled" },
    ],
    showNewLabels: false, // TODO set to true once new labels have been implemented and deployed everywhere
    oldLabels: [
      { name: "no_suspicious", label: "Slightly suspicious", inverted: true },
      { name: "no_suspicious_bag", label: "Suspicious bag", inverted: true },
      { name: "no_stroller", label: "Stroller", inverted: true },
      { name: "send_store_normal_bag", label: "Store normal bag" },
      { name: "send_gesture_into_body", label: "Gesture into body" },
      { name: "send_client_normal_bag", label: "Client normal bag" },
    ],
    newLabelsBody: [{ name: "gesture_into_body", label: "Gesture into body" }],
    newLabelsBag: [
      { name: "product_in_personal_bag", label: "Product in personal bag" },
      { name: "product_into_backpack", label: "Product into backpack" },
      { name: "suspicious_bag", label: "Suspicious bag" },
      { name: "product_into_stroller", label: "Product into stroller" },
      { name: "client_normal_bag", label: "Client normal bag" },
      { name: "store_normal_bag", label: "Store normal bag" },
    ],
    newLabelsOthersus: [
      { name: "deblistering", label: "Deblistering" },
      { name: "consumption", label: "Consumption" },
      { name: "burst_shot", label: "Burst shot" },
      { name: "other_suspicious", label: "Other" },
    ],
  };

  timer = null;

  channel = new Channel();
  schema = this.channel.getSchema();

  async componentDidMount() {
    await this.fetchMachines();

    const { channel } = this.props;
    let currentMachine = "Not assigned";
    if (channel && channel.inference_machine_data) {
      currentMachine = channel.inference_machine_data.hostname;
    }
    let data;
    if (channel) {
      data = channel;
      data = this.replaceFilterFields(data);
    } else {
      data = this.channel.getDefaultData();
      data.video_recorder = this.props.videoRecorderId;
    }
    this.setState({ data, currentMachine });
    this.setState({ data, currentMachine }, () => {
      const errors = this.validate();
      this.setState({ errors: errors || {} });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { query } = this.state;
    if (prevState.query !== query) {
      this.timer = setTimeout(() => this.fetchMachines(), 200);
    }
  }

  fetchMachines = async () => {
    const { query } = this.state;
    try {
      const { data: pagedMachines } = await getInferenceMachines(1, query);
      let inferenceMachines = pagedMachines.results;
      inferenceMachines = inferenceMachines.map((machine) => {
        return {
          value: machine.id,
          name: machine.machine.hostname,
        };
      });
      inferenceMachines = inferenceMachines.sort(function (a, b) {
        return a.name > b.name;
      });
      inferenceMachines.unshift({ value: 0, name: "Not assigned" });

      this.setState({ inferenceMachines });
    } catch (ex) {
      console.log(ex);
      toast.error("Failed to connect, please log in");
    }
  };

  handleInferenceMachineChange = (value) => {
    clearTimeout(this.timer);
    this.setState({ query: value, currentValue: value });
  };

  handleViewAreaChange = (viewArea) => {
    const { data } = this.state;
    data.view_area = viewArea;
    this.setState({ data, viewAreaUpdated: true });
  };

  handleMissedTheftsChange = (missedThefts) => {
    const { data } = this.state;
    data.missed_thefts = missedThefts;
    this.setState({ data });
  };

  handleOriginalResolutionSet = (originalResolution) => {
    const { data } = this.state;
    data.original_resolution = originalResolution;
    this.setState({ data });
  };

  showMachineList = () => {
    const showMachines = true;
    this.setState({ showMachines });
  };

  hideMachineList = () => {
    const showMachines = false;
    const currentValue = "";
    const query = "";
    this.setState({ showMachines, currentValue, query });
  };

  setInferenceMachine = (event, inferenceMachine) => {
    const { data } = this.state;
    data.inference_machine = inferenceMachine.value;
    const currentMachine = inferenceMachine.name;
    const currentValue = "";
    const query = "";
    this.setState({ data, currentMachine, currentValue, query });
  };

  delete = async () => {
    let status = "deleting";
    this.setState({ status });
    try {
      const res = await deleteChannel(this.state.data.id);
      if (res.status === 204) {
        this.props.removeChannel(this.state.data.id);
      }
    } catch (exception) {
      if (exception.response) {
        status = "Failed to delete!";
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  save = async () => {
    let status = "saving";
    this.setState({ status });
    try {
      console.log(JSON.stringify(this.state.data));
      const { data: channel, status: resCode } = await saveChannel(this.state.data);
      status = resCode < 300 ? "success" : "Could not save channel";
      this.setState({ status });
      let { viewAreaUpdated } = this.state;
      if (status === "success") {
        this.props.updateChannelList(channel, this.props.channelIndex);
        viewAreaUpdated = false;
      }
      this.setState({ status, viewAreaUpdated });
    } catch (exception) {
      if (exception.response) {
        status = "Invalid data provided! ";
        if (exception.response.data.non_field_errors) {
          status += exception.response.data.non_field_errors;
        }
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      console.log(exception.response);
      this.setState({ status });
    }
  };

  // TODO: I hate doing this but this is the existing behavior. Changing it requires to much work.
  //   will do it later
  replaceFilterFields = (channel) => {
    const newChannel = { ...channel };
    const keys = [
      "no_suspicious",
      "no_suspicious_bag",
      "no_stroller",
      "send_client_normal_bag",
      "send_store_normal_bag",
      "send_gesture_into_body",
      "gesture_into_body",
      "product_in_personal_bag",
      "product_into_backpack",
      "suspicious_bag",
      "product_into_stroller",
      "client_normal_bag",
      "store_normal_bag",
      "deblistering",
      "consumption",
      "burst_shot",
      "other_suspicious",
    ];

    keys.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(newChannel, key)) {
        const value = newChannel[key];
        if (typeof value === "boolean") {
          if (value) {
            newChannel[key] = 1;
          } else {
            newChannel[key] = 2;
          }
        } else if (typeof value === "string") {
          const parsed = parseInt(value, 10);
          if (Number.isNaN(parsed)) {
            newChannel[key] = 0;
          }
          newChannel[key] = parsed;
        } else {
          newChannel[key] = 0;
        }
      }
    });

    return newChannel;
  };

  render() {
    const {
      data,
      status,
      inferenceMachines,
      currentMachine,
      currentValue,
      showNewLabels,
      oldLabels,
      newLabelsBody,
      newLabelsBag,
      newLabelsOthersus,
      cameraFilters,
      cameraFiltersInverted,
      viewAreaUpdated,
      errors,
    } = this.state;
    const { className, deleteMode, alertChannels, relevances, depthOfFields } = this.props;
    let statusType = null;
    let message = "";
    switch (status) {
      case "edited":
        statusType = null;
        message = null;
        break;
      case "started":
        statusType = null;
        message = null;
        break;
      case "saving":
        statusType = "progress";
        message = "saving...";
        break;
      case "deleting":
        statusType = "progress";
        message = "deleting...";
        break;
      case "success":
        statusType = "success";
        message = "saved!";
        break;
      default:
        statusType = "failed";
        message = status;
    }

    const statusIndicator = (
      <div className="float-right m-2">
        <StatusIndicator status={statusType} message={message} />
      </div>
    );
    return (
      <React.Fragment>
        <FormDataContext.Provider value={data}>
          <div className={className}>
            <ResponsiveRow
              md="2"
              bottomSeparator
              fields={[
                new FieldBuilder()
                  .setId("channel_number")
                  .setName("channel_number")
                  .setComponent(this.renderInput("channel_number", "Channel number"))
                  .build(),
                new FieldBuilder()
                  .setId("channel_name")
                  .setName("channel_name")
                  .setComponent(this.renderInput("channel_name", "Channel name"))
                  .build(),
                new FieldBuilder()
                  .setId("favor_factor")
                  .setName("favor_factor")
                  .setComponent(
                    this.renderInput("favor_factor", "Favor factor", "number", {
                      step: 0.01,
                      min: process.env.REACT_APP_MIN_FAVOR_FACTOR,
                      max: process.env.REACT_APP_MAX_FAVOR_FACTOR_CAMERA_LEVEL,
                    })
                  )
                  .build(),
                new FieldBuilder()
                  .setId("recheckings")
                  .setName("recheckings")
                  .setComponent(
                    this.renderInput("recheckings", "Recheckings", "number", {
                      step: 1,
                      min: process.env.REACT_APP_MIN_RECHECKING,
                      max: process.env.REACT_APP_MAX_RECHECKING,
                    })
                  )
                  .build(),
                new FieldBuilder()
                  .setId("alert_channel")
                  .setName("alert_channel")
                  .setComponent(this.renderSelect("alert_channel", "Alert channel", alertChannels))
                  .build(),
                new FieldBuilder()
                  .setId("protocol")
                  .setName("protocol")
                  .setParentTagClassName("pr-md-1 centered")
                  .setComponent(
                    this.renderChoiceControl("protocol", [
                      { value: "tcp", label: "TCP" },
                      { value: "udp", label: "UDP" },
                    ])
                  )
                  .build(),
                new FieldBuilder()
                  .setId("is_active")
                  .setName("is_active")
                  .setParentTagClassName("pr-md-1 centered")
                  .setComponent(this.renderToggleSwitch("is_active", "Active"))
                  .build(),
              ]}
            >
              <Col className="pr-md-1" md="2">
                <label> Inference machine </label>
                <Input
                  name="find_machine"
                  id="find_machine"
                  placeholder="Search"
                  value={!this.state.showMachines ? currentMachine : currentValue}
                  onChange={(e) => this.handleInferenceMachineChange(e.currentTarget.value)}
                  onClick={this.showMachineList}
                  onBlur={this.hideMachineList}
                  style={{
                    fontWeight: currentValue === "" ? "bold" : "normal",
                  }}
                />
                <div
                  className="machine-list"
                  style={{
                    display: this.state.showMachines ? "block" : "none",
                  }}
                >
                  {inferenceMachines &&
                    inferenceMachines.map((infMachine, index) => (
                      <div className="machine-item" key={`machine-item-${index}`}>
                        <button
                          type="button"
                          className="text-left"
                          onMouseDown={(e) => this.setInferenceMachine(e, infMachine)}
                        >
                          {infMachine.name}
                        </button>
                      </div>
                    ))}
                </div>
              </Col>
            </ResponsiveRow>

            <ResponsiveRow
              header="Grading"
              md="3"
              fields={[
                new FieldBuilder()
                  .setId("relevance")
                  .setName("relevance")
                  .setComponent(this.renderSelect("relevance", "Relevance", relevances))
                  .build(),
                new FieldBuilder()
                  .setId("depth_of_field")
                  .setName("depth_of_field")
                  .setComponent(this.renderSelect("depth_of_field", "Depth of Field", depthOfFields))
                  .build(),
              ]}
              bottomSeparator
            >
              <Col className="pr-md-1" md="3">
                <label htmlFor="missed_thefts"> Total Missed Thefts :</label>
                {data.missed_thefts && (
                  <div id="missed_thefts" className="d-flex w-100">
                    <Input className="w-50" disabled value={data.missed_thefts.length} />
                    {data.id && (
                      <MissedTheftsTable
                        channelId={data.id}
                        inputMissedThefts={data.missed_thefts}
                        onMissedTheftsUpdate={this.handleMissedTheftsChange}
                      />
                    )}
                  </div>
                )}
              </Col>
            </ResponsiveRow>

            <ResponsiveRow
              header={showNewLabels ? "Old labels" : "Labels"}
              md="2"
              fields={oldLabels.map((config) =>
                new FieldBuilder()
                  .setId(config.name)
                  .setName(config.name)
                  .setComponent(
                    this.renderSelect(
                      config.name,
                      config.label,
                      config.inverted ? cameraFiltersInverted : cameraFilters
                    )
                  )
                  .build()
              )}
            />

            {showNewLabels && (
              <div>
                <h5 className="card-category">New labels</h5>
                <ResponsiveRow
                  header="Body"
                  bottomSeparator={false}
                  md="2"
                  fields={newLabelsBody.map((config) =>
                    new FieldBuilder()
                      .setId(config.name)
                      .setName(config.name)
                      .setComponent(
                        this.renderSelect(
                          config.name,
                          config.label,
                          config.inverted ? cameraFiltersInverted : cameraFilters
                        )
                      )
                      .build()
                  )}
                />
                <ResponsiveRow
                  header="Bag"
                  bottomSeparator={false}
                  md="2"
                  fields={newLabelsBag.map((config) =>
                    new FieldBuilder()
                      .setId(config.name)
                      .setName(config.name)
                      .setComponent(
                        this.renderSelect(
                          config.name,
                          config.label,
                          config.inverted ? cameraFiltersInverted : cameraFilters
                        )
                      )
                      .build()
                  )}
                />
                <ResponsiveRow
                  header="Other suspicious"
                  md="2"
                  fields={newLabelsOthersus.map((config) =>
                    new FieldBuilder()
                      .setId(config.name)
                      .setName(config.name)
                      .setComponent(
                        this.renderSelect(
                          config.name,
                          config.label,
                          config.inverted ? cameraFiltersInverted : cameraFilters
                        )
                      )
                      .build()
                  )}
                />
              </div>
            )}

            <ResponsiveRow
              md="4"
              fields={[
                new FieldBuilder()
                  .setId("view_area")
                  .setName("view_area")
                  .setComponent(
                    <PolygonsTable
                      inputViewArea={data.view_area}
                      inputOriginalresolution={data.original_resolution || [[[]]]}
                      onPolygonsUpdate={this.handleViewAreaChange}
                      onOriginalResolutionSet={this.handleOriginalResolutionSet}
                      viewAreaUpdated={viewAreaUpdated}
                    />
                  )
                  .build(),
              ]}
              bottomSeparator={false}
            />

            <Row>
              {!deleteMode && (
                <Col className="pr-md-4" md="9">
                  <Button
                    disabled={Object.keys(errors).length !== 0}
                    className="btn-success m-2 float-right"
                    onClick={this.save}
                  >
                    <i className="fas fa-check" />
                  </Button>
                  <Button className="btn-secondary m-2 float-right" onClick={this.props.toggleEditMode}>
                    <i className="fas fa-times" />
                  </Button>
                  {this.state.data && this.state.data.id && (
                    <Button className="btn-danger m-2 float-right" onClick={this.props.toggleDeleteMode}>
                      <i className="fas fa-trash-alt" />
                    </Button>
                  )}
                </Col>
              )}
            </Row>

            <Row>
              {deleteMode && (
                <Col className="pr-md-4 float-right " md="12">
                  <Button className="float-right btn-danger m-2" onClick={this.delete}>
                    <i className="fas fa-trash-alt mr-2" />
                    Delete
                  </Button>

                  <Button className="float-right btn-secondary m-2" onClick={this.props.toggleDeleteMode}>
                    <i className="fas fa-times mr-2" />
                    Cancel
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </FormDataContext.Provider>

        {statusType && (
          <tr className="status-container">
            <td colSpan={9}>{statusIndicator}</td>
          </tr>
        )}

        {this.props.canHaveSubChannels &&
          data &&
          Object.prototype.hasOwnProperty.call(data, "sub_channels") &&
          data.sub_channels.length === 0 && (
            <tr>
              <td colSpan={9}>
                <SubChannelsTable subChannels={[]} storeId={this.props.storeId} channelId={this.state.data.id} />
              </td>
            </tr>
          )}
      </React.Fragment>
    );
  }
}

export default ChannelForm;

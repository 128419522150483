import Model from "../Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class UpdatedvrModel extends Model {
  constructor() {
    super();
    this.type_action = this.createField("display", groups.PRIMARY, "Type action", Joi.string().required());
    this.type_dvr = this.createField("hik", groups.PRIMARY, "Type DVR", Joi.string().required());
    this.ip_dvr = this.createField(
      "",
      groups.PRIMARY,
      "Device IP",
      Joi.string()
        .regex(/^(?:(?:25[0-5]|2[0-4]\d|1?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|1?\d{1,2}|\{\})$/)
        .required()
        .error((errors) => {
          return errors.map((err) => {
            if (err.type === "string.regex.base") {
              err.message = "'Device IP' must be a valid IP address like '192.168.1.1' or '192.168.1.{}'";
            }
            return err;
          });
        })
    );
    this.username = this.createField("", groups.PRIMARY, "Username", Joi.string().alphanum().required());
    this.password = this.createField("", groups.PRIMARY, "Password", Joi.string().regex(/^\S+$/).required());
    this.flux = this.createField("main", groups.PRIMARY, "Flux", Joi.string().required());
    this.compression = this.createField("", groups.PRIMARY, "Compression", Joi.string().allow("").optional());
    this.fps = this.createField("", groups.PRIMARY, "Fps", Joi.string().allow("").optional());
    this.resolution = this.createField("", groups.PRIMARY, "Resolution", Joi.string().allow("").optional());
    this.bitrate = this.createField("", groups.PRIMARY, "Bitrate", Joi.string().allow("").optional());
    this.cbr_vbr = this.createField("", groups.PRIMARY, "Cbr_vbr", Joi.string().allow("").optional());
    this.motion_detection = this.createField("", groups.PRIMARY, "Motion", Joi.string().allow("").optional());
    this.encrypt = this.createField("", groups.PRIMARY, "Encrypt", Joi.string().allow("").optional());
  }
}

export default UpdatedvrModel;

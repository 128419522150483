import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import auth from "../../../services/authService";

/**
 * A login component that handle integration with Keycloak.
 *
 * The current implementation is base on [okta-auth-js](https://github.com/okta/okta-auth-js).
 *
 * @returns {null}
 * @constructor
 */
function LoginCallback() {
  const history = useHistory();

  const hasAuthorizationCode = (hashOrSearch) => {
    return /(code=)/i.test(hashOrSearch);
  };

  const hasInteractionCode = (hashOrSearch) => {
    return /(interaction_code=)/i.test(hashOrSearch);
  };

  const hasErrorInUrl = (hashOrSearch) => {
    return /(error=)/i.test(hashOrSearch) || /(error_description)/i.test(hashOrSearch);
  };

  const isRedirectUri = (uri) => {
    if (!uri) {
      return false;
    }
    return uri.indexOf(process.env.REACT_APP_KEYCLOAK_REDIRECT_URI) === 0;
  };

  const isCodeFlow = () => {
    const responseType = process.env.REACT_APP_KEYCLOAK_LOGIN_PARAMS_RESPONSE_TYPE;
    return responseType === "code" || responseType === "query";
  };

  const getHashOrSearch = () => {
    return isCodeFlow() ? window.location.search : window.location.hash;
  };

  /**
   * Check if tokens or a code have been passed back into the url, which happens in
   * the OIDC (including social auth IDP) redirect flow.
   */
  const isLoginRedirect = () => {
    // First check, is this a redirect URI?
    if (!isRedirectUri(window.location.href)) {
      return false;
    }

    // The location contains either a code, token, or an error + error_description
    const hashOrSearch = getHashOrSearch();

    if (hasErrorInUrl(hashOrSearch)) {
      return true;
    }

    return hasAuthorizationCode(hashOrSearch) || hasInteractionCode(hashOrSearch);
  };

  useEffect(() => {
    const parseFromUrl = async () => {
      try {
        if (hasAuthorizationCode(window.location.search)) {
          const url = new URL(window.location.href);
          const authorizationCode = url.searchParams.get("code");
          const token = await auth.getAccessToken(authorizationCode);
          const isValidToken = !!token;
          if (isValidToken) {
            history.push("/");
          } else {
            history.push("/login/error");
          }
        } else {
          throw new Error("Unable to parse url");
        }
      } catch (err) {
        history.push("/login/error");
      }
    };

    if (isLoginRedirect()) {
      return parseFromUrl();
    }
  }, [history]);

  return null;
}

export default React.memo(LoginCallback);

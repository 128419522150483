import * as Sentry from "@sentry/react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import React from "react";
import { getUsersHistoriesSummaryPaginatedEndpoint } from "../../../services/history/userHistoryService";
import UserChangesTable from "./UserChangesTable";
import useFetch from "../../../components/shared/hooks/api/fetch";
import PageContainer from "../../../components/common/PageContainer";
import NoDataAvailable from "../../../components/common/NoDataAvailable";

/**
 * A component that displays the list of changes (by date) for one user.
 *
 * @returns {JSX.Element}
 * @constructor
 */
function UserHistory() {
  const history = useHistory();
  const { username } = useParams();
  const [userHistories] = useFetch(getUsersHistoriesSummaryPaginatedEndpoint(username));

  const navigateBack = () => {
    history.go(-1);
  };

  if (userHistories === null) {
    return <NoDataAvailable />;
  }

  return (
    <PageContainer>
      <Container fluid>
        <Card>
          <CardBody>
            <Row>
              <Col md={{ size: 2 }}>
                <Button onClick={navigateBack}>Go back</Button>
              </Col>
            </Row>
            <Row>
              <UserChangesTable username={username} userHistories={userHistories} />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </PageContainer>
  );
}

export default Sentry.withProfiler(UserHistory);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { toast } from "react-toastify";

class ChannelScreenshot extends Component {
  state = {
    channel: null,
    videoRecorder: {},
  };

  componentDidMount() {
    const { channel, videoRecorder } = this.props;
    this.setState({ channel, videoRecorder });
  }

  handleClick = () => {
    const { channel, videoRecorder } = this.state;
    const { onClick } = this.props;
    const data = {
      video_recorder_id: videoRecorder.id,
    };
    let server = null;

    if (!channel.inference_machine_data) {
      toast.error("Can't generate screenshot for unassigned channels");
      return;
    }
    server = channel.inference_machine_data.hostname;

    onClick(channel.channel_number, data, server);
  };

  render() {
    const { channel, videoRecorder } = this.state;
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <React.Fragment>
        {channel && (
          <tr className="channel">
            <td>
              {channel.channel_number} {channel.view_area && <i className="fas fa-draw-polygon text-primary mr-2" />}
            </td>
            <td>{channel.channel_name}</td>
            <td>
              <span className={`badge ${channel.protocol}`}>{channel.protocol}</span>
            </td>
            <td>{videoRecorder.video_recorder_type_name}</td>
            <td>{videoRecorder.device_ip}</td>
            <td>{videoRecorder.device_rtsp_port}</td>
            <td>{videoRecorder.sub_type}</td>
            <td>
              {channel.inference_machine_data ? (
                <Link to={`/inferencemachines/${channel.inference_machine_data.id}`}>
                  <span className="machine">
                    <i className="fas fa-microchip mr-2" />
                    {channel.inference_machine_data.hostname}
                  </span>
                </Link>
              ) : (
                "Not assigned"
              )}
            </td>
            <td>
              <Button className="float-center" onClick={this.handleClick}>
                Screenshot
              </Button>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

export default ChannelScreenshot;

import http from "./httpService";

const apiEndPoint = "/inferencemachines/";

function getUrl(id) {
  return `${apiEndPoint}${id}/`;
}

function getMachineUrl(location, search = false) {
  let extraArgs = "";
  if (search) {
    extraArgs = "find/";
  }
  return `${apiEndPoint}hostname/${extraArgs}${location}`;
}

export function getInferenceMachines(page = 1, query = "") {
  const url = `${apiEndPoint}?page=${page}&search=${query}`;
  return http.get(url);
}

export function getInferenceMachine(machineId) {
  return http.get(getUrl(machineId));
}

export function getMachineByName(name) {
  return http.get(getMachineUrl(name, true));
}

export function saveMachine(machine) {
  const body = { ...machine };
  Object.keys(body).forEach(function (key) {
    if (body[key] === "") delete body[key];
  });
  if (body.id) {
    delete body.id;
    return http.put(getUrl(machine.id), body);
  }
}

import Model from "./Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };
class VtIpModel extends Model {
  constructor() {
    super();
    this.id = this.createField(null, groups.PRIMARY, "id", Joi.number());
    this.ip = this.createField(
      null,
      groups.PRIMARY,
      "ip",
      Joi.string().ip({
        version: ["ipv4", "ipv6"],
      })
    );
    this.store = this.createField(1, groups.PRIMARY, "Store", Joi.number().required());
  }
}

export default VtIpModel;

import auth from "../../../services/authService";

function userHasRole(role) {
  if (!auth.isAuthenticated()) {
    return false;
  }

  const currentUser = auth.getCurrentUser();
  return currentUser.roles.filter((r) => r === role).length > 0;
}

export default userHasRole;

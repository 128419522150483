import * as Sentry from "@sentry/react";
import { Button, Table } from "reactstrap";
import formatDate from "../utils";

function StoreChangesTable({ storeHistories, storeLocation }) {
  const formatUser = (user) => {
    const { username } = user;
    const { email } = user;

    if (username && email && username !== email) {
      return `${username}/${email}`;
    }

    if (username) {
      return `${username}`;
    }

    if (email) {
      return `${email}`;
    }

    return "";
  };

  const isValidUser = (user) => {
    return (
      user &&
      Object.prototype.hasOwnProperty.call(user, "username") &&
      Object.prototype.hasOwnProperty.call(user, "email")
    );
  };

  return (
    <Table className="tablesorter" responsive>
      <thead className="text-primary">
        <tr>
          <th>Location</th>
          <th>Edited on (mm/dd/yyyy)</th>
          <th>Editor</th>
          <th className="text-center" />
        </tr>
      </thead>
      <tbody>
        {storeHistories &&
          storeHistories.map((storeHistory, index) => (
            <tr key={`${storeHistory.revisionId}`}>
              <td>{storeLocation}</td>
              <td>
                <p className="text-info font-bold">{formatDate(storeHistory.createdOn)}</p>
              </td>
              <td>{isValidUser(storeHistory.user) && <p className="text-info">{formatUser(storeHistory.user)}</p>}</td>
              <td className="text-center">
                <a href={`/history/diff/${storeLocation}/${index}/${index + 1}/`}>
                  <Button>Change details</Button>
                </a>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default Sentry.withProfiler(StoreChangesTable);

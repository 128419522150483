import { Button, Form } from "reactstrap";
import BaseForm from "../common/BaseForm";
import StatusIndicator from "../common/StatusIndicator/StatusIndicator";
import Watcher from "../models/WatcherModel";
import { addOrUpdateSpecificNotifier, deleteSpecificNotifier } from "../../services/storeService";
import { toast } from "react-toastify";

class WatcherForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    confirmDelete: false,
    watcherFocus: undefined,
  };

  watcher = new Watcher();
  schema = this.watcher.getSchema();

  componentDidMount() {
    const { watcher, notifier, store } = this.props;
    let data = {};
    if (watcher) {
      data = this.watcher.getObject(watcher);
    } else {
      data.store = store.id;
      data.notifier = notifier.id;
    }
    this.setState({ data });
  }

  toggleConfirmDelete = ({ currentTarget: button }) => {
    const id = button.name;
    let { watcherFocus } = this.state;
    const confirmDelete = !this.state.confirmDelete;
    if (!watcherFocus) {
      watcherFocus = id;
    } else {
      watcherFocus = undefined;
    }
    this.setState({ confirmDelete, watcherFocus });
  };

  delete = async () => {
    let status = "deleting";
    this.setState({ status });
    try {
      const { notifiers } = this.props.store;
      Object.keys(notifiers).forEach((key) => {
        if (notifiers[key].watchers) {
          const watchers = notifiers[key].watchers.filter((watcher) => watcher.id !== this.state.data.id);
          notifiers[key].watchers = watchers;
        }
      });
      this.props.store.notifiers = notifiers;
      const notifierId = this.props.notifier.id;
      await deleteSpecificNotifier(this.props.store, this.props.notifier.name, this.state.data.id, notifierId);
      this.props.removeFromWatcherList(this.state.data.id);
      toast.info("Store config updated.");
      this.props.removeFromWatcherList(this.state.data.id);
      const confirmDelete = false;
      const watcherFocus = undefined;
      this.setState({ confirmDelete, watcherFocus });
      window.location.reload();
    } catch (exception) {
      if (exception.response) {
        status = "Failed to delete!";
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  save = async () => {
    const storeNotifiers = this.props.store.notifiers;
    const currentNotifier = this.props.notifier;
    const currentWatcher = this.state.data;

    this.state.data.notifier = currentNotifier.id;
    Object.keys(storeNotifiers).forEach((key) => {
      if (storeNotifiers[key].id === currentNotifier.id) {
        Object.keys(storeNotifiers[key].watchers).forEach((watcherKey) => {
          if (storeNotifiers[key].watchers[watcherKey].id === currentWatcher.id) {
            storeNotifiers[key].watchers[watcherKey] = { ...this.state.data };
          }
        });
      }
    });
    this.props.store.notifiers = storeNotifiers;
    try {
      const { data: watcher } = await addOrUpdateSpecificNotifier(
        this.props.store,
        this.props.notifier.name,
        this.state.data
      );

      this.props.onSaveNotifier(this.props.store.notifiers);
      this.props.updateWatcherList(watcher);
      toast.info("Store config updated.");
      this.props.toggleEditMode();
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 400) {
          Object.keys(ex.response.data).forEach(function (key) {
            const message = `${key}: ${ex.response.data[key][0]}`;
            toast.error(message);
          });
        } else if (ex.response.status === 404) {
          toast.error("Store not found");
        } else {
          console.error("Unexpected error:", ex.response); // Ajoutez cette ligne pour plus de détails
          toast.error("Failed to update");
        }
      } else {
        toast.error("Failed to update");
      }
    }
  };

  render() {
    const { watcher, showNewWatcher } = this.props;
    const { status, confirmDelete, watcherFocus } = this.state;
    let statusType = null;
    let message = "";
    switch (status) {
      case "edited":
        statusType = null;
        message = null;
        break;
      case "started":
        statusType = null;
        message = null;
        break;
      case "saving":
        statusType = "progress";
        message = "saving...";
        break;
      case "deleting":
        statusType = "progress";
        message = "deleting...";
        break;
      case "success":
        statusType = "success";
        message = "saved!";
        break;
      default:
        statusType = "failed";
        message = status;
    }
    const statusIndicator = (
      <div className="float-right m-2">
        <StatusIndicator status={statusType} message={message} />
      </div>
    );
    return (
      <div>
        <Form>
          <div className="vt-ip">
            <div className="!pr-1 pl-[15px]">{this.renderInput("watcher_version", "Watcher version")}</div>
            <label />
            {!confirmDelete && (
              <Button className="float-right btn-success m-50 !mt-[27px] !mx-[5px] !mb-0" onClick={this.save}>
                <i className="fas fa-save mr-2" />
                Save
              </Button>
            )}
            {!showNewWatcher && !confirmDelete && (
              <Button
                className="float-right btn-danger m-50 !mt-[27px] !mx-[5px] !mb-0"
                name={`items-${watcher.id}`}
                onClick={this.toggleConfirmDelete}
              >
                <i className="fas fa-trash-alt mr-2" />
                Delete
              </Button>
            )}
            {!showNewWatcher && confirmDelete && watcherFocus === `items-${watcher.id}` && (
              <div className="!mt-[27px] !mx-[5px] !mb-0">
                <span className="float-left" style={{ padding: "12px", fontWeight: 600, color: "#ff4e4e" }}>
                  Are you sure you want to delete this notifier ?
                </span>
                <Button className="float-right m-2" onClick={this.toggleConfirmDelete}>
                  <i className="fas fa-times mr-2" /> Cancel
                </Button>
                <Button className="float-right btn-danger m-2" onClick={this.delete}>
                  <i className="fas fa-trash-alt mr-2" />
                  Delete
                </Button>
              </div>
            )}
            {message && statusIndicator}
          </div>
        </Form>
      </div>
    );
  }
}

export default WatcherForm;

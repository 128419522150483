import React from "react";

function SortListItem({ sortElt, sortOrder, fieldName, onClick, active }) {
  return (
    <li className="block pl-8 pr-4 py-2 cursor-pointer hover:bg-gray-600" onClick={() => onClick(sortElt, sortOrder)}>
      {active && <i className="fa-check fas absolute left-25 pt-1" />}
      {fieldName}
    </li>
  );
}

export default SortListItem;

import React, { Component } from "react";
import VideoRecorder from "./VideoRecorder";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import userHasRole from "../shared/utils/authUtils";
import { editorRole, csmRole, csmMaintenanceRole } from "../shared/constants";
import DisplayWhen from "../common/base/DisplayWhen";

class VideoRecorderList extends Component {
  state = { videoRecorders: [], showNewDevice: false, highlight: null, dropdownOpen: false };

  componentDidMount() {
    const { videoRecorders } = this.props;
    this.setState({ videoRecorders });
  }

  toggleAddDevice = () => {
    const { showNewDevice } = this.state;
    this.setState({ showNewDevice: !showNewDevice });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  };

  updateVideoRecorderList = (videoRecorder) => {
    const { videoRecorders, showNewDevice } = this.state;
    let updatedShowNewDevice = showNewDevice;
    let highlight = null;
    const index = videoRecorders.map((data) => data.id).indexOf(videoRecorder.id);
    if (index === -1) {
      videoRecorders.unshift(videoRecorder);
      updatedShowNewDevice = false;
      highlight = videoRecorder.id;
    } else {
      videoRecorders[index] = videoRecorder;
    }
    this.setState({ videoRecorders, showNewDevice: updatedShowNewDevice, highlight });
  };

  removeFromVideoRecorderList = (videoRecorderId) => {
    const { videoRecorders } = this.state;
    const updatedVideoRecorders = videoRecorders.filter((videoRecorder) => videoRecorder.id !== videoRecorderId);
    this.setState({ videoRecorders: updatedVideoRecorders });
  };

  render() {
    const { videoRecorders, showNewDevice, highlight, dropdownOpen } = this.state;
    const { storeId, servers } = this.props;

    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Video Recorders</CardTitle>
          <DisplayWhen condition={userHasRole(editorRole)}>
            <Row>
              <Col md={6}>
                <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown} className="float-left">
                  <DropdownToggle caret>DVR interface</DropdownToggle>
                  <DropdownMenu>
                    {servers && servers.status ? (
                      Object.keys(servers.status).map((server, index) => (
                        <DropdownItem
                          key={index}
                          onClick={() => {
                            window.location.href = `/remotecontrol?server=${servers.status[server].id}&command=connect-dvr-interface`;
                          }}
                        >
                          <DisplayWhen
                            condition={
                              servers.status[server].id && (!userHasRole(csmRole) || userHasRole(csmMaintenanceRole))
                            }
                          >
                            {server}
                          </DisplayWhen>
                        </DropdownItem>
                      ))
                    ) : (
                      <DropdownItem disabled>Loading...</DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </Col>
              <Col md={6}>
                <Button className="float-right" onClick={this.toggleAddDevice}>
                  {showNewDevice ? "Cancel" : "Add video recorder"}
                </Button>
              </Col>
            </Row>
          </DisplayWhen>
        </CardHeader>
        <CardBody>
          {showNewDevice && (
            <VideoRecorder
              key="new"
              videoRecorder={null}
              storeId={storeId}
              toggleAddDevice={this.toggleAddDevice}
              updateVideoRecorderList={this.updateVideoRecorderList}
              removeFromVideoRecorderList={this.removeFromVideoRecorderList}
            />
          )}
          {videoRecorders &&
            videoRecorders.map((videoRecorder) => (
              <VideoRecorder
                key={videoRecorder.id}
                videoRecorder={videoRecorder}
                updateVideoRecorderList={this.updateVideoRecorderList}
                removeFromVideoRecorderList={this.removeFromVideoRecorderList}
                highlight={highlight}
              />
            ))}
        </CardBody>
      </Card>
    );
  }
}

export default VideoRecorderList;

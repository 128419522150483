import React from "react";
import { uniqueId } from "lodash";
import "./ChoiceControl.scss";
function ChoiceControl({ name, options, error, selected, ...rest }) {
  const uniqueName = uniqueId(`${name}-`);
  return (
    <div className="btn-group choice-control" role="group" aria-label="Basic radio toggle button group">
      {options.map((option) => (
        <React.Fragment>
          <input
            {...rest}
            type="radio"
            className="btn-check"
            name={uniqueName}
            id={`${uniqueName}-${option.value}`}
            autoComplete="off"
            value={option.value}
            checked={selected === option.value}
          />
          <label className="btn" htmlFor={`${uniqueName}-${option.value}`}>
            {option.label}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
}

export default ChoiceControl;

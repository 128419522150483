import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getResellerInfo, updateResellerInfo, getResellers } from "../services/machineService";

function MachineResellerInfo({ machineId, hostname }) {
  const [resellerId, setResellerId] = useState("");
  const [resellers, setResellers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resellerData = await getResellerInfo(machineId);
        const resellerList = await getResellers();
        setResellers(resellerList);

        const matchedReseller = resellerList.find((r) => r.name === resellerData.reseller_name);
        setResellerId(matchedReseller ? matchedReseller.id : "");
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [hostname, machineId]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await updateResellerInfo(machineId, resellerId);
    } catch (error) {
      console.error("Failed to update reseller", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <h5 className="card-category">Reseller Information</h5>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Form>
            <FormGroup>
              <Label for="resellerSelect">Select Reseller</Label>
              <Input
                type="select"
                name="select"
                id="resellerSelect"
                value={resellerId}
                onChange={(e) => setResellerId(e.target.value)}
              >
                <option value="">No reseller assigned</option>
                {resellers.map((reseller) => (
                  <option key={reseller.id} value={reseller.id}>
                    {reseller.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <Button onClick={handleSave}>Save</Button>
          </Form>
        )}
      </CardBody>
    </Card>
  );
}

export default MachineResellerInfo;

import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap";

class ConnectDvrInterfaceResult extends Component {
  state = {
    stdOut: "",
    stdErr: "",
  };

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      stdOut: nextProps.stdOut,
      stdErr: nextProps.stdErr,
      status: nextProps.status,
    };
  }

  render() {
    const { stdOut, stdErr, status } = this.state;
    const { server, formData } = this.props;
    const stdOutSplit = stdOut.split("\n");
    const stdErrSplit = stdErr.split("\n");

    const lastOctet = formData.dvr_ip.split(".").pop().padStart(3, "0");
    const customPort = `50${lastOctet}`;
    console.log(customPort);

    return (
      <Row>
        <Col md="6">
          {status === "OK" && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  Standard output
                </CardTitle>
              </CardHeader>
              <CardBody>
                <CardText>
                  Here is the address and port through which you can access the DVR interface:
                  <a href={`http://${server.ip}:${customPort}`} target="_blank" rel="noopener noreferrer">
                    {`${server.ip}:${customPort}`}
                  </a>
                </CardText>
                {stdOut && stdOutSplit.map((element, index) => <CardText key={`stdOut_${index}`}>{element}</CardText>)}
              </CardBody>
            </Card>
          )}
        </Col>
        <Col md="6">
          {stdErr !== "" && status === "FAILED" && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center" tag="h4">
                  Error output
                </CardTitle>
              </CardHeader>
              <CardBody>
                {stdErrSplit.map((element, index) => (
                  <CardText key={`stdErr_${index}`}>{element}</CardText>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    );
  }
}

export default ConnectDvrInterfaceResult;

import http from "./httpService";

const apiEndPoint = "/locations/remotecmd";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function triggerRemoteCommand(data) {
  const body = { ...data, timestamp: Date.now() };

  Object.keys(body).forEach(function (key) {
    if (body[key] === "" || typeof body[key] === "undefined") {
      body[key] = null;
    }
  });
  let response = await http.post(apiEndPoint, body);
  if (data.cmd === "connect-dvr-interface") {
    const newBody = { ...body, cmd: "clean-iptable-rules" };
    await http.post(apiEndPoint, newBody);
  }

  while (response.status === 200 && response.data.status === "RUNNING") {
    await timeout(2000);
    response = await http.post(apiEndPoint, body);
  }

  return response.data;
}
export default triggerRemoteCommand;

import React from "react";
import { Row, Col, Button, Form, Input } from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

import BaseForm from "../common/BaseForm";
import getVideoRecorderTypes from "../../services/videoRecorderTypeService";
import ScanVideoRecorder from "../models/ScanVideoRecorderModel";
import { trigggerBulkScreenshots, fetchBulkScreenshots } from "../../services/bulkScreenshotsService";
import downloadFile from "../../utils/downloadUtils";

class ScanVideoRecorderForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    deviceTypes: [],
    loadScan: null,
    loadZip: null,
    maxChannels: 32,
    defaultChannelRanges: "1-32",
    channelRanges: "1-32",
    rangesErrorMessage: "",
  };
  videoRecorder = new ScanVideoRecorder();
  schema = this.videoRecorder.getSchema();

  async fetchDeviceTypes() {
    const { data: deviceTypes } = await getVideoRecorderTypes();
    this.setState({ deviceTypes });
  }

  async componentDidMount() {
    await this.fetchDeviceTypes();
    const { videoRecorder } = this.props;
    let data = null;
    if (videoRecorder) {
      data = this.videoRecorder.getObject(videoRecorder);
      data.protocol = "tcp";
    } else {
      data = this.videoRecorder.getDefaultData();
      data.store = this.props.storeId;
    }
    this.setState({ data });
  }

  rangesToList = (inputRanges) => {
    const channels = [];
    const ranges = inputRanges.split(",");
    ranges.forEach((range) => {
      if (/^\d+$/.test(range)) {
        channels.push(Number(range));
      } else if (/^\d+-\d+$/.test(range)) {
        const [start, end] = range.split("-").map(Number);
        channels.push(...Array.from({ length: end - start + 1 }, (_, i) => start + i));
      } else {
        return [];
      }
    });
    return channels;
  };

  createChannels = (channelNumbers) => {
    const channels = [];
    const formData = this.state.data;
    const ip = formData.device_ip || "";
    const suffix = formData.rtsp_url_suffix || "";
    const rangeCurlyBraces = "{}";
    const rangeCurlyBracesOpen = "{";
    const rangeCurlyBracesClose = "}";
    if (!ip.includes(rangeCurlyBraces) && !suffix.includes(rangeCurlyBraces)) {
      channelNumbers.forEach((channelNumber) => {
        channels.push({ ...formData, channel_number: channelNumber });
      });
    }
    if (ip.includes(rangeCurlyBraces)) {
      const rootDeviceIp = ip.split(rangeCurlyBracesOpen)[0];
      channelNumbers.forEach((channelNumber) => {
        channels.push({ ...formData, channel_number: channelNumber, device_ip: rootDeviceIp + channelNumber });
      });
    }
    if (suffix.includes(rangeCurlyBraces)) {
      const beginRtsp = suffix.split(rangeCurlyBracesOpen)[0];
      const endRtsp = suffix.split(rangeCurlyBracesClose)[1];
      channelNumbers.forEach((channelNumber) => {
        channels.push({
          ...formData,
          channel_number: channelNumber,
          rtsp_url_suffix: beginRtsp + channelNumber + endRtsp,
        });
      });
    }
    return channels;
  };

  parseChannelRanges = (channelRanges) => {
    let rangesErrorMessage = "";
    if (!channelRanges) {
      rangesErrorMessage = `Channels ranges field is mandatory (default : ${this.state.defaultChannelRanges})`;
      this.setState({ rangesErrorMessage });
      return [];
    }
    const channelNumbers = this.rangesToList(channelRanges);
    if (channelNumbers.length === 0) {
      rangesErrorMessage = "Could not read ranges, please input a valid value";
      this.setState({ rangesErrorMessage });
      return [];
    }
    if (channelNumbers.length > this.state.maxChannels) {
      rangesErrorMessage = `Cannot generate screenshot for more than ${this.state.maxChannels} channels`;
      this.setState({ rangesErrorMessage });
      return [];
    }
    this.setState({ rangesErrorMessage });
    return channelNumbers;
  };

  setScreenshotState(state, destination) {
    if (destination === "zip") {
      this.setState({ loadZip: state });
    }
    if (destination === "gallery") {
      this.setState({ loadScan: state });
    }
  }

  handleScreenshotGeneration = async (screenshotsDestination) => {
    if (screenshotsDestination === "gallery") {
      this.props.onShowGallery(false);
    }
    const { channelRanges } = this.state;
    const channelNumbers = this.parseChannelRanges(channelRanges);
    const channels = this.createChannels(channelNumbers);
    if (channels.length === 0) {
      return;
    }
    this.setScreenshotState("load", screenshotsDestination);
    const { location } = this.props;
    const videoRecorders = [];
    channels.forEach((channel) => {
      const dvrData = {
        channels: [{ channel_number: channel.channel_number, protocol: channel.protocol }],
        device_ip: channel.device_ip,
        device_rtsp_port: channel.device_rtsp_port,
        username: channel.username,
        password: channel.password,
        type: { name: this.props.videoRecorder.video_recorder_type_name },
        rtsp_url_suffix: channel.rtsp_url_suffix,
        sub_type: channel.sub_type,
      };
      videoRecorders.push(dvrData);
    });
    const { serverIp } = this.props;
    const videoRecorderId = this.props.videoRecorder.id;
    if (serverIp) {
      try {
        await trigggerBulkScreenshots(
          serverIp,
          location,
          screenshotsDestination,
          videoRecorders,
          videoRecorderId,
          channelRanges
        ).then((triggerResponse) => {
          if (triggerResponse.data.status === "running") {
            toast.info(screenshotsDestination === "zip" ? "Zip screenshots is running!" : "Scanning in progress!");
            fetchBulkScreenshots(
              serverIp,
              location,
              screenshotsDestination,
              videoRecorders,
              videoRecorderId,
              channelRanges
            ).then((fetchResponse) => {
              if (fetchResponse.data.status === "success") {
                this.setScreenshotState("success", screenshotsDestination);
                if (screenshotsDestination === "zip") {
                  downloadFile(fetchResponse.data.zip_file_url);
                }
                if (screenshotsDestination === "gallery") {
                  this.props.handleFormSubmit(
                    channels,
                    fetchResponse.data.screenshots,
                    fetchResponse.data.screenshots_polygon
                  );
                }
              } else {
                this.setScreenshotState("failed", screenshotsDestination);
                toast.error(fetchResponse.data.message);
              }
            });
          } else if (triggerResponse.data.status === "success") {
            this.setScreenshotState("success", screenshotsDestination);
            if (screenshotsDestination === "zip") {
              downloadFile(triggerResponse.data.zip_file_url);
            }
            if (screenshotsDestination === "gallery") {
              this.props.handleFormSubmit(
                channels,
                triggerResponse.data.screenshots,
                triggerResponse.data.screenshots_polygon
              );
            }
          } else {
            this.setScreenshotState("failed", screenshotsDestination);
            toast.error(triggerResponse.data.message);
          }
        });
      } catch (ex) {
        this.setScreenshotState("failed", screenshotsDestination);
        if (ex.message.includes("424")) {
          toast.error("Failed fetching screenshots : server unreachable");
        } else {
          toast.error("Failed fetching screenshots!");
        }
      }
    }
  };

  handleRangesChange = (channelRanges) => {
    this.parseChannelRanges(channelRanges);
    this.setState({ channelRanges });
  };

  render() {
    const { channelRanges, rangesErrorMessage, loadScan, loadZip } = this.state;
    return (
      <Form>
        <Row>
          <Col className="pr-md-1" md="4">
            {this.renderSelect("video_recorder_type_id", "Device type", this.state.deviceTypes)}
          </Col>
          <Col className="pr-md-1" md="3">
            {this.renderInput("username", "Username")}
          </Col>
          <Col className="px-md-1" md="5">
            {this.renderInput("password", "Password")}
          </Col>
        </Row>
        <Row>
          <Col className="pr-md-1" md="3">
            {this.renderInput("device_ip", "Device IP")}
          </Col>
          <Col className="px-md-1" md="3">
            {this.renderInput("device_rtsp_port", "RTSP port")}
          </Col>
          <Col className="px-md-1" md="3">
            {this.renderInput("rtsp_url_suffix", "RTSP URL suffix")}
          </Col>
          <Col className="px-md-1 centered" md="3">
            {this.renderSelect("protocol", "Protocol", [
              { name: "tcp", id: "tcp" },
              { name: "udp", id: "udp" },
            ])}
          </Col>
        </Row>
        <Row>
          <Col className="pr-md-1 centered" md="3">
            {this.renderSelect("sub_type", "Sub Type", [
              { name: "sub", id: "sub" },
              { name: "main", id: "main" },
            ])}
          </Col>
        </Row>
        <hr />
        <Row className="">
          <Col className="pr-md-1 ml-auto" md="3">
            <label id="rangesLabel" htmlFor="ranges">
              Channels ranges
            </label>
            <Input
              name="ranges"
              id="ranges"
              value={channelRanges}
              onChange={(e) => this.handleRangesChange(e.currentTarget.value)}
            />
            {rangesErrorMessage && <div style={{ color: "#ff576c", fontSize: "12px" }}>{rangesErrorMessage}</div>}
          </Col>
          <Col className="col-sm-auto px-0">
            <Button
              className="float-right btn-success m-0 align-input"
              onClick={() => this.handleScreenshotGeneration("gallery")}
            >
              {loadScan === "load" && (
                <i style={{ display: "inline-block" }} className="mr-2">
                  <ThreeDots height="15" width="20" radius="5" color="#ffffff" ariaLabel="three-dots-loading" visible />
                </i>
              )}
              {loadScan !== "load" && <i className="fas fa-binoculars mr-2" />}
              Scan
            </Button>
          </Col>
          <Col className="float-right col-sm-auto px-0">
            <Button
              className="float-right btn-info ml-0 align-input"
              onClick={() => this.handleScreenshotGeneration("zip")}
            >
              {loadZip === "load" && (
                <i style={{ display: "inline-block" }} className="mr-2">
                  <ThreeDots height="15" width="20" radius="5" color="#ffffff" ariaLabel="three-dots-loading" visible />
                </i>
              )}
              {loadZip !== "load" && <i className="fas fa-file-archive mr-2" />}
              Download ZIP
            </Button>
          </Col>
        </Row>
        <hr />
      </Form>
    );
  }
}

export default ScanVideoRecorderForm;

import { uniqueId } from "lodash";
import React from "react";
import { FormGroup, Input } from "reactstrap";
import "./ToggleSwitch.scss";

function ToggleSwitch({ name, label, inverted, disabled, datagroup, parentgroup, childgroup, error, ...rest }) {
  const id = uniqueId(`${name}-`);
  return (
    <FormGroup>
      <div className="custom-control custom-switch">
        <Input
          {...rest}
          name={name}
          disabled={disabled}
          inverted={inverted ? "inverted" : null}
          id={id}
          type="checkbox"
          datagroup={datagroup}
          parentgroup={parentgroup}
          childgroup={childgroup}
          className="custom-control-input"
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </FormGroup>
  );
}

export default ToggleSwitch;

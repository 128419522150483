const MINUTES_PER_DAY = 60 * 24;
const EMPTY_RANGE_FAVOR_FACTOR = -1.0;

export function minutesToHourFormat(minutes) {
  return [Math.trunc(minutes / 60), minutes % 60];
}

export function hourToMinutesFormat(hour) {
  return 60 * hour[0] + hour[1];
}

export function buildHourlyFavorFactor(favorFactor, startMinute, endMinute, updated) {
  return { favor_factor: favorFactor, start_minute: startMinute, end_minute: endMinute, updated };
}

export function insertFavorFactorIntoMinutes(minutes, favorFactorRange) {
  const updatedMinutes = [...minutes];
  const favorFactor = favorFactorRange.favor_factor;
  const startMinute = favorFactorRange.start_minute;
  const endMinute = favorFactorRange.end_minute;
  const { updated } = favorFactorRange;
  if (updated) {
    if (startMinute > 0 && minutes[startMinute - 1].favor_factor === minutes[startMinute].favor_factor) {
      updatedMinutes[startMinute - 1].updated = true;
    }
    if (endMinute < MINUTES_PER_DAY && minutes[endMinute - 1].favor_factor === minutes[endMinute].favor_factor) {
      updatedMinutes[endMinute].updated = true;
    }
  }
  for (let i = startMinute; i < endMinute; i++) {
    updatedMinutes[i] = { favor_factor: favorFactor, updated };
  }
  return updatedMinutes;
}

export function minutesToHourlyFavorFactor(minutes) {
  let previousMinute = minutes[0];
  let startMinute = 0;
  const result = [];
  let updated = false;
  for (let i = 1; i < minutes.length; i++) {
    const currentMinute = minutes[i];
    updated = updated || previousMinute.updated;
    if (currentMinute.favor_factor !== previousMinute.favor_factor) {
      if (previousMinute.favor_factor !== EMPTY_RANGE_FAVOR_FACTOR) {
        result.push(buildHourlyFavorFactor(previousMinute.favor_factor, startMinute, i, updated));
      }
      startMinute = i;
      updated = false;
    }
    previousMinute = currentMinute;
  }
  return result;
}

export function hourlyFavorFactorToMinutes(hourlyFavorFactor, minutesPerDay = MINUTES_PER_DAY) {
  let minutes = [];
  for (let i = 0; i < minutesPerDay; i++) {
    minutes.push({ favor_factor: EMPTY_RANGE_FAVOR_FACTOR, updated: false });
  }
  hourlyFavorFactor.forEach((hff) => {
    minutes = insertFavorFactorIntoMinutes(minutes, hff);
  });
  return minutes;
}

export function insertNewFavorFactor(hourlyFavorFactor, startMinute, endMinute, favorFactor) {
  let minutes = hourlyFavorFactorToMinutes(hourlyFavorFactor);
  minutes = insertFavorFactorIntoMinutes(minutes, buildHourlyFavorFactor(favorFactor, startMinute, endMinute, true));
  const updatedHourlyFavorFactor = minutesToHourlyFavorFactor(minutes);
  return updatedHourlyFavorFactor;
}

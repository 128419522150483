import React from "react";
import { Spinner } from "reactstrap";
import "./StatusIndicator.scss";
function StatusIndicator({ status, message }) {
  let messageClass = "text-primary";
  let indicator = null;
  switch (status) {
    case "success":
      indicator = <i className="fas fa-check-circle text-success" />;
      messageClass = "text-success";
      break;
    case "failed":
      indicator = <i className="fas fa-times text-danger" />;
      messageClass = "text-danger";
      break;
    case "progress":
      indicator = <Spinner color="success" className="s" />;
      messageClass = "text-success";
      break;
    default:
      indicator = null;
  }
  return (
    <div className="status-indicator">
      {indicator} <span className={messageClass}>{message}</span>
    </div>
  );
}

export default StatusIndicator;

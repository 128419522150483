import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import Screenshot from "./Screenshot";
import ImageModal from "./ImageModal";
import { saveChannel } from "../../services/channelService";

class Gallery extends Component {
  state = {
    showModal: false,
    uriModal: null,
    notFoundChannels: "",
    screenshots: [],
    screenshotsPolygons: [],
    showPolygons: false,
    allChecked: false,
    selectedCount: 0,
  };

  componentDidMount() {
    const { screenshots, screenshotsPolygons, channels } = this.props;
    const notFoundList = channels
      .filter((channel) => {
        return !screenshots.some((screenshot) => parseInt(screenshot[0]) === parseInt(channel.channel_number));
      })
      .map((channel) => channel.channel_number);
    if (notFoundList.length > 0) {
      const notFoundChannels = `Failed fetching screenshot for camera${
        notFoundList.length > 1 ? "s " : " "
      }${notFoundList.join(", ")}`;
      toast.warning(notFoundChannels);
      this.setState({ notFoundChannels });
    }
    this.setState({
      screenshots,
      screenshotsPolygons,
    });
  }

  handleToggleAllCheckboxes = () => {
    this.setState((prevState) => {
      const allChecked = !prevState.allChecked;

      return { allChecked };
    });
  };

  updateSelectedCount = (isChecked) => {
    this.setState((prevState) => ({
      selectedCount: isChecked ? prevState.selectedCount + 1 : prevState.selectedCount - 1,
    }));
  };

  hasPolygons = (screenshot) => {
    const { screenshotsPolygons } = this.state;
    return screenshotsPolygons.some((screenshotPolygon) => parseInt(screenshotPolygon[0]) === parseInt(screenshot[0]));
  };

  chooseDataUri = (screenshot) => {
    // screenshot : [<channel_number>, <data_uri>]
    const channelNumber = screenshot[0];
    const { showPolygons, screenshotsPolygons } = this.state;
    if (!showPolygons) {
      return screenshot[1];
    }
    if (!this.hasPolygons(screenshot)) {
      return screenshot[1];
    }
    const screenshotPolygon = screenshotsPolygons.filter((item) => {
      return item[0] === channelNumber;
    })[0];
    return screenshotPolygon[1];
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  togglePolygons = () => {
    const { showPolygons } = this.state;
    this.setState({ showPolygons: !showPolygons });
  };

  handleShowModal = (dataUri) => {
    this.setState({ uriModal: dataUri });
    this.toggleModal();
  };

  handleValidate = async () => {
    const { screenshots } = this.state;
    const { videoRecorder } = this.props;

    const selectedChannels = screenshots.filter((screenshot) => screenshot[2]);
    const savePromises = selectedChannels.map(async (screenshot) => {
      try {
        await saveChannel({
          id: null,
          video_recorder: videoRecorder,
          channel_number: screenshot[0],
          is_active: false,
        });
      } catch (error) {
        if (error.response && error.response.status === 500) {
          toast.error(`Server error while saving camera ${screenshot[0]}. Please try again later.`);
        } else {
          toast.error(`Camera ${screenshot[0]} probably already exists: ${error.message}`);
        }
      }
    });

    try {
      await Promise.all(savePromises);
      toast.success("Selected channels have been processed.");
    } catch (error) {
      toast.error("An error occurred while processing the selected channels.");
    }
  };

  render() {
    const {
      showPolygons,
      showModal,
      screenshots,
      screenshotsPolygons,
      notFoundChannels,
      uriModal,
      allChecked,
      selectedCount,
    } = this.state;
    const { formId, serverIp, videoRecorder } = this.props;

    return (
      <div className="mt-5">
        {notFoundChannels && (
          <Row>
            <p className="ml-3">{notFoundChannels}</p>
          </Row>
        )}
        {screenshots.length !== 0 && videoRecorder && (
          <Row>
            <Button className="ml-3" onClick={this.handleToggleAllCheckboxes}>
              {allChecked ? "Uncheck All" : "Check All"}
            </Button>
          </Row>
        )}
        <Row>
          {screenshots &&
            screenshots.map((screenshot) => (
              <Col key={screenshot[0]} xl={3} lg={4} md={6} sm={12}>
                <Screenshot
                  channel={screenshot[0]}
                  formId={formId}
                  serverIp={serverIp}
                  onClick={this.handleShowModal}
                  dataUri={this.chooseDataUri(screenshot)}
                  hasPolygons={this.hasPolygons(screenshot)}
                  allChecked={allChecked}
                  updateSelectedCount={this.updateSelectedCount}
                  screenshot={screenshot}
                  checkbox={videoRecorder !== undefined}
                />
              </Col>
            ))}
          {showModal && <ImageModal showModal={showModal} dataUri={uriModal} toggle={this.toggleModal} />}
        </Row>
        {screenshotsPolygons.length > 0 && (
          <Row>
            <Button className="ml-3" onClick={this.togglePolygons}>
              <i className="fas fa-draw-polygon text-primary mr-2" />
              {showPolygons ? "Hide polygons" : "Show polygons"}
            </Button>
          </Row>
        )}
        {screenshots.length !== 0 && videoRecorder && (
          <Row>
            <Button className="ml-3" disabled={selectedCount === 0} onClick={this.handleValidate}>
              Validate
            </Button>
          </Row>
        )}
      </div>
    );
  }
}

export default Gallery;

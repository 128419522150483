import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import { toast } from "react-toastify";
import ArpScan from "../../models/remotecontrol/ArpScanModel";
import triggerRemoteCommand from "../../../services/remoteControlService";

class ArpScanForm extends BaseForm {
  state = {
    listInterface: [],
    data: {},
    errors: {},
  };

  arpScan = new ArpScan();
  schema = this.arpScan.getSchema();

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  componentDidMount() {
    const data = this.arpScan.getDefaultData();
    this.setState({ data });
    this.listPhysicalInterface(this.props.server);
  }

  async listPhysicalInterface(server) {
    const data = {};
    data.hostname = server.hostname;
    data.cmd = "list-physical-interfaces";
    data.cmdArgs = {};

    await triggerRemoteCommand(data)
      .then((response) => {
        if (response.status === "OK") {
          toast.success("List interfaces succeded !");
        } else if (response.status === "FAILED") {
          toast.error("Failed to list interfaces...");
        } else if (response.status === "EXPIRED") {
          toast.error("Listing interfaces took too long, interrupted");
        }

        const listInterface = response.stdout
          .split("\n")
          .filter(Boolean)
          .map((interfaceName, index) => ({
            id: index,
            name: interfaceName,
          }));
        this.setState({ listInterface });
      })
      .catch(() => {
        toast.error("Unhandled error");
      });
  }

  render() {
    return (
      <Form>
        <Row>
          <Col md="12">
            {this.state.listInterface.length === 0 ? (
              <p>Loading interfaces...</p>
            ) : (
              this.renderSelect("interface", "Interfaces", this.state.listInterface)
            )}
          </Col>
        </Row>
        {this.state.listInterface.length !== 0 && (
          <Row>
            <Col md="12">
              <Button className="float-right btn-success m-2" onClick={this.handleSubmit}>
                <i className="fas fa-wrench mr-2" />
                Arp Scan
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
}

export default ArpScanForm;

import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import ConnectDvrInterfaceModel from "../../models/remotecontrol/ConnectDvrInterfaceModel";

class ConnectDvrInterfaceForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  connectDvrInterface = new ConnectDvrInterfaceModel();
  schema = this.connectDvrInterface.getSchema();

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  componentDidMount() {
    const { videoRecorder } = this.props;
    let data = {};
    if (videoRecorder) {
      data = {
        dvr_ip: videoRecorder.device_ip || undefined,
        port: 80,
      };
    } else {
      data = this.connectDvrInterface.getDefaultData();
    }
    this.setState({ data });
  }

  render() {
    const { videoRecorder } = this.props;

    return (
      <Form>
        <Row>
          <Col md="6">{this.renderInput("dvr_ip", "DVR IP Address")}</Col>
          <Col md="6" id="portConnectDvr">
            {this.renderInput("port", "Port")}
          </Col>
          {videoRecorder && (
            <Col md="12">
              <p style={{ fontStyle: "italic", marginTop: "10px" }}>
                Username: {videoRecorder.username}
                <br />
                Password: {videoRecorder.password}
              </p>
            </Col>
          )}

          <Col md="12">
            <Button className="float-right btn-success m-2" onClick={this.handleSubmit}>
              <i className="fas fa-search mr-2" />
              Get DVR Interface link
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default ConnectDvrInterfaceForm;

import { MAX_ANCHOR } from "../components/shared/constants";

export function isResolution(resolution) {
  return resolution && resolution.width && resolution.height;
}

export function polygonToRepresentation(polygon) {
  return polygon.map((point) => point.join(",")).join(";");
}

export function textPolygonToInternalValue(polygon) {
  const points = polygon.split(";");
  return points.map((point) => {
    const [x, y] = point.split(",");
    return [parseInt(x), parseInt(y)];
  });
}

export function getTextPolygonAnchor(textPolygon, resolution) {
  if (!isResolution(resolution)) {
    // eslint-disable-next-line no-param-reassign
    resolution = { width: 3000, height: 3000 };
  }
  const polygon = textPolygonToInternalValue(textPolygon);
  const first = polygon[0];
  const second = polygon[1];
  const last = polygon.slice(-1)[0];
  const beforeLast = polygon.slice(-2)[0];
  // [ [0,30] , [10,30] , [20,60] , [0,60] ]
  if (first[0] === 0 && first[1] === second[1] && last[0] === 0 && last[1] === beforeLast[1]) {
    return "left";
  }
  // [ [3000,30] , [10,30] , [20,60] , [3000,60] ]
  if (
    first[0] === resolution.width &&
    first[1] === second[1] &&
    last[0] === resolution.width &&
    last[1] === beforeLast[1]
  ) {
    return "right";
  }
  // [ [10,0] , [10,30] , [20,60] , [20,0] ]
  if (first[1] === 0 && first[0] === second[0] && last[1] === 0 && last[0] === beforeLast[0]) {
    return "top";
  }
  // [ [10,3000] , [10,30] , [20,60] , [20,3000] ]
  if (
    first[1] === resolution.height &&
    first[0] === second[0] &&
    last[1] === resolution.height &&
    last[0] === beforeLast[0]
  ) {
    return "bot";
  }
  return "";
}

export function anchorTextPolygon(textPolygon, anchor, resolution) {
  let maxX = MAX_ANCHOR;
  let maxY = MAX_ANCHOR;
  if (isResolution(resolution)) {
    maxX = resolution.width;
    maxY = resolution.height;
  }
  if (!anchor || !textPolygon) {
    return [];
  }
  const polygon = textPolygonToInternalValue(textPolygon);
  const [xFirst, yFirst] = polygon[0];
  const [xLast, yLast] = polygon.slice(-1)[0];
  let anchors = [];
  if (anchor === "left") {
    anchors = [`0,${yFirst}`, `0,${yLast}`];
  }
  if (anchor === "right") {
    anchors = [`${maxX},${yFirst}`, `${maxX},${yLast}`];
  }
  if (anchor === "top") {
    anchors = [`${xFirst},0`, `${xLast},0`];
  }
  if (anchor === "bot") {
    anchors = [`${xFirst},${maxY}`, `${xLast},${maxY}`];
  }
  return `${anchors[0]};${polygonToRepresentation(polygon)};${anchors[1]}`;
}

export function extractTextPolygonAnchorPoints(textPolygon) {
  const points = textPolygon.split(";");
  const firstPoint = points.shift();
  const lastPoint = points.pop();
  return [points.join(";"), firstPoint, lastPoint];
}

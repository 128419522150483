import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import NetworkCheckModel from "../../models/remotecontrol/NetworkCheckModel";

class NetworkCheckForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  networkCheckModel = new NetworkCheckModel();
  schema = this.networkCheckModel.getSchema();

  componentDidMount() {
    const { networkCheck } = this.props;
    let data = {};
    if (networkCheck) {
      data = {
        internet: networkCheck.internet || false,
        duplicate: networkCheck.duplicate || false,
        dns: networkCheck.dns || false,
        ntp: networkCheck.ntp || false,
        veesion: networkCheck.veesion || false,
        aws: networkCheck.aws || false,
        apt: networkCheck.apt || false,
        wireguard: networkCheck.wireguard || false,
        rabbit: networkCheck.rabbit || false,
        mtu: networkCheck.mtu || false,
        speedtest: networkCheck.speedtest || false,
      };
    } else {
      data = this.networkCheckModel.getDefaultData();
    }
    this.setState({ data });
  }

  checkAll = () => {
    const newData = { ...this.state.data };
    Object.keys(newData).forEach((key) => {
      newData[key] = true;
    });
    this.setState({ data: newData });
  };

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  render() {
    return (
      <Form>
        <Row>
          <Col md="4">{this.renderToggleSwitch("internet", "Internet")}</Col>
          <Col md="4">{this.renderToggleSwitch("duplicate", "Duplicate")}</Col>
          <Col md="4">{this.renderToggleSwitch("dns", "DNS")}</Col>
        </Row>
        <Row>
          <Col md="4">{this.renderToggleSwitch("ntp", "NTP")}</Col>
          <Col md="4">{this.renderToggleSwitch("veesion", "Veesion")}</Col>
          <Col md="4">{this.renderToggleSwitch("aws", "AWS")}</Col>
        </Row>
        <Row>
          <Col md="4">{this.renderToggleSwitch("apt", "APT")}</Col>
          <Col md="4">{this.renderToggleSwitch("wireguard", "WireGuard")}</Col>
          <Col md="4">{this.renderToggleSwitch("rabbit", "RabbitMQ")}</Col>
        </Row>
        <Row>
          <Col md="4">{this.renderToggleSwitch("mtu", "MTU")}</Col>
          <Col md="4">{this.renderToggleSwitch("speedtest", "Speedtest")}</Col>
        </Row>
        <Row>
          <Col>
            <Button className="btn-info" onClick={this.checkAll}>
              <i className="fa fa-check-square mr-2" />
              Check All
            </Button>
          </Col>
        </Row>
        <Col>
          <Button
            className="float-right btn-success m-2"
            disabled={this.props.isDisabledButton}
            onClick={this.handleSubmit}
          >
            <i className="fa fa-play mr-2" />
            Run Network Check
          </Button>
        </Col>
      </Form>
    );
  }
}

export default NetworkCheckForm;

import Model from "../Model";
import Joi from "joi-browser";
const groups = { PRIMARY: "primary" };

class PingDeviceModel extends Model {
  constructor() {
    super();
    this.ip = this.createField("", groups.PRIMARY, "Device IP", Joi.string().ip({ cidr: "forbidden" }).required());
  }
}

export default PingDeviceModel;

import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { getMachines } from "../../services/incidentService";
import Select from "../common/controls/Select";
import incidentTypesGroups from "../incidents/IncidentType";

export default function IncidentForm({ onIncidentCreated, store }) {
  const [chosenType, setChosenType] = useState();
  const [machines, setMachines] = useState([]);
  const [machine, setMachine] = useState();

  const requiresMachine = useCallback(() => {
    return chosenType && chosenType.type.requires && chosenType.type.requires.includes("machine");
  }, [chosenType]);

  useEffect(() => {
    if (requiresMachine()) {
      const fetchMachines = async () => {
        try {
          let machinesData = await getMachines(store.location);
          machinesData = machinesData.map((machineData) => ({ id: machineData, name: machineData }));
          setMachines(machinesData);
          if (machinesData.length > 0) {
            setMachine(machinesData[0].id);
          }
        } catch (error) {
          console.error("Error fetching machines", error);
        }
      };

      fetchMachines();
    }
  }, [chosenType, requiresMachine, store.location]);

  function create() {
    onIncidentCreated(chosenType.type, machine);
  }

  function changeIncident() {
    setChosenType();
    setMachine();
  }

  return (
    <div>
      <h4>Open new Incident</h4>
      {!chosenType && (
        <div>
          {incidentTypesGroups.map((group) => (
            <div>
              <Row>
                <Col>{group.name}</Col>
              </Row>
              <Row>
                {group.incidentTypes.map((type) => (
                  <Col md={6} className="d-flex">
                    <Button style={{ width: "100%", textAlign: "left" }} onClick={() => setChosenType({ type, group })}>
                      <i className={`fas ${type.icon} mr-2`} />
                      {type.displayName}
                    </Button>
                  </Col>
                ))}
              </Row>
              <br />
            </div>
          ))}
        </div>
      )}
      {chosenType && (
        <div>
          Incident :
          <Button className="ml-2" style={{ textAlign: "left" }}>
            {chosenType.group.name} :
            <i className={`fas ${chosenType.type.icon} ml-2 mr-2`} />
            {chosenType.type.displayName}
          </Button>
          <Row>
            <Col md="12">
              {requiresMachine() && (
                <div>
                  Machine :
                  <Select className="ml-2" options={machines} onChange={(e) => setMachine(e.target.value)} />
                </div>
              )}
              <Button disabled={requiresMachine() && !machine} className="btn-success m-2 float-right" onClick={create}>
                Create
                <i className="fas fa-check ml-2" />
              </Button>
              <Button className="btn-secondary m-2 float-right" onClick={changeIncident}>
                Change Incident
                <i className="fas fa-times ml-2" />
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

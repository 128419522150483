import React from "react";
import { Row, Col, Button, Form } from "reactstrap";
import BaseForm from "../../common/BaseForm";
import PingDevice from "../../models/remotecontrol/PingDeviceModel";

class PingDeviceForm extends BaseForm {
  state = {
    data: {},
    errors: {},
  };

  pingDevice = new PingDevice();
  schema = this.pingDevice.getSchema();

  doSubmit = async () => {
    await this.props.handleSubmit(this.state.data);
  };

  componentDidMount() {
    const data = this.pingDevice.getDefaultData();
    this.setState({ data });
  }

  render() {
    return (
      <Form>
        <Row>
          <Col md="12">{this.renderInput("ip", "Device IP")}</Col>
          <Col md="12">
            <Button className="float-right btn-success m-2" onClick={this.handleSubmit}>
              <i className="fas fa-wrench mr-2" />
              Ping
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default PingDeviceForm;

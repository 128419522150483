import { Col, Row } from "reactstrap";
import React, { cloneElement, useContext } from "react";
import FormDataContext from "../../shared/contexts/FormDataContext";
import Field from "../../shared/utils/Field";

const defaultColClassName = "pr-md-1";

/**
 * A row that renders columns automatically when the field is present in the form data.
 *
 * @param fields list of fields to be rendered.
 * @param children children to be rendered.
 * @param header header row.
 * @param bottomSeparator true to display bottom separator.
 * @param md
 * @param attributes extras attributes
 * @returns {JSX.Element|null}
 * @constructor
 *
 * @see Field
 * @see FieldBuilder
 */
function ResponsiveRow({ fields, children, header = undefined, bottomSeparator = true, md = "3", ...attributes }) {
  const data = useContext(FormDataContext);
  let presentFields = [];
  if (fields) {
    presentFields = fields.filter(
      (field) =>
        field instanceof Field && field.isVisible && data && Object.prototype.hasOwnProperty.call(data, field.name)
    );
  }

  if (presentFields.length > 0) {
    const { permissions } = data;
    return (
      <>
        {header && <h5 className="card-category">{header}</h5>}
        <Row {...attributes}>
          {presentFields.map((field) => {
            let className = defaultColClassName;
            if (field.parentTagClassName) {
              className = field.parentTagClassName;
            }

            let childComponent = field.component;

            if (
              Object.prototype.hasOwnProperty.call(data, "permissions") &&
              Object.prototype.hasOwnProperty.call(permissions, "readonly_fields")
            ) {
              if (permissions.readonly_fields.find((fieldName) => fieldName === field.name)) {
                childComponent = cloneElement(field.component, {
                  readOnly: "readonly",
                  disabled: true,
                });
              }
            }

            return (
              <Col className={className} md={md} key={field.id}>
                {childComponent}
              </Col>
            );
          })}
          {children}
        </Row>
        {bottomSeparator && <hr />}
      </>
    );
  }
  return null;
}

export default React.memo(ResponsiveRow);

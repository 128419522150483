import { Button, Form } from "reactstrap";
import BaseForm from "../common/BaseForm";
import StatusIndicator from "../common/StatusIndicator/StatusIndicator";
import Gchat from "../models/GchatModel";
import "../gchat/Gchat.scss";
import { addOrUpdateSpecificNotifier, deleteSpecificNotifier } from "../../services/storeService";
import { toast } from "react-toastify";

class GchatForm extends BaseForm {
  state = {
    data: {},
    errors: {},
    confirmDelete: false,
    gchatFocus: undefined,
  };

  gchat = new Gchat();
  schema = this.gchat.getSchema();

  componentDidMount() {
    const { gchat, notifier, store } = this.props;
    let data = {};
    if (gchat) {
      data = this.gchat.getObject(gchat);
    } else {
      data.store = store.id;
      data.notifier = notifier.id;
    }
    this.setState({ data });
  }

  toggleConfirmDelete = ({ currentTarget: button }) => {
    const id = button.name;
    let { gchatFocus } = this.state;
    const confirmDelete = !this.state.confirmDelete;
    if (!gchatFocus) {
      gchatFocus = id;
    } else {
      gchatFocus = undefined;
    }
    this.setState({ confirmDelete, gchatFocus });
  };

  delete = async () => {
    let status = "deleting";
    this.setState({ status });
    try {
      const { notifiers } = this.props.store;
      Object.keys(notifiers).forEach((key) => {
        if (notifiers[key].gchats) {
          const gchats = notifiers[key].gchats.filter((gchat) => gchat.id !== this.state.data.id);
          notifiers[key].gchats = gchats;
        }
      });
      this.props.store.notifiers = notifiers;
      await deleteSpecificNotifier(this.props.store, this.props.notifier.name, this.state.data.id);
      this.props.removeFromGchatList(this.state.data.id);
      toast.info("Store config updated.");
      this.props.removeFromGchatList(this.state.data.id);
      const confirmDelete = false;
      const gchatFocus = undefined;
      this.setState({ confirmDelete, gchatFocus });
    } catch (exception) {
      if (exception.response) {
        status = "Failed to delete!";
        this.validateResponse(exception.response.data);
      } else {
        console.log(exception);
        status = "unknown";
      }
      this.setState({ status });
    }
  };

  save = async () => {
    const storeNotifiers = this.props.store.notifiers;
    const currentNotifier = this.props.notifier;
    const currentGchat = this.state.data;
    this.state.data.notifier = currentNotifier.id;

    Object.keys(storeNotifiers).forEach((key) => {
      if (storeNotifiers[key].id === currentNotifier.id) {
        Object.keys(storeNotifiers[key].gchats).forEach((gchatKey) => {
          if (storeNotifiers[key].gchats[gchatKey].id === currentGchat.id) {
            storeNotifiers[key].gchats[gchatKey] = { ...this.state.data };
          }
        });
      }
    });
    this.props.store.notifiers = storeNotifiers;
    try {
      const { data: gchat } = await addOrUpdateSpecificNotifier(
        this.props.store,
        this.props.notifier.name,
        this.state.data
      );
      this.props.onSaveNotifier(this.props.store.notifiers);
      this.props.updateGchatList(gchat);
      toast.info("Store config updated.");
      this.props.toggleEditMode();
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 400) {
          Object.keys(ex.response.data).forEach(function (key) {
            const message = `${key}: ${ex.response.data[key][0]}`;
            toast.error(message);
          });
        } else if (ex.response.status === 404) {
          toast.error("Store not found");
        } else {
          toast.error("Failed to update");
        }
      } else {
        toast.error("Failed to update");
      }
    }
  };

  render() {
    const { gchat, showNewGchat, gchatBots } = this.props;
    const { status, confirmDelete, gchatFocus } = this.state;
    let statusType = null;
    let message = "";
    switch (status) {
      case "edited":
        statusType = null;
        message = null;
        break;
      case "started":
        statusType = null;
        message = null;
        break;
      case "saving":
        statusType = "progress";
        message = "saving...";
        break;
      case "deleting":
        statusType = "progress";
        message = "deleting...";
        break;
      case "success":
        statusType = "success";
        message = "saved!";
        break;
      default:
        statusType = "failed";
        message = status;
    }
    const statusIndicator = (
      <div className="float-right m-2">
        <StatusIndicator status={statusType} message={message} />
      </div>
    );
    return (
      <div>
        <Form>
          <div className="vt-ip">
            <div className="input">{this.renderInput("space_id", "Space Id")}</div>
            <div className="input">{this.renderInput("space_name", "Space Name")}</div>
            <div className="input">{this.renderSelect("bot", "Bot", gchatBots)}</div>
            <label />
            {!confirmDelete && (
              <Button className="float-right btn-success m-2 align-input" onClick={this.save}>
                <i className="fas fa-save mr-2" />
                Save
              </Button>
            )}
            {!showNewGchat && !confirmDelete && (
              <Button
                className="float-right btn-danger m-2 align-input"
                name={`items-${gchat.id}`}
                onClick={this.toggleConfirmDelete}
              >
                <i className="fas fa-trash-alt mr-2" />
                Delete
              </Button>
            )}
            {!showNewGchat && confirmDelete && gchatFocus === `items-${gchat.id}` && (
              <div className="align-input">
                <span className="float-left" style={{ padding: "12px", fontWeight: 600, color: "#ff4e4e" }}>
                  Are you sure you want to delete this notifier ?
                </span>
                <Button className="float-right m-2" onClick={this.toggleConfirmDelete}>
                  <i className="fas fa-times mr-2" /> Cancel
                </Button>
                <Button className="float-right btn-danger m-2" onClick={this.delete}>
                  <i className="fas fa-trash-alt mr-2" />
                  Delete
                </Button>
              </div>
            )}
            {message && statusIndicator}
          </div>
        </Form>
      </div>
    );
  }
}

export default GchatForm;

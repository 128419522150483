/**
 * Determines whether a favor factor update row is valid.
 * @param row CSV entry
 * @returns {boolean} true if valid. false otherwise.
 */
export const isFavorFactorRowValid = (row) => {
  const locationRegex = /^[a-zA-Z0-9-]+$/;
  const ipAddressRegex = /^(?:\d{1,3}\.){3}(?:\d{1,3}|\{\})$/;
  const portRegex = /^\d+$/;
  const cameraNumbersRegex = /^\d+(,\d+)*$/;
  const favorFactorRegex = /^([-+])?\d+([.,]\d+)?$/;
  const recheckingRegex = /^(?:\d+|reset)$/i;
  const operationRegex = /^(add|by)$/i;

  const regexPatterns = [
    locationRegex,
    ipAddressRegex,
    cameraNumbersRegex,
    portRegex,
    favorFactorRegex,
    recheckingRegex,
    operationRegex,
  ];

  const locationIndex = 0;
  const favorFactorIndex = regexPatterns.length - 3;
  const recheckingIndex = regexPatterns.length - 2;
  const operationIndex = regexPatterns.length - 1;

  if (!row[locationIndex]) {
    return false;
  }

  if (!row[favorFactorIndex] && !row[recheckingIndex]) {
    return false;
  }

  if (row.every((item) => !item)) {
    return false;
  }

  const length = Math.min(regexPatterns.length, row.length);

  for (let i = 0; i < length; i++) {
    if (row[i] !== "" && !regexPatterns[i].test(row[i])) {
      return false;
    }
  }

  if (row.length >= regexPatterns.length && row[operationIndex].toLowerCase() === "by") {
    const favorFactor = parseFloat(row[favorFactorIndex]);
    if (!Number.isNaN(favorFactor) && favorFactor < 0) {
      return false;
    }
  }

  return true;
};

export const favorFactorRowToUpdateRequest = (row) => {
  return {
    location: row[0],
    ipAddress: row[1],
    cameraNumbers: row[2],
    rtspPort: row[3],
    favorFactor: row[4],
    rechecking: row[5],
    operation: row[6],
  };
};

export const spreadsheetRowToUpdateRow = (row) => {
  return row.map((item) => item.value || "");
};

export const updateRowToFavorFactorRow = (updateRow) => {
  return updateRow.map((item) => item.value || "");
};

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  const size = Math.floor(chunkSize);

  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size);
    res.push(chunk);
  }
  return res;
};

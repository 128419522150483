import React from "react";
import Joi from "joi-browser";
import { Redirect } from "react-router-dom";
import BaseForm from "../common/BaseForm";
import auth from "../../services/authService";

class LoginForm extends BaseForm {
  state = {
    data: { username: "", password: "" },
    errors: {},
  };

  schema = {
    username: Joi.string().required().label("username"),
    password: Joi.string().required().label("Password"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.username, data.password);
      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        // toast.error("Incorrect Login/password");
      }
    }
  };

  render() {
    const user = auth.getCurrentUser();
    if (user) return <Redirect to={{ pathname: "/", state: { name: user.name } }} />;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Username")}
          {this.renderInput("password", "Password", "password")}
          {this.renderButton("Login")}
        </form>
      </div>
    );
  }
}

export default LoginForm;

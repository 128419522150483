import { Component } from "react";

class Logout extends Component {
  componentDidMount() {
    console.log("LOGOUT");
    // authService.logout();
    // window.location = "/";
  }

  render() {
    return null;
  }
}

export default Logout;

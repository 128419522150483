import React from "react";
import LoginForm from "../components/Forms/LoginForm";

function LoginView() {
  return (
    <div className="container col-lg-4 col-md-6 col-sm-6 col-xs-12">
      <div className="my-5">
        <div className="row">
          <h2>Veesion manager | Login</h2>
        </div>
        <hr />
        <LoginForm />
      </div>
    </div>
  );
}

export default React.memo(LoginView);

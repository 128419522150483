import * as Sentry from "@sentry/react";
import { Button, Table } from "reactstrap";
import formatDate from "../utils";

function UserChangesTable({ userHistories, username }) {
  return (
    <Table className="tablesorter" responsive>
      <thead className="text-primary">
        <tr>
          <th>Location</th>
          <th>Edited on (mm/dd/yyyy)</th>
          <th className="text-center" />
        </tr>
      </thead>
      <tbody>
        {userHistories &&
          userHistories.map((userHistory, index) => (
            <tr key={`${userHistory.revisionId}`}>
              <td>{userHistory.location}</td>
              <td>
                <p className="text-info font-bold">{formatDate(userHistory.createdOn)}</p>
              </td>
              <td className="text-center">
                <a href={`/history/u/${username}/${index}/${userHistory.createdOn}/`}>
                  <Button>See changes</Button>
                </a>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default Sentry.withProfiler(UserChangesTable);

import React from "react";

// reactstrap components
import { NavbarBrand, Navbar, Nav, Container, Button } from "reactstrap";
import auth from "../../services/authService";
import { useHistory } from "react-router-dom";

export default function MainNavbar(props) {
  const history = useHistory();
  const currentUser = auth.getCurrentUser();

  function logout() {
    if (auth.isAuthenticated()) {
      auth.logout();
      history.push("/");
    }
  }

  const { toggleSidebar, brandText } = props;
  return (
    <Navbar className="navbar" expand="lg" fixed="top">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle d-inline">
            <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
            {brandText}
          </NavbarBrand>
        </div>
        <button
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-target="#navigation"
          data-toggle="collapse"
          id="navigation"
          type="button"
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>

        <Nav className="ml-auto" navbar>
          {auth.isAuthenticated() && (
            <span>
              {currentUser.first_name} {currentUser.last_name}
            </span>
          )}
          <Button
            color="secondary"
            onClick={() => {
              logout();
            }}
            className="btn-block"
          >
            Log out
          </Button>
          <li className="separator d-lg-none" />
        </Nav>
      </Container>
    </Navbar>
  );
}

import Model from "../Model";
import Joi from "joi-browser";

const groups = { PRIMARY: "primary" };

class DiscoverModel extends Model {
  constructor() {
    super();
    this.list_subnetwork = this.createField(false, groups.PRIMARY, "List Subnetworks", Joi.boolean().required());
    this.ip = this.createField("", groups.PRIMARY, "IP Address", Joi.string().allow(""));
    this.ip = this.createField("", groups.PRIMARY, "IP Address", Joi.string().ip({ cidr: "forbidden" }).allow(""));
    this.port = this.createField("", groups.PRIMARY, "Port", Joi.string().allow(""));
    this.username = this.createField("", groups.PRIMARY, "Username", Joi.string().allow(""));
    this.password = this.createField("", groups.PRIMARY, "Password", Joi.string().allow(""));
    this.password_guess = this.createField(false, groups.PRIMARY, "Password Guess", Joi.boolean().required());
    this.url = this.createField("", groups.PRIMARY, "URL", Joi.string().allow(""));
    this.channel = this.createField("", groups.PRIMARY, "Channel", Joi.string().allow(""));
    this.print_all = this.createField(false, groups.PRIMARY, "Print All", Joi.boolean().required());
  }
}

export default DiscoverModel;

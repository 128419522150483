const historyBaseEndPoint = "/history";

export function getStoresHistoriesPaginatedEndpoint({ page = 1, pageSize = 10, query = "" }) {
  return `${historyBaseEndPoint}/stores/?page=${page}&page_size=${pageSize}&filter=${query}`;
}

export function getStoresHistoriesSummaryPaginatedEndpoint(storeLocation) {
  return `${historyBaseEndPoint}/stores/${storeLocation}/summary/`;
}

export function getStoreHistoryEndpoint(location) {
  return `${historyBaseEndPoint}/stores/${location}/`;
}
